<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 890.55 266.55" style="enable-background:new 0 0 890.55 266.55;" xml:space="preserve">
<g id="Canteiro">
</g>
<g id="Sub_x5F_base">
	<polygon class="st_aquisition_0" points="645.2,111 442.77,107 241.72,111 252.79,92 442.77,88 634.13,92 	"/>
	<polygon class="st_aquisition_1" points="656.14,130 442.77,126 230.78,130 241.44,111 442.77,107 645.47,111 	"/>
</g>
<g id="Land">
</g>
<g id="Asphalt_x5F_and_components">
	<polyline class="st_aquisition_2" points="610.01,89 631.83,89 631.83,81 	"/>
	<polyline class="st_aquisition_2" points="253.71,81 253.71,89 275.52,89 	"/>
	<polygon points="610.01,86.16 442.77,82.16 275.52,86.16 275.52,91.16 442.77,87.16 610.01,91.16 	"/>
</g>
<g id="Arrows">
</g>
<g id="Text">
	<g>
		<text transform="matrix(1 0 0 1 431.0696 216.4852)" class="st_aquisition_3 st_aquisition_4">{{ currentCalc.values_params.earthwork.avg_earthwork_width }} m</text>
	</g>
</g>
<g id="Grass">
	<g>
		<path class="st_aquisition_5" d="M228.86,131.99l-65.8,22.95c0,0-1.55-5.25-1.8-6.12c0.31,1.04,0.91-0.9,2.97-0.88
			c0.81-0.73,2.32,0.14,3.53-0.61c0.77-2.25,3.16,0.39,4.31-1.7c0.87-0.03,2.62,2.77,2.45-1.04c-0.46-4.78,1.72-0.72,2.73-0.71
			c1.46,1.53,1.49-1.89,2.92-0.66c-0.27-4.51,1.33-2.75,2.99-0.86c2.62,5.16,2.94,2.45,2.76-2.01c0.78-1.2,2.16-0.4,3.21-0.78
			c0.39-2.61,1.59-2.51,3.14-1.2c1.2,0.08,2.13-0.74,3.59,0.24c1.74,1.92,1.63-2.46,3.69,0.55c1.38,0.72,1.19-3.91,2.9-2.03
			c1.07-0.31,1.6-2.38,2.85-2c1.52,1.3,2.46,0.69,3.28-0.27c1.69-1.76,2.99-4.56,6-1.21c2.39,1.25,3.73-0.69,5.09-2.06
			c1-2.58,3.21-0.58,4.43-1.31c0.52-3.11,3,1.07,3.41-1.01c2.08,0.06,2.78-1.54,2.97-0.88
			C232.96,130.65,228.86,131.99,228.86,131.99z"/>
		<path d="M162.73,155.59l-1.95-6.63l0,0l0.96-0.28c-0.03-0.02-0.22-0.09-0.32-0.04c0.05-0.02,0.18-0.13,0.28-0.2
			c0.45-0.36,1.2-0.95,2.37-0.99c0.53-0.36,1.17-0.33,1.79-0.3c0.55,0.03,1.06,0.05,1.51-0.16c0.56-1.33,1.73-1.17,2.52-1.05
			c0.89,0.12,1.38,0.16,1.76-0.54l0.14-0.25l0.28-0.01c0.43,0.01,0.83,0.27,1.24,0.57c0.2,0.14,0.52,0.37,0.65,0.4
			c-0.02-0.04,0.12-0.35,0.07-1.48c-0.17-1.72-0.02-2.5,0.53-2.77c0.61-0.3,1.21,0.26,1.85,0.85c0.26,0.25,0.7,0.65,0.87,0.68h0.21
			l0.14,0.15c0.22,0.24,0.33,0.25,0.33,0.25c0.09-0.02,0.29-0.3,0.4-0.45c0.26-0.36,0.59-0.81,1.12-0.88
			c0.06-0.01,0.12-0.01,0.17-0.01c-0.02-1.51,0.24-2.25,0.83-2.5c0.91-0.38,1.91,0.76,3.07,2.08l0.07,0.1
			c0.94,1.85,1.43,2.35,1.61,2.49c0.14-0.27,0.33-1.19,0.21-4.25l-0.01-0.16l0.09-0.13c0.65-1,1.62-0.95,2.4-0.91
			c0.28,0.01,0.55,0.03,0.79,0c0.22-1.17,0.65-1.85,1.29-2.1c0.87-0.33,1.81,0.27,2.47,0.81c0.28,0,0.56-0.06,0.86-0.12
			c0.79-0.15,1.68-0.33,2.83,0.44l0.09,0.08c0.3,0.33,0.44,0.34,0.46,0.34c0.09,0,0.29-0.22,0.42-0.36c0.28-0.3,0.65-0.73,1.23-0.68
			c0.49,0.03,0.95,0.38,1.49,1.12c0.17-0.13,0.37-0.64,0.48-0.92c0.28-0.72,0.57-1.46,1.24-1.58c0.4-0.08,0.75,0.13,0.99,0.33
			c0.22-0.16,0.45-0.45,0.67-0.72c0.5-0.62,1.19-1.47,2.23-1.15l0.18,0.1c0.57,0.49,1.05,0.69,1.47,0.6
			c0.34-0.07,0.68-0.32,1.1-0.82c0.27-0.28,0.51-0.57,0.76-0.86c0.86-1.02,1.76-2.07,3.03-2.09c0.01,0,0.02,0,0.03,0
			c0.91,0,1.85,0.55,2.87,1.67c1.6,0.79,2.56-0.04,3.93-1.5c0.14-0.15,0.28-0.3,0.42-0.44c0.79-1.91,2.3-1.64,3.3-1.46
			c0.42,0.07,0.82,0.15,1.12,0.08c0.15-0.64,0.41-1.04,0.8-1.23c0.62-0.3,1.3,0.07,1.84,0.38c0.2,0.11,0.53,0.3,0.67,0.31
			c0-0.02,0.03-0.08,0.05-0.2l0.08-0.41l0.42,0.01c1.05,0.04,1.67-0.42,2.09-0.71c0.3-0.21,0.58-0.41,0.92-0.29
			c0.12,0.04,0.27,0.13,0.37,0.34c0.7,0.67,0.98,1.35,0.83,2.05c-0.31,1.46-2.46,2.21-2.72,2.3L162.73,155.59z M161.97,149.47
			l1.42,4.82l65.31-22.78c0.48-0.16,1.9-0.79,2.06-1.56c0.07-0.33-0.11-0.69-0.53-1.1c-0.01,0.01-0.03,0.02-0.04,0.03
			c-0.42,0.3-1.16,0.82-2.31,0.89c-0.11,0.25-0.28,0.43-0.49,0.53c-0.51,0.24-1.05-0.06-1.58-0.35c-0.29-0.16-0.76-0.42-0.92-0.35
			c-0.02,0.01-0.19,0.11-0.3,0.77l-0.04,0.23l-0.2,0.12c-0.61,0.36-1.31,0.24-1.99,0.12c-1.15-0.2-1.77-0.25-2.23,0.94l-0.11,0.17
			c-0.16,0.16-0.31,0.33-0.47,0.49c-1.23,1.31-2.76,2.94-5.2,1.66l-0.14-0.11c-0.84-0.93-1.57-1.41-2.19-1.41c-0.01,0-0.01,0-0.02,0
			c-0.82,0.01-1.53,0.85-2.28,1.74c-0.26,0.3-0.51,0.6-0.78,0.88c-0.37,0.43-0.91,0.97-1.64,1.13c-0.71,0.15-1.45-0.09-2.24-0.74
			c-0.32-0.04-0.6,0.25-1.07,0.84c-0.4,0.5-0.81,1.01-1.41,1.18l-0.3,0.09l-0.21-0.23c-0.25-0.27-0.36-0.29-0.36-0.29
			c-0.12,0.07-0.35,0.65-0.47,0.96c-0.26,0.66-0.52,1.33-1.08,1.54c-0.19,0.07-0.48,0.11-0.84-0.07l-0.11-0.06l-0.07-0.1
			c-0.57-0.83-0.83-0.87-0.86-0.87c-0.11,0-0.29,0.21-0.42,0.36c-0.28,0.31-0.62,0.69-1.16,0.69c0,0,0,0,0,0
			c-0.38,0-0.76-0.2-1.15-0.62c-0.78-0.5-1.34-0.39-2.03-0.26c-0.4,0.08-0.8,0.16-1.27,0.13l-0.16-0.01l-0.12-0.1
			c-0.97-0.82-1.43-0.82-1.61-0.75c-0.19,0.07-0.53,0.38-0.71,1.64l-0.04,0.3l-0.28,0.1c-0.48,0.17-0.97,0.15-1.45,0.12
			c-0.69-0.04-1.12-0.04-1.43,0.34c0.14,3.62-0.11,4.92-0.95,5.14c-0.76,0.19-1.53-0.64-2.73-3c-0.53-0.6-1.56-1.76-1.91-1.76
			c0,0,0,0,0,0c0.02,0-0.28,0.3-0.16,2.31l0.07,1.19l-0.9-0.78c-0.15-0.13-0.22-0.15-0.26-0.15c-0.1,0.01-0.31,0.31-0.42,0.47
			c-0.27,0.37-0.6,0.83-1.14,0.86c-0.33,0.01-0.66-0.12-1-0.43c-0.43-0.11-0.86-0.51-1.3-0.92c-0.2-0.19-0.51-0.48-0.71-0.6
			c-0.05,0.2-0.09,0.66,0.01,1.72c0.07,1.46-0.12,2.17-0.63,2.44c-0.57,0.31-1.16-0.11-1.67-0.48c-0.12-0.09-0.29-0.21-0.43-0.29
			c-0.73,0.95-1.77,0.81-2.55,0.7c-0.93-0.13-1.26-0.14-1.5,0.57l-0.06,0.17l-0.15,0.09c-0.75,0.47-1.57,0.43-2.23,0.39
			c-0.53-0.03-0.98-0.05-1.23,0.17l-0.14,0.13l-0.19,0c-0.89,0.01-1.5,0.44-1.91,0.78C162.2,149.31,162.09,149.4,161.97,149.47z
			M161.74,148.68L161.74,148.68L161.74,148.68L161.74,148.68z"/>
	</g>
	<g>
		<path class="st_aquisition_5" d="M660.68,131.99l65.8,22.95c0,0,1.55-5.25,1.8-6.12c-0.31,1.04-0.91-0.9-2.97-0.88
			c-0.81-0.73-2.32,0.14-3.53-0.61c-0.77-2.25-3.16,0.39-4.31-1.7c-0.87-0.03-2.62,2.77-2.45-1.04c0.46-4.78-1.72-0.72-2.73-0.71
			c-1.46,1.53-1.49-1.89-2.92-0.66c0.27-4.51-1.33-2.75-2.99-0.86c-2.62,5.16-2.94,2.45-2.76-2.01c-0.78-1.2-2.16-0.4-3.21-0.78
			c-0.39-2.61-1.59-2.51-3.14-1.2c-1.2,0.08-2.13-0.74-3.59,0.24c-1.74,1.92-1.63-2.46-3.69,0.55c-1.38,0.72-1.19-3.91-2.9-2.03
			c-1.07-0.31-1.6-2.38-2.85-2c-1.52,1.3-2.46,0.69-3.28-0.27c-1.68-1.76-2.99-4.56-6-1.21c-2.39,1.25-3.73-0.69-5.09-2.06
			c-1-2.58-3.21-0.58-4.43-1.31c-0.52-3.11-3,1.07-3.41-1.01c-2.08,0.06-2.78-1.54-2.97-0.88
			C656.58,130.65,660.68,131.99,660.68,131.99z"/>
		<path d="M726.81,155.59l-0.5-0.17l-65.79-22.95c-0.27-0.09-2.41-0.84-2.72-2.3c-0.15-0.69,0.13-1.38,0.83-2.05
			c0.1-0.21,0.25-0.3,0.37-0.34c0.34-0.12,0.62,0.08,0.92,0.29c0.42,0.3,1.04,0.74,2.09,0.71l0.42-0.01l0.08,0.41
			c0.04,0.18,0.08,0.23,0.08,0.23c0.07-0.02,0.43-0.22,0.64-0.34c0.54-0.3,1.22-0.68,1.84-0.38c0.39,0.19,0.65,0.59,0.8,1.23
			c0.29,0.07,0.7,0,1.12-0.08c1-0.18,2.51-0.44,3.3,1.46c0.14,0.14,0.28,0.29,0.42,0.44c1.37,1.46,2.33,2.29,3.93,1.5
			c1.04-1.13,1.99-1.71,2.9-1.67c1.27,0.02,2.17,1.07,3.03,2.09c0.24,0.29,0.49,0.57,0.74,0.84c0.44,0.52,0.78,0.77,1.12,0.84
			c0.41,0.09,0.9-0.11,1.47-0.6l0.18-0.1c1.05-0.33,1.73,0.53,2.23,1.15c0.22,0.28,0.45,0.56,0.67,0.72
			c0.24-0.2,0.59-0.41,0.99-0.33c0.66,0.12,0.95,0.86,1.24,1.58c0.11,0.29,0.32,0.81,0.47,0.95c0.54-0.75,1.01-1.11,1.51-1.14
			c0.57-0.05,0.95,0.38,1.23,0.68c0.13,0.14,0.33,0.36,0.42,0.36c0.02,0,0.16-0.01,0.46-0.34l0.09-0.08
			c1.15-0.77,2.04-0.59,2.83-0.44c0.29,0.06,0.57,0.11,0.86,0.12c0.67-0.54,1.6-1.14,2.47-0.81c0.65,0.25,1.07,0.93,1.29,2.1
			c0.24,0.03,0.51,0.02,0.79,0c0.78-0.04,1.76-0.09,2.4,0.91l0.09,0.13l-0.01,0.16c-0.12,3.07,0.07,3.98,0.21,4.25
			c0.18-0.14,0.67-0.64,1.61-2.49l0.07-0.1c1.16-1.32,2.16-2.46,3.07-2.08c0.59,0.24,0.85,0.99,0.83,2.5c0.06,0,0.11,0,0.17,0.01
			c0.53,0.06,0.86,0.51,1.12,0.88c0.11,0.15,0.31,0.43,0.4,0.45c0,0,0.11-0.01,0.33-0.25l0.15-0.15h0.21
			c0.15-0.03,0.59-0.44,0.85-0.68c0.64-0.59,1.23-1.15,1.85-0.85c0.55,0.27,0.7,1.05,0.53,2.8c-0.06,1.25,0.13,1.48,0.14,1.49
			c0.04-0.04,0.38-0.29,0.59-0.43c0.42-0.3,0.82-0.56,1.24-0.57l0.28,0.01l0.14,0.25c0.38,0.7,0.87,0.66,1.76,0.54
			c0.79-0.11,1.96-0.28,2.52,1.05c0.45,0.22,0.97,0.19,1.51,0.16c0.62-0.04,1.26-0.06,1.79,0.3c1.17,0.04,1.92,0.63,2.37,0.99
			c0.1,0.08,0.23,0.18,0.28,0.2c-0.08-0.03-0.21-0.01-0.29,0.05l0.92,0.27L726.81,155.59z M659.3,128.86
			c-0.42,0.4-0.6,0.77-0.53,1.1c0.16,0.77,1.58,1.4,2.06,1.56l0.01,0l65.3,22.78l1.42-4.82c-0.11-0.07-0.23-0.16-0.34-0.25
			c-0.42-0.33-0.98-0.77-1.91-0.78l-0.19,0l-0.14-0.13c-0.25-0.22-0.7-0.2-1.23-0.17c-0.66,0.04-1.48,0.08-2.23-0.39l-0.15-0.09
			l-0.06-0.17c-0.24-0.71-0.58-0.71-1.5-0.57c-0.78,0.11-1.81,0.25-2.55-0.7c-0.14,0.08-0.31,0.21-0.43,0.29
			c-0.52,0.37-1.1,0.79-1.67,0.48c-0.51-0.28-0.69-0.98-0.63-2.42c0.1-1.08,0.06-1.55,0.01-1.75c-0.2,0.13-0.51,0.41-0.71,0.6
			c-0.44,0.41-0.87,0.81-1.3,0.92c-0.34,0.31-0.66,0.44-1,0.43c-0.55-0.04-0.88-0.5-1.14-0.86c-0.11-0.16-0.33-0.46-0.42-0.47
			c-0.03-0.01-0.11,0.02-0.26,0.15l-0.9,0.78l0.07-1.18c0.12-2.03-0.18-2.32-0.2-2.33c-0.31,0-1.35,1.17-1.88,1.78
			c-1.2,2.36-1.97,3.2-2.73,3c-0.85-0.22-1.09-1.52-0.95-5.14c-0.31-0.38-0.74-0.38-1.43-0.34c-0.48,0.02-0.96,0.05-1.45-0.12
			l-0.28-0.1l-0.04-0.3c-0.19-1.27-0.53-1.57-0.71-1.64c-0.18-0.07-0.64-0.06-1.61,0.75l-0.12,0.1l-0.16,0.01
			c-0.47,0.03-0.88-0.05-1.27-0.13c-0.7-0.14-1.25-0.25-2.03,0.26c-0.39,0.42-0.77,0.62-1.15,0.62c0,0,0,0,0,0
			c-0.54,0-0.88-0.38-1.16-0.69c-0.13-0.14-0.33-0.36-0.42-0.36c-0.03,0-0.29,0.04-0.86,0.87l-0.07,0.1l-0.11,0.06
			c-0.36,0.19-0.66,0.14-0.84,0.07c-0.56-0.21-0.83-0.89-1.08-1.54c-0.12-0.31-0.35-0.88-0.49-0.96c0,0.01-0.11,0.05-0.34,0.29
			l-0.21,0.23l-0.3-0.09c-0.6-0.17-1.01-0.68-1.41-1.18c-0.47-0.59-0.75-0.88-1.07-0.84c-0.79,0.65-1.52,0.89-2.24,0.74
			c-0.73-0.15-1.27-0.69-1.65-1.15c-0.25-0.26-0.5-0.56-0.76-0.86c-0.75-0.89-1.46-1.73-2.28-1.74c-0.01,0-0.01,0-0.02,0
			c-0.62,0-1.35,0.47-2.19,1.41l-0.14,0.11c-2.44,1.28-3.97-0.35-5.2-1.66c-0.16-0.17-0.31-0.33-0.47-0.49l-0.11-0.17
			c-0.46-1.19-1.09-1.14-2.23-0.94c-0.68,0.12-1.38,0.24-1.99-0.12l-0.2-0.12l-0.04-0.23c-0.11-0.66-0.28-0.76-0.3-0.77
			c-0.15-0.07-0.63,0.19-0.92,0.35c-0.52,0.29-1.06,0.59-1.58,0.35c-0.21-0.1-0.38-0.28-0.49-0.53c-1.15-0.07-1.9-0.59-2.31-0.89
			C659.33,128.88,659.32,128.87,659.3,128.86z M727.78,148.74c0,0-0.01,0.01-0.01,0.02L727.78,148.74z"/>
	</g>
</g>
<g id="Jerseys" class="st_aquisition_6">
	<g class="st_aquisition_7">
		<polygon class="st_aquisition_8" points="252.94,90.75 274.76,90.75 274.76,82.25 267.28,75.25 264.28,70.25 262.28,55.25 252.78,55.25 		"/>
		<polygon class="st_aquisition_8" points="632.59,90.75 610.78,90.75 610.78,82.25 618.26,75.25 621.26,70.25 623.26,55.25 632.76,55.25 		"/>
	</g>
</g>
<g id="Metal_x5F_guard">
	<g>
		<g>
			<g>
				<path class="st_aquisition_9" d="M635.64,70.89h-13.53c-0.7,0-1.27-0.57-1.27-1.27l0,0c0-0.7,0.57-1.27,1.27-1.27h13.53
					c0.7,0,1.27,0.57,1.27,1.27l0,0C636.91,70.32,636.34,70.89,635.64,70.89z"/>
				<path d="M635.64,71.21h-13.53c-0.87,0-1.59-0.71-1.59-1.59s0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59
					S636.51,71.21,635.64,71.21z M622.11,68.67c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
					c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H622.11z"/>
			</g>
			<g>
				<polygon class="st_aquisition_9" points="623.8,69.62 617.88,62.86 623.8,55.24 629.72,55.24 623.8,62.86 629.72,69.62 623.8,78.08 
					629.72,84.84 623.8,84.84 617.75,78.08 				"/>
				<path d="M630.42,85.16h-6.76l-6.3-7.06l6.04-8.46l-5.93-6.77l6.18-7.94h6.72l-6.16,7.92l5.91,6.76l-5.92,8.46L630.42,85.16z
				M623.94,84.53h5.08l-5.62-6.43l5.92-8.46l-5.93-6.78l5.68-7.31h-5.12l-5.66,7.28l5.91,6.76l-6.04,8.45L623.94,84.53z"/>
			</g>
			<g>
				<rect x="630.14" y="54.82" class="st_aquisition_10" width="4.23" height="36.37"/>
				<path d="M634.69,91.5h-4.86v-37h4.86V91.5z M630.46,90.87h3.59V55.14h-3.59V90.87z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st_aquisition_9" d="M250.18,70.89h13.53c0.7,0,1.27-0.57,1.27-1.27l0,0c0-0.7-0.57-1.27-1.27-1.27h-13.53
					c-0.7,0-1.27,0.57-1.27,1.27l0,0C248.91,70.32,249.48,70.89,250.18,70.89z"/>
				<path d="M248.6,69.62c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59s-0.71,1.59-1.59,1.59h-13.53
					C249.31,71.21,248.6,70.5,248.6,69.62z M250.18,68.67c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
					c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H250.18z"/>
			</g>
			<g>
				<polygon class="st_aquisition_9" points="262.02,69.62 267.94,62.86 262.02,55.24 256.1,55.24 262.02,62.86 256.1,69.62 262.02,78.08 
					256.1,84.84 262.02,84.84 268.06,78.08 				"/>
				<path d="M261.62,78.06l-5.92-8.46l5.91-6.76l-6.16-7.92h6.72l6.18,7.94l-5.93,6.77l6.04,8.46l-6.3,7.06h-6.76L261.62,78.06z
				M267.66,78.06l-6.04-8.45l5.91-6.76l-5.66-7.28h-5.12l5.68,7.31l-5.93,6.78l5.92,8.46l-5.62,6.43h5.08L267.66,78.06z"/>
			</g>
			<g>
				
					<rect x="251.45" y="54.82" transform="matrix(-1 -4.473722e-11 4.473722e-11 -1 507.1322 146.0081)" class="st_aquisition_10" width="4.23" height="36.37"/>
				<path d="M251.13,54.5H256v37h-4.86V54.5z M255.36,55.14h-3.59v35.73h3.59V55.14z"/>
			</g>
		</g>
	</g>
</g>
<g id="Lines">
	<polygon class="st_aquisition_11" points="890.54,154.3 726.15,154.3 658.77,129.96 442.77,126 230.77,129.96 162.73,155.59 0,155.59 0,181.53 
		890.56,181.53 	"/>
</g>
<g id="Car">
	<g>
		<g>
			<path class="st_aquisition_12" d="M335.19,81.73c0,2.06-1.69,3.75-3.75,3.75h-3.62c-2.06,0-3.75-1.69-3.75-3.75V66.36
				c0-2.06,1.69-3.75,3.75-3.75h3.62c2.06,0,3.75,1.69,3.75,3.75V81.73z"/>
			<path class="st_aquisition_12" d="M408.85,81.73c0,2.06-1.69,3.75-3.75,3.75h-3.62c-2.06,0-3.75-1.69-3.75-3.75V66.36
				c0-2.06,1.69-3.75,3.75-3.75h3.62c2.06,0,3.75,1.69,3.75,3.75V81.73z"/>
		</g>
		<g>
			<path class="st_aquisition_13" d="M404.44,47.29c0,0-6.5-17.51-11-21.51c-4.25-3.78-14.28-5.32-25.9-5.49v-0.02c-0.35,0-0.7,0.01-1.06,0.01
				c-0.35,0-0.7-0.01-1.06-0.01v0.02c-11.62,0.16-21.66,1.71-25.9,5.49c-4.5,4-11.01,21.51-11.01,21.51
				c-9.87,0-2.81,23.68-0.6,30.42c0.35,1.06,1.33,1.78,2.45,1.78l35.06,0.3v0.02l1.06-0.01l1.06,0.01v-0.02l35.06-0.3
				c1.11-0.01,2.1-0.73,2.45-1.78C407.25,70.97,414.31,47.29,404.44,47.29z"/>
			<g>
				<path class="st_aquisition_14" d="M388.68,28.03c-5.63-4.13-22.2-4.5-22.2-4.5s-16.58,0.38-22.21,4.5c-5.63,4.13-12.38,18.88-12.38,18.88
					h34.59h34.59C401.06,46.91,394.31,32.16,388.68,28.03z"/>
				<g>
					<g>
						<g>
							<path class="st_aquisition_15" d="M341.09,54.5c0,1.91-1.27,3.46-2.85,3.46h-7.57c-1.57,0-2.85-1.55-2.85-3.46l0,0
								c0-1.91,1.27-3.46,2.85-3.46h7.57C339.81,51.04,341.09,52.59,341.09,54.5L341.09,54.5z"/>
							<path class="st_aquisition_15" d="M405.12,54.5c0,1.91-1.27,3.46-2.84,3.46h-7.57c-1.57,0-2.85-1.55-2.85-3.46l0,0
								c0-1.91,1.27-3.46,2.85-3.46h7.57C403.84,51.04,405.12,52.59,405.12,54.5L405.12,54.5z"/>
						</g>
						<g>
							<path class="st_aquisition_16" d="M341.09,59.61c0,0.36-1.27,0.66-2.85,0.66h-7.57c-1.57,0-2.85-0.29-2.85-0.66l0,0
								c0-0.36,1.27-0.66,2.85-0.66h7.57C339.81,58.95,341.09,59.24,341.09,59.61L341.09,59.61z"/>
							<path class="st_aquisition_16" d="M405.12,59.61c0,0.36-1.27,0.66-2.84,0.66h-7.57c-1.57,0-2.85-0.29-2.85-0.66l0,0
								c0-0.36,1.27-0.66,2.85-0.66h7.57C403.84,58.95,405.12,59.24,405.12,59.61L405.12,59.61z"/>
						</g>
						<path class="st_aquisition_17" d="M387.49,58.76c0,2.96-1.69,5.38-3.75,5.38h-34.52c-2.06,0-3.75-2.42-3.75-5.38s1.69-5.38,3.75-5.38
							h34.52C385.8,53.37,387.49,55.79,387.49,58.76z"/>
						<rect x="354.47" y="67.52" class="st_aquisition_15" width="24.01" height="6.03"/>
						<rect x="355.26" y="68.22" class="st_aquisition_18" width="1.25" height="4.6"/>
					</g>
					<g>
						<path class="st_aquisition_15" d="M338.52,75.3c0,0.41-0.34,0.75-0.75,0.75h-5.13c-0.41,0-0.75-0.34-0.75-0.75v-2.52
							c0-0.41,0.34-0.75,0.75-0.75h5.13c0.41,0,0.75,0.34,0.75,0.75V75.3z"/>
						<path class="st_aquisition_15" d="M401.8,75.3c0,0.41-0.34,0.75-0.75,0.75h-5.13c-0.41,0-0.75-0.34-0.75-0.75v-2.52
							c0-0.41,0.34-0.75,0.75-0.75h5.13c0.41,0,0.75,0.34,0.75,0.75V75.3z"/>
					</g>
				</g>
				<g>
					<path class="st_aquisition_12" d="M331.44,45.94c0,0.83-0.68,1.5-1.5,1.5h-5.49c-0.83,0-1.5-0.68-1.5-1.5v-1.06c0-0.83,0.68-1.5,1.5-1.5
						h5.49c0.83,0,1.5,0.68,1.5,1.5V45.94z"/>
					<path class="st_aquisition_12" d="M409.96,45.94c0,0.83-0.68,1.5-1.5,1.5h-5.49c-0.83,0-1.5-0.68-1.5-1.5v-1.06c0-0.83,0.68-1.5,1.5-1.5
						h5.49c0.83,0,1.5,0.68,1.5,1.5V45.94z"/>
				</g>
			</g>
		</g>
	</g>
	<text transform="matrix(1 0 0 1 210.8144 255.7469)" class="st_aquisition_3 st_aquisition_4">{{currentCalc.values_params.others.aquisition_offset}} m</text>
	<text transform="matrix(1 0 0 1 655.7839 255.7469)" class="st_aquisition_3 st_aquisition_4">{{currentCalc.values_params.others.aquisition_offset}} m</text>
	<text transform="matrix(1 0 0 1 32.5685 216.4852)" class="st_aquisition_3 st_aquisition_4">{{ currentCalc.values_params.others.aquisition_clearance }} m</text>
	<text transform="matrix(1 0 0 1 833.1215 216.4852)" class="st_aquisition_3 st_aquisition_4">{{ currentCalc.values_params.others.aquisition_clearance }} m</text>
	<g>
		<g>
			<line class="st_aquisition_19" x1="0.5" y1="195.75" x2="89.5" y2="195.75"/>
			<g>
				<rect x="0" y="192.5" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="89" y="192.5" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="801.05" y1="195.75" x2="890.05" y2="195.75"/>
			<g>
				<rect x="800.55" y="192.5" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="889.55" y="192.5" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="0.5" y1="232.28" x2="445.99" y2="232.28"/>
			<g>
				<rect x="0" y="229.03" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="445.49" y="229.03" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="446.38" y1="232.28" x2="890.05" y2="232.28"/>
			<g>
				<rect x="445.88" y="229.03" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="889.55" y="229.03" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="162.47" y1="195.75" x2="728.26" y2="195.75"/>
			<g>
				<rect x="161.97" y="192.5" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="727.76" y="192.5" width="1" height="6.5"/>
			</g>
		</g>
	</g>
</g>
</svg>


</template>
<script>
export default {
  name: "SvgPlatormAquisition1LaneMetalGuard",
  props: {
    currentCalc: undefined,
  },
};
</script>
<style scoped>
.st_aquisition_0{fill:#DD8F64;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_aquisition_1{fill:#7C503A;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_aquisition_2{fill:none;stroke:#7C7C7C;stroke-width:4;stroke-miterlimit:10;}
	.st_aquisition_3{font-family:'MyriadPro-Regular';}
	.st_aquisition_4{font-size:12px;}
	.st_aquisition_5{fill:#8FCE57;}
	.st_aquisition_6{display:none;}
	.st_aquisition_7{display:inline;}
	.st_aquisition_8{fill:#686868;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_aquisition_9{fill:#686868;}
	.st_aquisition_10{fill:#72502F;}
	.st_aquisition_11{fill:#4C3125;stroke:#000000;stroke-miterlimit:10;}
	.st_aquisition_12{fill:#42454C;}
	.st_aquisition_13{fill:#2B2B2B;}
	.st_aquisition_14{fill:#383847;}
	.st_aquisition_15{fill:#FFFFFF;}
	.st_aquisition_16{fill:#F9E873;}
	.st_aquisition_17{fill:#595959;}
	.st_aquisition_18{fill:#174EFF;}
	.st_aquisition_19{fill:none;stroke:#000000;stroke-miterlimit:10;}
</style>
