<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformTitleSVG2">
        <strong> {{ selectedLanguage['platformAquisition1Lane_component_text1'] }} </strong>
      </v-col>
    </v-row>    
    <SvgPlatormAquisition1Lane v-if="params.central_barrier==this.selectedLanguage['platformAquisition1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platformAquisition1Lane_component_text2'] && this.currentCalc.values_params.platform.include_gutter_calc == true" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG2"/>
    <SvgPlatormAquisition1LaneJersey v-else-if="params.central_barrier==this.selectedLanguage['platformAquisition1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platformAquisition1Lane_component_text3']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG2"/>
    <SvgPlatormAquisition1LaneMetalGuard  v-else-if="params.central_barrier==this.selectedLanguage['platformAquisition1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platformAquisition1Lane_component_text4']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG2"/>
    <SvgPlatormAquisition_1_Lane_gutter_N v-else-if="params.central_barrier==this.selectedLanguage['platformAquisition1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platformAquisition1Lane_component_text2'] && this.currentCalc.values_params.platform.include_gutter_calc == false" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG2Gutter"/>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsOthersAquisitionOffset">
          <v-col style="align-self: center"> {{ selectedLanguage['platformAquisition1Lane_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.others.aquisition_offset
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkAvgEarthworkWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platformAquisition1Lane_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.avg_earthwork_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsOthersAquisitionClearance">
          <v-col style="align-self: center"> {{ selectedLanguage['platformAquisition1Lane_component_text7'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.others.aquisition_clearance
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SvgPlatormAquisition1Lane from "@/components/SvgComponents/roadway/platformAquisition/SvgPlatormAquisition1Lane.vue";
import SvgPlatormAquisition1LaneJersey from "@/components/SvgComponents/roadway/platformAquisition/SvgPlatormAquisition1LaneJersey.vue";
import SvgPlatormAquisition1LaneMetalGuard from "@/components/SvgComponents/roadway/platformAquisition/SvgPlatormAquisition1LaneMetalGuard.vue";
import SvgPlatormAquisition_1_Lane_gutter_N from "@/components/SvgComponents/roadway/platformAquisition/SvgPlatormAquisition_1_Lane_gutter_N.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgPlatormAquisition1Lane,
    SvgPlatormAquisition1LaneJersey,
    SvgPlatormAquisition1LaneMetalGuard,
    SvgPlatormAquisition_1_Lane_gutter_N,
  },
  mounted(){
    // console.log('1: ',this.params.central_barrier)
    // console.log('2: ',this.selectedLanguage['platform1Lane_component_text2'])
  },
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
</style>