<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 189.58 163.1" style="enable-background:new 0 0 189.58 163.1;" xml:space="preserve">
<g id="Seção_1_Pista">
	<g id="Estrutura">
		<polyline class="st_tunnel_0" points="50.84,71.4 50.84,137.5 177.79,137.5 177.79,69.34 		"/>
		<path class="st_tunnel_0" d="M50.84,71.84c0-34.63,28.42-62.7,63.47-62.7s63.47,28.07,63.47,62.7"/>
	</g>
	<g id="Medidas_2_">
		<path class="st_tunnel_1" d="M180.51,154.29"/>
		<path class="st_tunnel_1" d="M48.46,154.29"/>
		<line class="st_tunnel_1" x1="48.67" y1="149.7" x2="48.67" y2="158.92"/>
		<line class="st_tunnel_1" x1="180.3" y1="149.7" x2="180.3" y2="158.46"/>
		<path class="st_tunnel_1" d="M37.18,140.4"/>
		<path class="st_tunnel_1" d="M37.18,67.96"/>
		<path class="st_tunnel_1" d="M37.18,9.97"/>
		<line class="st_tunnel_1" x1="34.16" y1="67.31" x2="40.49" y2="67.31"/>
		<line class="st_tunnel_1" x1="33.94" y1="8.97" x2="40.28" y2="8.97"/>
		<line class="st_tunnel_1" x1="34.29" y1="140.4" x2="40.63" y2="140.4"/>
		<text transform="matrix(0.9 0 0 1 3.103 40.8155)" class="st_tunnel_2 st_tunnel_3">3.25m</text>
		<text transform="matrix(0.9 0 0 1 6.6802 103.7965)" class="st_tunnel_2 st_tunnel_3">5.1m</text>
		<text transform="matrix(0.9 0 0 1 107.0962 152.0192)" class="st_tunnel_2 st_tunnel_3">{{currentCalc.values_params.structure.tunnel_2lanes_width}}m</text>
		<g>
			<g>
				<line class="st_tunnel_1" x1="37.18" y1="66.33" x2="37.18" y2="9.71"/>
				<g>
					<polygon points="38.03,65.33 37.18,66.18 36.34,65.33 36.34,66.05 37.18,66.9 38.03,66.05 					"/>
				</g>
				<g>
					<polygon points="38.03,10.71 37.18,9.86 36.34,10.71 36.34,9.99 37.18,9.14 38.03,9.99 					"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st_tunnel_1" x1="37.51" y1="139.76" x2="37.24" y2="68.16"/>
				<g>
					<polygon points="38.36,138.76 37.51,139.61 36.66,138.77 36.67,139.49 37.52,140.33 38.36,139.48 					"/>
				</g>
				<g>
					<polygon points="38.09,69.16 37.24,68.31 36.4,69.16 36.4,68.44 37.24,67.59 38.09,68.44 					"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st_tunnel_1" x1="49.57" y1="154.51" x2="179.55" y2="154.23"/>
				<g>
					<polygon points="50.56,155.35 49.71,154.51 50.56,153.66 49.84,153.66 48.99,154.51 49.84,155.35 					"/>
				</g>
				<g>
					<polygon points="178.55,155.08 179.4,154.23 178.55,153.38 179.27,153.38 180.12,154.23 179.27,155.08 					"/>
				</g>
			</g>
		</g>
	</g>
	<g id="Asphalt">
	</g>
	<g id="Jerseys">
	</g>
</g>
<g id="Estrutura_1_">
</g>
<g id="Asphalt_x5F_and_components">
	<rect x="57.1" y="132.53" width="114.3" height="1.79"/>
</g>
<g id="Canaleta">
	<g>
		<polygon class="st_tunnel_4" points="59.1,134.43 53.74,134.43 53.74,130.66 55.31,130.66 55.31,132.53 59.1,132.53 		"/>
	</g>
	<g>
		<polygon class="st_tunnel_4" points="174.77,134.32 169.41,134.32 169.41,132.53 173.2,132.53 173.2,130.66 174.77,130.66 		"/>
	</g>
</g>
<g id="Metal_x5F_guard">
</g>
<g id="Jerseys_1_">
</g>
</svg>

</template>
<script>
export default {
  name: "SvgPlatformTunnel2LaneComponent",
  props: {
    currentCalc: undefined,
  },
};
</script>
<style scoped>
	.st_tunnel_0{fill:none;stroke:#666666;stroke-width:6;stroke-miterlimit:10;}
	.st_tunnel_1{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_tunnel_2{font-family:'MyriadPro-Regular';}
	.st_tunnel_3{font-size:12px;}
	.st_tunnel_4{fill:#7C7C7C;}
</style>
