<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 595.68 203.7" style="enable-background:new 0 0 595.68 203.7;" xml:space="preserve">
<g id="Terreno">
	<path class="st_cut_0" d="M595.88-2.9c0-0.13,0,0.28,0,0.09c0-0.9,0,0.67,0,0.26c0-0.08,0,0.36,0,0.43c0,0.46,0,1.04,0,0.59
		c0,0.37,0,0.69,0,0.75c0-0.07,0,0.41,0,0.91c0,0.71,0,0.46,0,1.06c0,0.2,0,0.65,0,1.2c0,0.43,0,0.87,0,1.35c0,35.4,0,200.6,0,200.6
		H0.4c0-15.33,0-30.66,0-45.99c0-18.68,0-37.36,0-56.04c0-2.08-0.5-5.51-0.65-8.69c-0.04-0.8-0.05-1.83-0.03-2.32
		c0.02-0.66,0.08-1.89,0.19-2.08C0.02,88,0.18,87.9,0.4,87.53c0.05-0.04,0.24,0.35,0.57-0.5c0.32-0.38,0.77-3.42,1.33-3.83
		c0.55-0.31,1.21,0.02,1.95-1.09c0.74-0.72,1.56-0.82,2.44-1.28c0.88-0.57,1.82-1.05,2.79-1.4c0.97-0.52,1.99-0.76,3.01-1.45
		c1.02-0.48,2.06-1.17,3.09-1.43c1.03-0.65,2.05-0.84,3.04-1.34c0.99-0.5,1.95-0.8,2.85-1.18c0.91-0.18,1.76-0.59,2.53-0.95
		c0.77-0.11,1.47-0.3,2.07-0.65c0.6,0.06,1.1-0.3,1.48-0.27c0.38-0.02,0.64,0.03,0.75,0.17c0,0.15,0.03,0.15,0.09,0.11
		c0.06,0.33,0.14,0.39,0.26,0.31c0.11,0.24,0.25,0.01,0.42,0.5c0.17,0.22,0.36-0.44,0.57-0.31c0.22,0.37,0.46,0.91,0.72,0.87
		c0.26,0.22,0.55,0.65,0.86,1.04c0.31,0.71,0.64,0.66,1,1.2c0.35,0.43,0.73,0.54,1.13,1.36c0.4,0.33,0.81,1.3,1.25,1.51
		c0.44-0.13,0.89,1.06,1.37,1.65c0.47,0.62,0.97,1.02,1.48,1.78c0.51,0.68,1.04,1.29,1.58,1.91c0.54,0.4,1.1,1.53,1.68,2.02
		c0.57,0.95,1.16,1.12,1.77,2.13c0.6,0.68,1.22,1.24,1.85,2.23c0.63,0.68,1.27,1.13,1.93,2.33c0.65,0.37,1.32,1.51,2,2.41
		c0.68,0.8,1.36,1.9,2.06,2.49c0.7,0.87,1.4,1.99,2.12,2.56c0.72,0.66,1.44,1.66,2.17,2.62c0.73,1.11,1.47,1.69,2.22,2.68
		c0.75,0.84,1.5,1.68,2.26,2.72c0.76,1.08,1.52,1.65,2.29,2.76c0.77,0.93,1.54,1.86,2.31,2.79c3.87,4.68,7.83,9.45,11.73,14.16
		c0.78,0.94,1.56,1.88,2.33,2.82c0.77,1,1.55,2.01,2.31,2.79c0.77,0.69,1.53,2.04,2.29,2.76c0.76,0.91,1.51,1.82,2.26,2.72
		c0.75,0.9,1.49,1.79,2.22,2.68c0.73,0.6,1.46,1.52,2.17,2.62c0.72,1.01,1.42,1.59,2.12,2.56c0.7,0.51,1.39,1.67,2.06,2.49
		c0.68,0.82,1.34,1.62,2,2.41c0.65,0.75,1.3,1.51,1.93,2.33c0.63,0.42,1.25,1.07,1.85,2.23c0.6,0.95,1.19,1.41,1.76,2.13
		c0.57,0.84,1.13,1.69,1.67,2.02c0.54,0.69,1.07,1.29,1.58,1.9c0.51,0.62,1,0.96,1.47,1.78c0.47,0.85,0.93,5.1,1.36,5.65
		c0.44,0.46,0.85,0.9,1.25,1.51c0.4,0.9,0.77,0.99,1.13,1.36c0.35,0.69,0.69,1.01,1,1.2c0.31,0.44,0.6,1.27,0.86,1.04
		c0.26-0.28,0.5,0.88,0.72,0.87c0.22-0.06,0.41,0.36,0.57,0.69c0.16,0.98,0.3-0.53,0.42,0.5c0.11,0.55,0.2,0.14,0.25,0.31
		c0.06-0.25,0.09,0.03,0.09,0.1c0,0.11,0.03,0.4,0.08,0c0.05,0.29,0.14-0.74,0.25,0c0.11-0.05,0.24,1.02,0.4,0
		c0.16,0.3,0.35-0.65,0.56,0c0.21-0.46,0.45-0.6,0.71,0c0.26,0.1,0.55,0.01,0.86,0c0.31,0.35,0.64,0.03,1,0
		c0.36-0.08,0.74,0.07,1.14,0c0.4-0.02,0.83,0.11,1.28,0c0.45,0.17,0.92-0.19,1.41,0c0.49-0.23,1.01,0.11,1.54,0
		c0.53,0.02,1.09-0.49,1.67,0c0.58-0.01,1.17-0.38,1.79,0c0.62-0.18,1.25-0.06,1.91,0c0.65-0.12,1.33,0,2.02,0c1.11,0,2.26,0,3.46,0
		c0.6,0,1.21,0,1.83,0c0.62-0.24,1.25,0.05,1.9,0c0.64-0.05,1.3-0.38,1.96,0c0.41,0.54,0.83-0.06,1.26,0c0.42-0.25,0.85-0.06,1.28,0
		c0.43,0.08,0.86-0.14,1.3,0.08c0.44,0.13,0.88,0.17,1.32-0.08c0.45,0.47,0.89,0.06,1.35,0.04c0.45,0.14,0.91-0.21,1.37-0.04
		c0.46-0.09,0.92-0.17,1.39,0.03c0.47-0.17,0.94,0,1.41-0.03c0.47,0.34,0.95-0.07,1.43,0c0.48-0.02,0.96,0.01,1.45,0
		c0.7-0.16,1.41-0.21,2.13,0c0.72-0.23,1.44-0.24,2.17,0c0.73,0.02,1.46-0.54,2.2,0c0.74-0.27,1.49-0.08,2.24,0
		c0.75-0.31,1.51-0.63,2.27,0c0.76,0.72,1.53,0.46,2.31,0c0.77,0.55,1.55,0.2,2.34,0c0.78-0.16,1.57,1.18,2.37,0
		c0.79,0.25,1.59,0.45,2.4,0c0.8-0.29,1.61-0.54,2.42,0c0.81-0.17,1.63-0.53,2.45,0c0.82-0.56,1.65,0.21,2.47,0
		c0.83-0.47,1.66-0.33,2.5,0c0.84-0.31,1.68,0.03,2.52,0c0.84,0.21,1.69-0.07,2.54,0c0.85-0.22,1.7-0.15,2.56,0
		c0.86,0.08,1.71-0.13,2.58,0c0.86,0.09,1.73,0.03,2.59,0c0.6-0.35,1.2-0.06,1.8,0.04c0.6-0.16,1.21,0.15,1.81-0.04
		c0.6,0.05,1.21,0.09,1.82,0.14c0.61,0.02,1.21-0.01,1.82-0.14c1.22,0.23,2.43,0.19,3.66,0c1.22-0.09,2.45-0.06,3.67,0
		c1.23-0.08,2.46-0.16,3.69,0c1.23,0.05,2.46,0.43,3.7,0c1.23,0.06,2.47,0.03,3.7,0c1.23,0.07,2.47,0.07,3.7,0
		c1.23,0.06,2.47,0.15,3.7,0c1.23,0.14,2.47-0.13,3.7,0c1.23-0.1,2.46,0.21,3.69,0c1.23,0.05,2.45,0.12,3.67,0
		c1.22,0.02,2.44-0.13,3.66,0c1.22,0.03,2.43,0,3.64,0c2.42,0,4.82,0,7.2,0c1.19,0,2.37,0,3.55,0c1.18,0.2,2.35,0.06,3.52,0
		c1.17-0.08,2.33-0.01,3.48,0c1.15-0.25,2.3-0.09,3.43,0c1.14,0.19,2.27-0.43,3.39,0c1.12-0.27,2.23,0.39,3.34,0
		c1.1-0.03,2.2,0.26,3.28,0c1.08,0.07,2.16-0.14,3.22,0c1.06-0.17,2.12,0.02,3.16,0c1.04-0.24,2.08,0.05,3.1,0
		c1.02-0.04,2.03-0.02,3.03,0c1,0.06,1.98-0.19,2.96,0c0.97-0.02,1.93-0.19,2.88,0c0.95-0.21,1.88-0.06,2.8,0
		c0.92,0.09,1.82-0.04,2.71,0c0.89,0.19,1.77,0.15,2.63,0c0.86,0.18,1.71,0.07,2.53,0c0.83-0.2,1.64,0.01,2.44,0
		c0.8,0.1,1.58,0.25,2.34,0c0.76-0.24,1.51-0.35,2.24,0c0.73,0.18,1.44,0.48,2.13,0c0.69,0.22,1.37-0.43,2.02,0
		c0.65-0.06,1.29-0.44,1.91,0c0.62,0,1.21,0,1.79,0c0.58,0,1.13,0,1.67,0s1.05,0,1.54,0c0.49-0.02,0.96-0.06,1.41,0
		c0.45-0.04,0.88,0.04,1.28,0c0.4-0.05,0.79,0.04,1.14,0c0.36-0.08,0.69-0.33,1,0c0.31-0.09,0.6-0.01,0.86,0
		c0.26,0.22,0.5,0.05,0.71,0c0.21,0.32,0.4-0.48,0.56,0c0.16,0.12,0.3-0.25,0.4,0c0.11,0.13,0.19-0.43,0.25,0
		c0.05-0.02,0.08,0.06,0.08,0c0-0.16,0.03,0.24,0.09-0.1c0.06-0.48,0.14,0.01,0.25-0.3c0.11-0.04,0.25-0.27,0.42-0.48
		c0.16-0.29,0.35-0.61,0.57-0.66c0.22-0.25,0.46-0.53,0.72-0.84c0.79-0.92,1.8-2.09,2.99-3.48c0.4-0.46,0.81-0.95,1.25-1.46
		c0.44-0.48,0.89-0.85,1.37-1.59c0.47-0.66,0.97-5.25,1.48-5.72c0.51-0.6,1.04-0.96,1.58-1.85c0.54-0.95,1.11-1.45,1.68-1.96
		c0.58-0.67,1.17-1.27,1.77-2.07c0.61-0.91,1.23-1.44,1.86-2.17c0.63-1.18,1.28-1.19,1.94-2.26c0.66-0.51,1.33-1.36,2.01-2.34
		c0.68-0.59,1.37-2,2.08-2.42c0.7-1.04,1.42-2.4,2.14-2.49c0.72-0.03,1.45-2.06,2.19-2.55c0.74-0.74,1.48-2.09,2.24-2.61
		c0.75-0.74,1.51-1.8,2.28-2.66c0.77-1.09,1.54-1.96,2.31-2.7c0.78-0.91,1.56-1.82,2.34-2.73c2.35-2.74,4.74-5.53,7.14-8.32
		c0.8-0.93,1.6-2.04,2.39-2.79c0.8-1.14,1.59-1.77,2.39-2.79c0.8-0.96,1.59-1.86,2.38-2.78c0.79-0.75,1.58-1.84,2.36-2.76
		c0.78-0.83,1.57-1.91,2.34-2.73c0.78-1.12,1.55-1.81,2.31-2.7c0.77-0.74,1.53-1.85,2.28-2.66c0.75-1.11,1.5-1.84,2.24-2.61
		c0.74-1.05,1.47-1.6,2.19-2.55c0.72-0.77,1.43-1.32,2.14-2.49c0.7-0.84,1.4-1.26,2.08-2.42c0.68-0.9,1.35-0.92,2.01-2.34
		c0.66-0.39,1.3-1.36,1.94-2.26c0.63-0.74,1.25-1.34,1.86-2.17c0.61-0.54,1.2-1.4,1.77-2.07c0.58-0.59,1.14-1.59,1.68-1.96
		c0.54-1.48,1.07-1.08,1.58-1.85c0.51,0.14,1-1.05,1.48-1.72c0.47-0.52,0.93-1.22,1.37-1.59c0.44-0.05,0.85-0.99,1.25-1.46
		c0.4-1.06,0.77-0.98,1.13-1.31c0.35-0.41,0.69-0.66,1-1.16c0.31-0.55,0.6,1.37,0.86,1c0.26-0.12,0.5-0.78,0.72-0.84
		c0.22-0.08,0.41-0.69,0.57-0.66c0.16,0.05,0.3-0.28,0.42-0.48c0.11-0.06,0.2-0.39,0.25-0.3c0.06,0.17,0.09,0.05,0.09-0.1
		c0,0.01,0.13,0.24,0.37,0.02c0.24,0.16,0.6-0.18,1.06,0.07c0.46,0.17,1.02-0.08,1.66,0.11c0.65,0.24,1.38,0.32,2.19,0.14
		c0.81,0.27,1.69-0.02,2.63,0.17c0.94,0.4,1.94,0.16,2.99,0.2c1.05,0.11,2.15,0.16,3.28,0.21c1.13,0.04,2.29-0.18,3.48,0.23
		c1.18-0.1,2.39,0.13,3.6,0.23c1.21,0.12,2.43,0.37,3.64,0.24c1.21-0.06,2.41,0.02,3.6,0.23c1.18-0.21,2.35,0.12,3.48,0.23
		c1.13,0.21,2.23,0.47,3.28,0.21c1.05,0.33,2.05,0.21,2.99,0.2c0.94,0.06,1.82,0.12,2.63,0.17c3.23,0.21,5.27,0.34,5.27,0.34
		s0.71-2.73,1.97-4.05c0.32-0.33,0.67-0.69,1.05-1.09c0.38-0.81,0.8-1.62,1.24-1.3c0.45-0.71,0.92-1.54,1.42-1.48
		c0.5-0.51,1.04-0.84,1.59-1.66c0.56-0.23,1.14-1.16,1.75-1.82c0.61-0.61,1.23-1.53,1.88-1.96c0.65-0.75,1.32-1.25,2.01-2.09
		c0.69-0.72,1.4-1.45,2.12-2.21c5.07-5.28,10.93-11.38,16.8-17.49c0.84-0.87,1.68-1.97,2.51-2.61c0.84-0.87,1.67-1.74,2.5-2.6
		c0.83-1.14,1.65-1.64,2.47-2.57c0.82-0.55,1.63-1.72,2.43-2.53c0.8-0.69,1.59-1.54,2.37-2.47c0.78-0.78,1.55-1.49,2.3-2.4
		c0.75-0.94,1.49-1.51,2.22-2.31c0.72,0.32,1.43-1.1,2.12-2.21c0.69-0.61,1.36-1.07,2.01-2.09c0.65-1.43,1.28-0.93,1.88-1.96
		c0.61-0.97,1.19-0.77,1.75-1.82c0.56-0.58,1.09-1.76,1.59-1.66c0.5-0.88,0.98-1.19,1.42-1.48c0.45-1.01,0.86-1.46,1.24-1.3
		c0.38,0.03,0.73-0.63,1.05-1.09c0.32-0.64,0.6,1.31,0.84,1.13c0.24-0.1,0.45-0.56,0.62-0.64c0.17-0.53,0.29-0.56,0.38-0.4
		c0.09-0.19,0.13-0.73,0.13-0.14c0,0.26,0,0.12,0.01,0c0.01,0.24,0.02-0.48,0.05-0.01c0.03,0.48,0.07,0.38,0.13-0.03
		c0.06-0.47,0.15,0.26,0.26-0.06c0.11,0.01,0.25-0.85,0.43-0.09c0.17-0.74,0.38-0.55,0.63-0.13c0.25-0.31,0.54-0.39,0.88-0.18
		c0.34,0.01,0.73,0,1.18-0.24c0.45-0.11,0.95-0.04,1.51-0.3c0.56-0.11,1.19-0.23,1.89-0.37c0.7-0.08,1.46-0.48,2.3-0.44
		c0.84-0.02,1.76-0.52,2.76-0.53c1-0.03,2.09-0.28,3.26-0.62c1.17-0.22,2.44-0.46,3.8-0.72c1.36-0.26,2.82-0.68,4.38-0.82
		c1.56-0.43,3.23-0.13,5.01-0.94c1.78-0.75,3.67-0.65,5.67-1.06c2.01-0.72,4.13-0.77,6.38-1.18c0,0.42,0.21-0.23,0.58-0.17
		c0.38-0.07,0.92-0.18,1.59-0.46c0.67-0.01,1.48-0.5,2.38-0.69c0.9-0.15,1.89-0.63,2.94-0.85c1.05-0.35,2.16-0.57,3.28-0.95
		c1.13-0.36,2.27-0.71,3.39-0.98c1.13-0.2,2.23,1.52,3.28,1.05c1.05-0.87,2.04-0.62,2.94-0.85c0.9-0.99,1.71-0.55,2.38-0.69
		c0.68-0.06,1.22-0.99,1.59-0.46C595.68-2.77,595.88-2.75,595.88-2.9z"/>
	<path class="st_cut_1" d="M595.88-2.9v207.23H0.4c0-15.33,0-30.66,0-45.99c0-18.68,0-37.36,0-56.04c0-3.64-1.54-11.41-0.01-14.78
		c0.61-1.34,26.42-13.98,27.9-12.19c0.02,0.03,84.75,102.3,84.75,102.3h219.77l87.76-102.3l46.14,3.01l63.67-66.3
		c0,0,0.04-0.23,40.54-7.73L595.88-2.9z"/>
</g>
<g id="Corte">
	<path class="st_cut_2" d="M28.3,75.33l84.32,102.88l220.2-0.57l87.76-102.3l46.14,3.01l63.67-66.3c0,0-158.03,12.26-209.98,21.56
		C279.22,40.97,28.3,75.33,28.3,75.33z"/>
</g>
<g id="Canaleta" class="st_cut_3">
	<g class="st_cut_4">
		<polygon class="st_cut_5" points="11.23,326.53 11.23,339.52 -11.04,339.52 -11.04,326.53 -8.66,326.53 -8.66,337.14 8.85,337.14 
			8.85,326.53 		"/>
	</g>
	<g class="st_cut_4">
		<polygon class="st_cut_5" points="221.28,326.53 221.28,339.52 199,339.52 199,326.53 201.38,326.53 201.38,337.14 218.9,337.14 
			218.9,326.53 		"/>
	</g>
	<g class="st_cut_4">
		<rect x="-9.99" y="326.53" class="st_cut_6" width="19.86" height="11.41"/>
	</g>
	<g class="st_cut_4">
		<rect x="200.24" y="326.53" class="st_cut_6" width="19.86" height="11.41"/>
	</g>
</g>
<g id="Canteiro">
	<g>
		<polygon class="st_cut_7" points="229.26,170.79 229.26,179.63 216.22,179.63 216.22,170.82 217.67,170.86 227.8,170.92 		"/>
		<path d="M229.5,179.87h-13.52v-9.3l1.7,0.04l10.13,0.07l1.69-0.16V179.87z M216.46,179.39h12.55v-8.33l-1.19,0.11l-10.15-0.06
			l-1.21-0.03V179.39z"/>
	</g>
	<g class="st_cut_3">
		<polygon class="st_cut_8" points="229.26,170.48 229.26,179.42 216.09,179.42 216.09,170.51 217.55,170.55 217.55,172.07 
			227.79,172.07 227.79,170.62 		"/>
		<path class="st_cut_4" d="M229.51,179.66h-13.67v-9.4l1.96,0.05v1.51h9.75v-1.43l1.96-0.18V179.66z M216.33,179.17h12.69v-8.42
			l-0.98,0.09v1.48h-10.73v-1.53l-0.98-0.02V179.17z"/>
	</g>
</g>
<g id="Sub_x5F_base">
	<g>
		<polygon class="st_cut_9" points="233.45,179.37 233.45,179.42 233.4,179.42 		"/>
		<polygon points="233.69,179.66 232.85,179.66 233.69,178.73 		"/>
	</g>
	<g>
		<polygon class="st_cut_10" points="214.46,174.77 164.88,173.79 111.7,174.76 117.72,169.91 164.88,169.14 211.75,170.12 		"/>
		<path d="M111.55,174.87l6.65-4.99l0.14,0l46.53-0.98l47.02,0.98l3,5.15l-50.02-0.99L111.55,174.87z M164.88,173.55l49.16,0.97
			l-2.42-4.16l-46.73-0.98l-46.38,0.98l-4.86,3.77L164.88,173.55z"/>
	</g>
	<g>
		<polygon class="st_cut_11" points="217.14,179.43 164.88,178.45 112.96,179.43 111.55,175.43 112.7,174.93 115.57,174.77 
			164.88,173.79 214.53,174.77 		"/>
		<path d="M112.53,179.68l-1.41-4.2l1.2-0.95l3.25,0l49.31-0.98l49.8,0.98l2.89,5.15l-52.69-0.99L112.53,179.68z M164.88,178.2
			l51.84,0.97l-2.34-4.16l-49.51-0.98l-52,0.98l-1.11,0.5l1.61,3.66L164.88,178.2z"/>
	</g>
	<g>
		<polygon class="st_cut_10" points="333.84,174.77 281.06,173.79 231.81,174.77 234.52,170.12 281.06,169.14 328.73,170.12 		"/>
		<path d="M231.38,175.03l3-5.15l0.14,0l46.53-0.98l47.02,0.98l6.06,5.15l-53.08-0.99L231.38,175.03z M281.05,173.55l51.37,0.97
			l-4.63-4.16l-46.73-0.98l-46.38,0.98l-2.42,4.16L281.05,173.55z"/>
	</g>
	<g>
		<polygon class="st_cut_11" points="333.32,179.43 281.06,178.45 229.13,179.43 231.74,174.77 281.06,173.79 334.79,174.76 
			334.13,177.43 		"/>
		<path d="M228.71,179.68l2.89-5.15l0.14,0l49.31-0.98l53.19,0.98l0.55,1.39l-1.05,3.76l-52.69-0.99L228.71,179.68z M281.05,178.2
			l51.84,0.97l1.35-3.12l-0.75-0.86l-52.44-1.16l-49.16,0.98l-2.33,4.16L281.05,178.2z"/>
	</g>
</g>
<g id="Land">
	<g>
		<polygon class="st_cut_11" points="242.27,169.78 233.45,179.37 233.4,179.42 211.85,179.42 211.81,179.42 203.28,169.78 
			216.09,170.51 216.09,173.31 229.46,173.31 229.46,170.51 		"/>
		<path d="M233.51,179.66H211.7l-9-10.16l13.63,0.78v2.78h12.88v-2.78l13.64-0.78L233.51,179.66z M211.92,179.17h21.37l8.39-9.11
			l-11.98,0.68v2.81h-13.86v-2.81l-11.99-0.69L211.92,179.17z"/>
	</g>
</g>
<g id="Asphalt">
</g>
<g id="Grass">
	<g>
		<path class="st_cut_12" d="M217.03,170.56l-13.41-0.86c0,0,0.01-0.07,0-0.17c-0.01,0.13,0.3-0.68,0.67-0.57
			c0.2-0.04,0.41,0.14,0.68,0.12c0.29-0.22,0.55,0.21,0.89,0.03c0.16,0.04,0.29,0.45,0.51,0.01c0.23-0.57,0.36,0.01,0.54,0.06
			c0.16,0.25,0.39-0.14,0.57,0.08c0.25-0.53,0.42-0.24,0.6,0.06c0.14,0.72,0.37,0.43,0.63-0.08c0.22-0.1,0.42,0.07,0.63,0.08
			c0.24-0.28,0.45-0.2,0.65,0.03c0.21,0.07,0.43,0.03,0.63,0.22c0.19,0.31,0.46-0.19,0.63,0.26c0.2,0.16,0.47-0.38,0.66-0.08
			c0.21,0.02,0.45-0.19,0.65-0.08c0.19,0.23,0.4,0.21,0.61,0.14c0.42-0.11,0.84-0.36,1.16,0.18c0.35,0.27,0.72,0.12,1.06,0.03
			c0.35-0.24,0.62,0.1,0.89,0.09c0.3-0.33,0.47,0.28,0.68,0.07c0.37,0.12,0.6-0.03,0.6,0.06
			C217.86,170.63,217.03,170.56,217.03,170.56z"/>
		<path d="M217.16,170.63c-0.06,0-0.11,0-0.14-0.01l-13.48-0.87l0.01-0.07c0,0,0.01-0.04,0-0.1l-0.01,0l0-0.06l0.05-0.48l0.05,0.34
			c0.13-0.21,0.36-0.55,0.64-0.48c0.11-0.02,0.21,0.02,0.32,0.05c0.11,0.03,0.21,0.07,0.33,0.06c0.17-0.11,0.33-0.05,0.47,0.01
			c0.14,0.06,0.27,0.11,0.41,0.03l0.02-0.01l0.02,0.01c0.06,0.02,0.12,0.07,0.17,0.12c0.05,0.05,0.1,0.1,0.14,0.09
			c0.02,0,0.07-0.03,0.14-0.17c0.07-0.18,0.15-0.27,0.23-0.28c0.1-0.01,0.18,0.08,0.25,0.18c0.04,0.06,0.09,0.12,0.13,0.13
			l0.02,0.01l0.01,0.02c0.05,0.08,0.1,0.07,0.21,0.03c0.08-0.03,0.19-0.07,0.29-0.01c0.09-0.17,0.18-0.25,0.28-0.26
			c0.17-0.01,0.28,0.19,0.39,0.38l0.01,0.02c0.07,0.35,0.14,0.38,0.16,0.38c0.04,0.01,0.14-0.05,0.36-0.48l0.01-0.02l0.02-0.01
			c0.16-0.07,0.31-0.02,0.44,0.02c0.07,0.02,0.13,0.04,0.19,0.05c0.11-0.12,0.22-0.18,0.34-0.17c0.12,0,0.25,0.07,0.37,0.22
			c0.06,0.02,0.12,0.03,0.19,0.04c0.15,0.02,0.31,0.05,0.45,0.19l0.01,0.01c0.06,0.1,0.12,0.09,0.22,0.06
			c0.12-0.03,0.29-0.08,0.4,0.19c0.05,0.03,0.12-0.01,0.22-0.08c0.09-0.06,0.19-0.13,0.29-0.11c0.05,0.01,0.09,0.04,0.13,0.09
			c0.07,0,0.14-0.03,0.22-0.05c0.13-0.04,0.28-0.09,0.42-0.02l0.02,0.01c0.16,0.19,0.33,0.19,0.55,0.12
			c0.07-0.02,0.13-0.04,0.19-0.06c0.35-0.11,0.74-0.23,1.04,0.25c0.28,0.21,0.58,0.13,0.87,0.05c0.04-0.01,0.07-0.02,0.11-0.03
			c0.24-0.16,0.46-0.07,0.64,0.01c0.09,0.04,0.17,0.07,0.25,0.08c0.19-0.19,0.36-0.06,0.47,0.03c0.11,0.09,0.15,0.11,0.2,0.06
			l0.03-0.03l0.04,0.01c0.2,0.06,0.36,0.04,0.46,0.03c0.07-0.01,0.12-0.01,0.15,0.02c0.01,0.01,0.02,0.02,0.02,0.04
			c0.07,0.1,0.09,0.18,0.05,0.25C217.6,170.61,217.33,170.63,217.16,170.63z M203.69,169.64l13.35,0.86c0.18,0.01,0.48,0,0.52-0.09
			c0.02-0.04-0.02-0.1-0.05-0.13l0,0c-0.01,0-0.03,0-0.05,0.01c-0.1,0.01-0.27,0.03-0.48-0.03c-0.12,0.1-0.24,0-0.33-0.07
			c-0.13-0.1-0.2-0.15-0.32-0.02l-0.02,0.02l-0.02,0c-0.11,0.01-0.22-0.04-0.33-0.08c-0.17-0.07-0.33-0.14-0.53-0.01l-0.02,0.01
			c-0.04,0.01-0.08,0.02-0.12,0.03c-0.31,0.08-0.66,0.18-0.99-0.08l-0.02-0.02c-0.25-0.42-0.55-0.32-0.9-0.21
			c-0.07,0.02-0.13,0.04-0.2,0.06c-0.21,0.06-0.45,0.1-0.67-0.15c-0.09-0.04-0.2-0.01-0.31,0.03c-0.1,0.03-0.2,0.07-0.3,0.06
			l-0.03,0l-0.02-0.03c-0.02-0.04-0.05-0.06-0.07-0.06c-0.05-0.01-0.13,0.04-0.2,0.09c-0.11,0.07-0.25,0.17-0.37,0.07l-0.01-0.01
			l-0.01-0.02c-0.07-0.19-0.15-0.17-0.26-0.14c-0.1,0.03-0.25,0.07-0.36-0.1c-0.12-0.11-0.25-0.13-0.38-0.15
			c-0.07-0.01-0.15-0.02-0.22-0.05l-0.02-0.01l-0.01-0.01c-0.11-0.12-0.2-0.18-0.29-0.19c0,0,0,0,0,0c-0.08,0-0.17,0.05-0.26,0.16
			l-0.02,0.02l-0.03,0c-0.09-0.01-0.17-0.03-0.25-0.06c-0.12-0.04-0.23-0.07-0.34-0.04c-0.2,0.4-0.34,0.55-0.48,0.53
			c-0.12-0.02-0.2-0.16-0.26-0.47c-0.08-0.14-0.18-0.31-0.27-0.31c0,0,0,0,0,0c-0.03,0-0.11,0.04-0.21,0.26l-0.04,0.09l-0.06-0.08
			c-0.05-0.06-0.1-0.05-0.21-0.01c-0.1,0.04-0.24,0.09-0.35-0.06c-0.07-0.03-0.12-0.1-0.17-0.17c-0.04-0.06-0.1-0.13-0.13-0.13
			c-0.01,0-0.06,0.02-0.14,0.21c-0.07,0.15-0.15,0.23-0.23,0.24c-0.1,0.02-0.18-0.06-0.24-0.13c-0.03-0.03-0.06-0.06-0.09-0.08
			c-0.18,0.08-0.34,0.02-0.49-0.04c-0.16-0.06-0.26-0.1-0.37-0.01l-0.01,0.01l-0.02,0c-0.15,0.01-0.28-0.03-0.4-0.07
			c-0.1-0.03-0.2-0.06-0.28-0.05l-0.02,0l-0.01,0c-0.22-0.07-0.45,0.31-0.54,0.47c-0.02,0.03-0.03,0.06-0.05,0.08
			C203.69,169.6,203.69,169.62,203.69,169.64z"/>
	</g>
	<g>
		<path class="st_cut_12" d="M228.39,170.56l13.41-0.86c0,0-0.01-0.07,0-0.17c0.01,0.13-0.3-0.68-0.67-0.57
			c-0.2-0.04-0.41,0.14-0.68,0.12c-0.29-0.22-0.55,0.21-0.89,0.03c-0.16,0.04-0.29,0.45-0.51,0.01c-0.23-0.57-0.36,0.01-0.54,0.06
			c-0.16,0.25-0.39-0.14-0.57,0.08c-0.25-0.53-0.42-0.24-0.6,0.06c-0.14,0.72-0.37,0.43-0.63-0.08c-0.22-0.1-0.42,0.07-0.63,0.08
			c-0.24-0.28-0.45-0.2-0.65,0.03c-0.21,0.07-0.43,0.03-0.63,0.22c-0.19,0.31-0.46-0.19-0.63,0.26c-0.2,0.16-0.47-0.38-0.66-0.08
			c-0.21,0.02-0.45-0.19-0.65-0.08c-0.19,0.23-0.4,0.21-0.61,0.14c-0.42-0.11-0.84-0.36-1.16,0.18c-0.35,0.27-0.72,0.12-1.06,0.03
			c-0.35-0.24-0.62,0.1-0.89,0.09c-0.3-0.33-0.47,0.28-0.68,0.07c-0.37,0.12-0.6-0.03-0.6,0.06
			C227.56,170.63,228.39,170.56,228.39,170.56z"/>
		<path d="M228.25,170.63c-0.17,0-0.43-0.02-0.5-0.16c-0.04-0.07-0.02-0.16,0.05-0.25c0-0.02,0.02-0.03,0.02-0.04
			c0.03-0.03,0.08-0.03,0.15-0.02c0.1,0.01,0.26,0.03,0.46-0.03l0.04-0.01l0.03,0.03c0.05,0.05,0.08,0.04,0.2-0.06
			c0.11-0.09,0.28-0.22,0.47-0.03c0.08,0,0.16-0.04,0.25-0.08c0.17-0.07,0.39-0.17,0.64-0.01c0.04,0.01,0.07,0.02,0.11,0.03
			c0.29,0.08,0.59,0.16,0.87-0.05c0.3-0.49,0.69-0.36,1.04-0.25c0.06,0.02,0.13,0.04,0.19,0.06c0.22,0.07,0.39,0.07,0.55-0.12
			l0.02-0.01c0.14-0.08,0.29-0.03,0.42,0.02c0.08,0.03,0.15,0.05,0.22,0.05c0.04-0.05,0.08-0.08,0.13-0.09
			c0.1-0.02,0.2,0.04,0.29,0.11c0.1,0.07,0.17,0.11,0.22,0.08c0.11-0.27,0.29-0.23,0.4-0.19c0.11,0.03,0.17,0.04,0.22-0.06
			l0.01-0.01c0.15-0.14,0.3-0.16,0.45-0.19c0.07-0.01,0.13-0.02,0.19-0.04c0.13-0.14,0.25-0.21,0.37-0.22c0,0,0,0,0,0
			c0.11,0,0.22,0.06,0.33,0.17c0.06-0.01,0.12-0.03,0.19-0.05c0.13-0.04,0.28-0.09,0.44-0.02l0.02,0.01l0.01,0.02
			c0.22,0.43,0.32,0.49,0.36,0.48c0.02,0,0.09-0.03,0.16-0.38l0.01-0.02c0.11-0.19,0.22-0.38,0.38-0.38c0,0,0.01,0,0.01,0
			c0.1,0,0.19,0.09,0.28,0.26c0.1-0.06,0.2-0.02,0.29,0.01c0.12,0.05,0.16,0.05,0.21-0.03l0.01-0.02l0.02-0.01
			c0.04-0.01,0.09-0.08,0.13-0.13c0.07-0.09,0.14-0.19,0.25-0.18c0.09,0.01,0.16,0.1,0.24,0.29c0.07,0.13,0.11,0.16,0.14,0.16
			c0.04,0.01,0.09-0.05,0.14-0.09c0.05-0.05,0.1-0.1,0.17-0.12l0.02-0.01l0.02,0.01c0.15,0.07,0.27,0.02,0.41-0.03
			c0.14-0.06,0.31-0.12,0.47-0.01c0.12,0.01,0.23-0.03,0.33-0.06c0.11-0.04,0.22-0.07,0.32-0.05c0.28-0.07,0.51,0.27,0.64,0.48
			l0.05-0.33l0.05,0.53l-0.01,0.01c-0.01,0.06,0,0.11,0,0.11l0.01,0.07l-0.07,0l-13.41,0.86
			C228.37,170.63,228.32,170.63,228.25,170.63z M227.91,170.28L227.91,170.28c-0.03,0.04-0.07,0.1-0.05,0.14
			c0.05,0.09,0.34,0.1,0.52,0.09l0,0l13.35-0.86c0-0.02,0-0.05,0-0.08c-0.01-0.02-0.03-0.04-0.05-0.07
			c-0.1-0.16-0.32-0.54-0.54-0.47l-0.01,0l-0.02,0c-0.08-0.02-0.17,0.01-0.28,0.05c-0.12,0.04-0.25,0.08-0.4,0.07l-0.02,0
			l-0.01-0.01c-0.11-0.08-0.21-0.05-0.37,0.01c-0.14,0.06-0.3,0.12-0.49,0.04c-0.03,0.02-0.06,0.05-0.09,0.08
			c-0.07,0.07-0.14,0.14-0.24,0.13c-0.08-0.01-0.16-0.09-0.23-0.23c-0.08-0.2-0.13-0.21-0.14-0.21c-0.03,0-0.09,0.07-0.13,0.13
			c-0.05,0.07-0.11,0.14-0.17,0.17c-0.11,0.15-0.25,0.09-0.35,0.06c-0.11-0.04-0.16-0.05-0.21,0.01l-0.06,0.07l-0.04-0.09
			c-0.1-0.22-0.18-0.26-0.21-0.26c0,0,0,0,0,0c-0.09,0-0.19,0.18-0.27,0.31c-0.06,0.31-0.14,0.46-0.26,0.47
			c-0.14,0.02-0.27-0.13-0.48-0.53c-0.11-0.04-0.22,0-0.34,0.04c-0.08,0.03-0.16,0.05-0.25,0.06l-0.03,0l-0.02-0.02
			c-0.09-0.1-0.18-0.16-0.26-0.16c0,0,0,0,0,0c-0.09,0-0.19,0.06-0.29,0.19l-0.01,0.01l-0.02,0.01c-0.08,0.03-0.15,0.04-0.22,0.05
			c-0.14,0.02-0.26,0.04-0.38,0.15c-0.11,0.17-0.25,0.13-0.36,0.1c-0.12-0.03-0.19-0.05-0.26,0.14l-0.01,0.02l-0.01,0.01
			c-0.13,0.1-0.26,0-0.37-0.07c-0.07-0.05-0.15-0.1-0.2-0.09c-0.02,0.01-0.05,0.03-0.07,0.06l-0.02,0.03l-0.03,0
			c-0.11,0.01-0.2-0.02-0.3-0.06c-0.11-0.04-0.22-0.07-0.31-0.03c-0.21,0.25-0.46,0.22-0.67,0.15c-0.06-0.02-0.13-0.04-0.19-0.06
			c-0.35-0.11-0.65-0.21-0.9,0.21l-0.02,0.02c-0.33,0.25-0.68,0.16-0.99,0.08c-0.04-0.01-0.08-0.02-0.12-0.03l-0.02-0.01
			c-0.19-0.13-0.36-0.06-0.53,0.01c-0.11,0.05-0.21,0.09-0.33,0.08l-0.02,0l-0.02-0.02c-0.12-0.13-0.19-0.08-0.32,0.02
			c-0.09,0.07-0.21,0.17-0.33,0.07c-0.21,0.06-0.38,0.04-0.48,0.03C227.94,170.28,227.92,170.28,227.91,170.28z"/>
	</g>
</g>
<g id="Medidas">
	<g>
		<text transform="matrix(1 0 0 1 483.0084 138.6516)" class="st_cut_13 st_cut_14 st_cut_15">{{ currentCalc.values_params.earthwork.cut_height }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 432.8511 104.7336)" class="st_cut_13 st_cut_14 st_cut_15">{{ currentCalc.values_params.earthwork.cut_enbankment_width }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 63.2833 170.7355)" class="st_cut_13 st_cut_14 st_cut_15">{{ Math.round(
            (1 / currentCalc.values_params.earthwork.cut_factor) * 10
        ) / 10 }}</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 29.105 149.0695)" class="st_cut_13 st_cut_14 st_cut_15">1.5</text>
	</g>
	<rect x="421.83" y="66.54" width="45.27" height="0.43"/>
	<polygon points="466.25,67.48 466.97,66.76 466.25,66.03 466.86,66.03 467.58,66.76 466.86,67.48 	"/>
	<path class="st_cut_16" d="M56.77,153.67v-26.51l23.14,26.4L56.77,153.67z"/>
	<rect x="467.35" y="85.09" class="st_cut_13" width="0.43" height="6.18"/>
	<rect x="421.14" y="85.09" class="st_cut_13" width="0.43" height="6.18"/>
	<rect x="473.42" y="181.28" class="st_cut_13" width="6.18" height="0.43"/>
	<rect x="473.42" y="77.98" class="st_cut_13" width="6.18" height="0.43"/>
	<text transform="matrix(1 0 0 1 433.1978 62.0915)" class="st_cut_14 st_cut_15">{{currentCalc.values_params.earthwork.cut_enbankment_slope}}%</text>
	<g>
		<g>
			<line class="st_cut_17" x1="423.19" y1="87.71" x2="466.05" y2="87.71"/>
			<g>
				<polygon class="st_cut_13" points="424.19,88.56 423.34,87.71 424.19,86.86 423.47,86.86 422.62,87.71 423.47,88.56 				"/>
			</g>
			<g>
				<polygon class="st_cut_13" points="465.05,88.56 465.9,87.71 465.05,86.86 465.77,86.86 466.62,87.71 465.77,88.56 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_cut_17" x1="477.12" y1="179.64" x2="477.12" y2="79.78"/>
			<g>
				<polygon class="st_cut_13" points="477.97,178.64 477.12,179.49 476.27,178.64 476.27,179.36 477.12,180.21 477.97,179.36 				"/>
			</g>
			<g>
				<polygon class="st_cut_13" points="477.97,80.78 477.12,79.93 476.27,80.78 476.27,80.06 477.12,79.21 477.97,80.06 				"/>
			</g>
		</g>
	</g>
</g>
<g id="Asphalt_x5F_and_components">
	<g>
		<polygon class="st_cut_18" points="118.94,169.37 111.23,175.43 108.13,171.58 108.86,171.01 111.39,174.14 118.37,168.65 		"/>
	</g>
	<g>
		<polygon class="st_cut_18" points="327.95,169.87 334.73,175.92 337.84,172.07 337.11,171.5 334.57,174.63 328.52,169.14 		"/>
	</g>
	<g>
		<polygon points="205.78,168.76 164.81,167.78 118.35,168.9 118.35,170.12 164.81,169 205.78,169.98 		"/>
	</g>
	<g>
		<polygon points="328.12,168.9 281.4,167.78 240.44,168.76 240.44,169.98 281.4,169 328.12,170.12 		"/>
	</g>
</g>
<g id="Lines">
</g>
<g id="Metal_x5F_guard" class="st_cut_3">
	<g class="st_cut_4">
		<g>
			<g>
				<path class="st_cut_7" d="M222.36,165.94h-3.28c-0.17,0-0.31-0.14-0.31-0.31v0c0-0.17,0.14-0.31,0.31-0.31h3.28
					c0.17,0,0.31,0.14,0.31,0.31v0C222.67,165.8,222.53,165.94,222.36,165.94z"/>
				<path d="M222.36,166.01h-3.28c-0.21,0-0.38-0.17-0.38-0.38c0-0.21,0.17-0.38,0.38-0.38h3.28c0.21,0,0.38,0.17,0.38,0.38
					C222.74,165.84,222.57,166.01,222.36,166.01z M219.08,165.4c-0.13,0-0.23,0.1-0.23,0.23c0,0.13,0.1,0.23,0.23,0.23h3.28
					c0.13,0,0.23-0.1,0.23-0.23c0-0.13-0.1-0.23-0.23-0.23H219.08z"/>
			</g>
			<g>
				<polygon class="st_cut_7" points="219.49,165.63 218.05,163.99 219.49,162.14 220.92,162.14 219.49,163.99 220.92,165.63 
					219.49,167.68 220.92,169.32 219.49,169.32 218.02,167.68 				"/>
				<path d="M221.09,169.4h-1.64l-1.53-1.71l1.47-2.05l-1.44-1.64l1.5-1.93h1.63l-1.49,1.92l1.43,1.64l-1.44,2.05L221.09,169.4z
				M219.52,169.24h1.23l-1.36-1.56l1.44-2.05l-1.44-1.64l1.38-1.77h-1.24l-1.37,1.77l1.43,1.64l-1.47,2.05L219.52,169.24z"/>
			</g>
			<g>
				<rect x="221.03" y="162.04" class="st_cut_19" width="1.03" height="8.82"/>
				<path d="M222.13,170.94h-1.18v-8.98h1.18V170.94z M221.1,170.78h0.87v-8.67h-0.87V170.78z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st_cut_7" d="M224.13,165.94h3.28c0.17,0,0.31-0.14,0.31-0.31v0c0-0.17-0.14-0.31-0.31-0.31h-3.28
					c-0.17,0-0.31,0.14-0.31,0.31v0C223.82,165.8,223.96,165.94,224.13,165.94z"/>
				<path d="M223.74,165.63c0-0.21,0.17-0.38,0.38-0.38h3.28c0.21,0,0.38,0.17,0.38,0.38c0,0.21-0.17,0.38-0.38,0.38h-3.28
					C223.92,166.01,223.74,165.84,223.74,165.63z M224.13,165.4c-0.13,0-0.23,0.1-0.23,0.23c0,0.13,0.1,0.23,0.23,0.23h3.28
					c0.13,0,0.23-0.1,0.23-0.23c0-0.13-0.1-0.23-0.23-0.23H224.13z"/>
			</g>
			<g>
				<polygon class="st_cut_7" points="227,165.63 228.44,163.99 227,162.14 225.57,162.14 227,163.99 225.57,165.63 227,167.68 
					225.57,169.32 227,169.32 228.47,167.68 				"/>
				<path d="M226.9,167.68l-1.44-2.05l1.43-1.64l-1.49-1.92h1.63l1.5,1.93l-1.44,1.64l1.47,2.05l-1.53,1.71h-1.64L226.9,167.68z
				M228.37,167.68l-1.47-2.05l1.43-1.64l-1.37-1.77h-1.24l1.38,1.77l-1.44,1.64l1.44,2.05l-1.36,1.56h1.23L228.37,167.68z"/>
			</g>
			<g>
				
					<rect x="224.44" y="162.04" transform="matrix(-1 -4.255941e-11 4.255941e-11 -1 449.9005 332.8994)" class="st_cut_19" width="1.03" height="8.82"/>
				<path d="M224.36,161.96h1.18v8.98h-1.18V161.96z M225.39,162.12h-0.87v8.67h0.87V162.12z"/>
			</g>
		</g>
	</g>
</g>
<g id="Jersey">
	<g>
		<g class="st_cut_3">
			<polygon class="st_cut_8" points="118.96,168.42 124.25,168.42 124.25,166.36 122.44,164.67 121.71,163.46 121.23,159.82 
				118.92,159.82 			"/>
			<path class="st_cut_4" d="M124.49,168.67h-5.77l-0.04-9.09h2.76l0.51,3.8l0.68,1.14l1.86,1.74V168.67z M119.2,168.18h4.8v-1.71
				l-1.78-1.68l-0.75-1.25l-0.46-3.47h-1.85L119.2,168.18z"/>
		</g>
		<g class="st_cut_3">
			<polygon class="st_cut_8" points="326.53,168.42 321.25,168.42 321.25,166.36 323.06,164.67 323.78,163.46 324.27,159.82 
				326.57,159.82 			"/>
			<path class="st_cut_4" d="M326.77,168.67H321v-2.41l1.86-1.74l0.68-1.14l0.51-3.8h2.76L326.77,168.67z M321.49,168.18h4.8l0.04-8.12
				h-1.85l-0.46,3.47l-0.79,1.31l-1.73,1.62V168.18z"/>
		</g>
	</g>
	<g>
		<g class="st_cut_3">
			<polygon class="st_cut_8" points="118.96,170.42 124.25,170.42 124.25,168.36 122.44,166.67 121.71,165.46 121.23,161.82 
				118.92,161.82 			"/>
			<path class="st_cut_4" d="M124.49,170.67h-5.77l-0.04-9.09h2.76l0.51,3.8l0.68,1.14l1.86,1.74V170.67z M119.2,170.18h4.8v-1.71
				l-1.78-1.68l-0.75-1.25l-0.46-3.47h-1.85L119.2,170.18z"/>
		</g>
		<g class="st_cut_3">
			<polygon class="st_cut_8" points="326.53,170.42 321.25,170.42 321.25,168.36 323.06,166.67 323.78,165.46 324.27,161.82 
				326.57,161.82 			"/>
			<path class="st_cut_4" d="M326.77,170.67H321v-2.41l1.86-1.74l0.68-1.14l0.51-3.8h2.76L326.77,170.67z M321.49,170.18h4.8l0.04-8.12
				h-1.85l-0.46,3.47l-0.79,1.31l-1.73,1.62V170.18z"/>
		</g>
		<g>
			<polygon class="st_cut_7" points="227.95,168.85 227.95,170.91 217.78,170.91 217.78,168.85 219.59,167.15 220.32,165.94 
				220.8,162.31 224.93,162.31 225.42,165.94 226.14,167.15 			"/>
			<path d="M228.19,171.15h-10.65v-2.41l1.86-1.74l0.68-1.14l0.51-3.8h4.55l0.51,3.8l0.68,1.14l1.86,1.74V171.15z M218.03,170.67
				h9.68v-1.71l-1.78-1.68l-0.75-1.25l-0.46-3.47h-3.7l-0.46,3.47l-0.79,1.31l-1.73,1.62V170.67z"/>
		</g>
	</g>
</g>
</svg>


</template>
<script>
export default {
  name: "SvgEarthworkCut2LaneComponent",
  props: {
    currentCalc: undefined,
  },
};
</script>
<style scoped>
	.st_cut_0{fill:#8FCE57;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_cut_1{fill:#3E2D00;stroke:#000000;stroke-miterlimit:10;}
	.st_cut_2{opacity:0.29;fill:#FF9100;}
	.st_cut_3{display:none;}
	.st_cut_4{display:inline;}
	.st_cut_5{fill:#A07220;}
	.st_cut_6{fill:none;}
	.st_cut_7{fill:#686868;}
	.st_cut_8{display:inline;fill:#686868;}
	.st_cut_9{fill:#2D190F;}
	.st_cut_10{fill:#DD8F64;}
	.st_cut_11{fill:#7C503A;}
	.st_cut_12{fill:#8FCE57;}
	.st_cut_13{fill:#FFFFFF;}
	.st_cut_14{font-family:'Roboto-Bold';}
	.st_cut_15{font-size:18px;}
	.st_cut_16{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st_cut_17{fill:none;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
	.st_cut_18{fill:#7C7C7C;}
	.st_cut_19{fill:#72502F;}
</style>
