<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 549 274" style="enable-background:new 0 0 549 274;" xml:space="preserve">
	<g id="Layer_1">
	</g>
	<g id="Sub_x5F_base">
		<polygon class="st0" points="468.63,148.9 266.19,144.9 65.15,148.9 76.22,129.9 266.19,125.9 457.56,129.9 	"/>
		<polygon class="st1" points="479.57,167.9 266.19,163.9 54.21,167.9 64.87,148.9 266.19,144.9 468.9,148.9 	"/>
	</g>
	<g id="Asphalt_x5F_and_components">
		<polygon points="458,125.06 266.19,120.06 76,125.06 76,130.06 266.19,125.06 458,130.06 	"/>
		<text transform="matrix(1 0 0 1 92.3648 197.8511)" class="st2 st3">Acostamento</text>
		<text transform="matrix(1 0 0 1 362.7876 197.8512)" class="st2 st3">Acostamento</text>
	</g>
	<g id="Arrows">
		<g>
			<g>
				<g>
					<line class="st4" x1="250.44" y1="110.9" x2="201.41" y2="111.88"/>
					<g>
						<polygon points="203.37,110.15 201.71,111.87 203.44,113.54 202,113.56 200.27,111.9 201.93,110.17 					"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<line class="st4" x1="279.71" y1="110.9" x2="328.74" y2="111.88"/>
					<g>
						<polygon points="326.72,113.54 328.45,111.87 326.78,110.15 328.22,110.17 329.88,111.9 328.16,113.56 					"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g id="Text">
		<g>
			<g>
				<text transform="matrix(1 0 0 1 129.2226 60.6374)" class="st2 st3">{{ currentCalc.values_params.platform.sholder }}m</text>
				<text transform="matrix(1 0 0 1 378.2226 60.6374)" class="st2 st3">{{ currentCalc.values_params.platform.sholder }}m</text>
			</g>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 218.359 60.6374)" class="st2 st3">{{ (currentCalc.values_params.platform.lanes*(currentCalc.values_params.platform.lane)) }}m</text>
			<text transform="matrix(1 0 0 1 275.359 60.6374)" class="st2 st3">{{ (currentCalc.values_params.platform.lanes*(currentCalc.values_params.platform.lane)) }}m</text>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 225.2465 104.6263)" class="st2 st3">3 %</text>
			<text transform="matrix(1 0 0 1 289.2465 104.6263)" class="st2 st3">3 %</text>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 502.2892 130.4349)" class="st2 st3">{{ (Number(this.currentCalc.values_params.superstructure.first_asphalt_layer_depth)+Number(this.currentCalc.values_params.superstructure.second_asphalt_layer_depth)) }}m</text>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 502.2892 145.4349)" class="st2 st3">{{ currentCalc.values_params.superstructure.base_height }}m</text>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 502.2892 165.4349)" class="st2 st3">{{ currentCalc.values_params.superstructure.subbase_height }}m</text>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 253.763 32.8012)" class="st2 st3">{{ ((2*currentCalc.values_params.platform.sholder)+(2*(currentCalc.values_params.platform.lanes*(currentCalc.values_params.platform.lane)))) }}m</text>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 53.2705 123.4025)" class="st2 st3">1</text>
		</g>
		<g>
			<g>
				<polyline class="st5" points="206.19,124.82 206.19,211.13 215.19,211.13 			"/>
				<g>
					<polygon points="214.19,211.97 215.04,211.13 214.19,210.28 214.91,210.28 215.76,211.13 214.91,211.97 				"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<polyline class="st5" points="190.37,136.87 190.37,232.11 207.84,232.11 			"/>
				<g>
					<polygon points="206.84,232.96 207.69,232.11 206.84,231.26 207.56,231.26 208.41,232.11 207.56,232.96 				"/>
				</g>
			</g>
		</g>
		<text transform="matrix(1 0 0 1 222.1429 214.8508)" class="st2 st3">{{currentCalc.values_params.superstructure.revestment_density}} t/m³</text>
		<text transform="matrix(1 0 0 1 214.7925 235.8356)" class="st2 st3">2.4 t/m³</text>
		<g>
			<text transform="matrix(1 0 0 1 26.4822 143.4347)" class="st2 st3">1.5</text>
		</g>
		<g>
			<g>
				<line class="st5" x1="125.79" y1="125.4" x2="125.79" y2="184.46"/>
				<g>
					<polygon points="124.95,183.46 125.79,184.31 126.64,183.46 126.64,184.18 125.79,185.03 124.95,184.18 				"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st5" x1="396.22" y1="124.4" x2="396.22" y2="184.46"/>
				<g>
					<polygon points="395.37,183.46 396.22,184.31 397.06,183.46 397.06,184.18 396.22,185.03 395.37,184.18 				"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<polyline class="st5" points="174.15,157.9 174.15,253.19 191.62,253.19 			"/>
				<g>
					<polygon points="190.63,254.03 191.48,253.19 190.63,252.34 191.35,252.34 192.2,253.19 191.35,254.03 				"/>
				</g>
			</g>
		</g>
		<text transform="matrix(1 0 0 1 198.5795 256.9093)" class="st2 st3">2.4 t/m³</text>
	</g>
	<g id="Lines">
		<g>
			<g>
				<line class="st6" x1="54.47" y1="40.42" x2="478.83" y2="40.42"/>
				<g>
					<rect x="53.97" y="37.17" width="1" height="6.5"/>
				</g>
				<g>
					<rect x="478.33" y="37.17" width="1" height="6.5"/>
				</g>
			</g>
		</g>
		<polygon class="st6" points="49.71,128.9 65.28,128.9 48.94,156.93 	"/>
		<g>
			<g>
				<line class="st6" x1="75.94" y1="70.03" x2="169.5" y2="70.03"/>
				<g>
					<rect x="75.44" y="66.78" width="1" height="6.5"/>
				</g>
				<g>
					<rect x="169" y="66.78" width="1" height="6.5"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st6" x1="363.5" y1="70.03" x2="456.73" y2="70.03"/>
				<g>
					<rect x="363" y="66.78" width="1" height="6.5"/>
				</g>
				<g>
					<rect x="456.23" y="66.78" width="1" height="6.5"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st6" x1="169.63" y1="70.03" x2="363.19" y2="70.03"/>
				<g>
					<rect x="169.13" y="66.78" width="1" height="6.5"/>
				</g>
				<g>
					<rect x="362.69" y="66.78" width="1" height="6.5"/>
				</g>
			</g>
		</g>
		<line class="st6" x1="260.53" y1="65.52" x2="260.53" y2="74.54"/>
		<g>
			<line class="st7" x1="463.46" y1="124.9" x2="493.46" y2="124.9"/>
			<line class="st7" x1="463.46" y1="128.9" x2="493.46" y2="128.9"/>
			<line class="st7" x1="473.46" y1="147.9" x2="493.46" y2="147.9"/>
			<line class="st7" x1="485.46" y1="167.9" x2="493.46" y2="167.9"/>
		</g>
	</g>
	</svg>
</template>
<script>
export default {
	name: "SvgPlatormStructure1Lane",
	props: {
    currentCalc: undefined,
	},
};
</script>
<style scoped>
	.st0{fill:#DD8F64;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st1{fill:#7C503A;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st2{font-family:'MyriadPro-Regular';}
	.st3{font-size:12px;}
	.st4{fill:none;stroke:#000000;stroke-linecap:round;stroke-miterlimit:10;}
	.st5{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st6{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st7{fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
</style>