import { render, staticRenderFns } from "./PlatformTunnel.vue?vue&type=template&id=3128d030&scoped=true&"
import script from "./PlatformTunnel.vue?vue&type=script&lang=js&"
export * from "./PlatformTunnel.vue?vue&type=script&lang=js&"
import style0 from "./PlatformTunnel.vue?vue&type=style&index=0&id=3128d030&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3128d030",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VRow,VTextField})
