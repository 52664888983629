<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <SvgPlatformBridge1LaneComponent v-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text1']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge1LaneJersey v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text2']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge1LaneMetalGuard v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text3']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsStructureBridgeWidth">
          <v-col style="align-self: center;"> {{ selectedLanguage['platformBridge1Lane_component_text4'] }} </v-col>
          <v-col style="align-self: center;">
            <v-text-field dense hide-details class="mb-2" clearable outlined v-model.number="currentCalc.values_params.structure.bridge_width" style="border-radius: 10px !important;"/>
          </v-col>
          <v-col style="align-self: center;"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SvgPlatformBridge1LaneComponent from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge1LaneComponent.vue";
import SvgPlatformBridge1LaneJersey from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge1LaneJersey.vue";
import SvgPlatformBridge1LaneMetalGuard from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge1LaneMetalGuard.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgPlatformBridge1LaneComponent,
    SvgPlatformBridge1LaneJersey,
    SvgPlatformBridge1LaneMetalGuard,
  },
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2 {
  fill: #666;
}
.cls-3,
.cls-5 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 14px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>