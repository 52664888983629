<template>
  <div style="overflow-x: hidden; overflow-y: hidden;">

    <SvgPlatformBridge2Lane v-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text1']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge2LaneJersey2 v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text2']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge2LaneMetalGuardComponent  v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text3']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge2LaneJerseyComponent v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text2']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge2LaneMetalGuardComponent  v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text3']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>

    <SvgPlatformBridge_2_lanes_N_M  v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text1']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge_2_lanes_N_J  v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text1']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>

    <SvgPlatformBridge_2_lanes_J_M  v-else-if="params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text3']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge_2_lanes_M_J  v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text2']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>
    <SvgPlatformBridge_2_lanes_M_J_2  v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['platformBridge1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platformBridge1Lane_component_text2']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG5"/>

    <v-row>
      <v-col>
        <v-row id="ValuesParamsStructureBridgeWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platformBridge1Lane_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.structure.bridge_2lanes_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SvgPlatformBridge2Lane from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge2Lane.vue";
import SvgPlatformBridge2LaneMetalGuardComponent from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge2LaneMetalGuardComponent.vue";
import SvgPlatformBridge2LaneJersey2 from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge2LaneJersey2.vue";
import SvgPlatformBridge2LaneJerseyComponent from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge2LaneJerseyComponent.vue";
import SvgPlatformBridge_2_lanes_N_J from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge_2_lanes_N_J.vue";
import SvgPlatformBridge_2_lanes_N_M from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge_2_lanes_N_M.vue";
import SvgPlatformBridge_2_lanes_M_J from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge_2_lanes_M_J.vue";
import SvgPlatformBridge_2_lanes_J_M from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge_2_lanes_J_M.vue";
import SvgPlatformBridge_2_lanes_M_J_2 from "@/components/SvgComponents/roadway/bridge/SvgPlatformBridge_2_lanes_M_J_2.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgPlatformBridge2LaneMetalGuardComponent,
    SvgPlatformBridge2Lane,
    SvgPlatformBridge2LaneJersey2,
    SvgPlatformBridge2LaneJerseyComponent,
    SvgPlatformBridge_2_lanes_N_J,
    SvgPlatformBridge_2_lanes_N_M,
    SvgPlatformBridge_2_lanes_M_J,
    SvgPlatformBridge_2_lanes_J_M,
    SvgPlatformBridge_2_lanes_M_J_2,
  },
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2 {
  fill: #666;
}
.cls-3,
.cls-5 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 14px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>