<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 299.81 161.72"
    style="enable-background: new 0 0 299.81 161.72"
    xml:space="preserve"
  >
    <g id="Seção_2_Pistas">
      <g id="Estrutura_1_">
        <polyline
          class="st_tunnel_0"
          points="42,72.51 42,138.6 293.98,138.6 293.98,70.45 		"
        />
        <path
          class="st_tunnel_0"
          d="M42,72.95c0-34.63,56.41-62.7,125.99-62.7s125.99,28.07,125.99,62.7"
        />
      </g>
      <g id="Medidas_1_">
        <path class="st_tunnel_1" d="M295.65,153.21" />
        <path class="st_tunnel_1" d="M39.67,153.21" />
        <line class="st_tunnel_1" x1="40.07" y1="148.62" x2="40.07" y2="157.84" />
        <line class="st_tunnel_1" x1="295.24" y1="148.62" x2="295.24" y2="157.38" />
        <text transform="matrix(0.9 0 0 1 156.9854 151.0867)" class="st_tunnel_2 st_tunnel_3">
			{{currentCalc.values_params.structure.tunnel_2lanes_width}}m
        </text>
        <text transform="matrix(0.9 0 0 1 3.5347 41.2322)" class="st_tunnel_2 st_tunnel_3">
          3.25m
        </text>
        <text transform="matrix(0.9 0 0 1 9.1118 104.2132)" class="st_tunnel_2 st_tunnel_3">
          5.1m
        </text>
        <path class="st_tunnel_1" d="M31.85,141.68" />
        <path class="st_tunnel_1" d="M31.85,69.23" />
        <path class="st_tunnel_1" d="M31.85,11.24" />
        <line class="st_tunnel_1" x1="28.82" y1="68.58" x2="35.16" y2="68.58" />
        <line class="st_tunnel_1" x1="28.61" y1="10.24" x2="34.94" y2="10.24" />
        <line class="st_tunnel_1" x1="28.96" y1="141.68" x2="35.29" y2="141.68" />
        <g>
          <g>
            <line class="st_tunnel_1" x1="31.85" y1="67.6" x2="31.85" y2="10.99" />
            <g>
              <polygon
                points="32.7,66.61 31.85,67.46 31,66.61 31,67.33 31.85,68.17 32.7,67.33 					"
              />
            </g>
            <g>
              <polygon
                points="32.7,11.98 31.85,11.14 31,11.98 31,11.26 31.85,10.42 32.7,11.26 					"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <line class="st_tunnel_1" x1="32.18" y1="141.04" x2="31.91" y2="69.44" />
            <g>
              <polygon
                points="33.02,140.04 32.18,140.89 31.33,140.04 31.33,140.76 32.18,141.61 33.03,140.76 					"
              />
            </g>
            <g>
              <polygon
                points="32.76,70.43 31.91,69.59 31.07,70.44 31.06,69.72 31.91,68.87 32.76,69.71 					"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <line class="st_tunnel_1" x1="40.65" y1="152.84" x2="294.67" y2="153.38" />
            <g>
              <polygon
                points="41.64,153.69 40.79,152.84 41.64,152 40.92,151.99 40.07,152.84 40.92,153.69 					"
              />
            </g>
            <g>
              <polygon
                points="293.67,154.23 294.52,153.38 293.68,152.53 294.4,152.53 295.24,153.38 294.39,154.23 					"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Jerseys"></g>
      <g id="Asphalt_x5F_and_x5F_components"></g>
    </g>
    <g id="Estrutura">
      <path class="st_tunnel_4" d="M40.98,84.78" />
    </g>
    <g id="Asphalt_x5F_and_components">
      <rect x="44.81" y="133.93" width="246.41" height="1.64" />
    </g>
    <g id="Canteiro" class="st_tunnel_5">
      <g class="st_tunnel_6">
        <polygon
          class="st_tunnel_7"
          points="51.64,135.67 44.81,135.67 44.81,132.2 46.95,132.2 46.95,133.93 51.64,133.93 		"
        />
      </g>
      <g class="st_tunnel_6">
        <polygon
          class="st_tunnel_7"
          points="291.22,135.57 284.38,135.57 284.38,133.93 289.07,133.93 289.07,132.2 291.22,132.2 		"
        />
      </g>
    </g>
    <g id="Metal_x5F_guard">
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  class="st_tunnel_8"
                  d="M166.42,129.64h-3.88c-0.2,0-0.36-0.16-0.36-0.36l0,0c0-0.2,0.16-0.36,0.36-0.36h3.88
							c0.2,0,0.36,0.16,0.36,0.36l0,0C166.79,129.48,166.62,129.64,166.42,129.64z"
                />
              </g>
              <g>
                <path
                  d="M166.42,129.73h-3.88c-0.25,0-0.45-0.2-0.45-0.45s0.2-0.45,0.45-0.45h3.88c0.25,0,0.45,0.2,0.45,0.45
							S166.67,129.73,166.42,129.73z M162.54,129c-0.15,0-0.27,0.12-0.27,0.27s0.12,0.27,0.27,0.27h3.88c0.15,0,0.27-0.12,0.27-0.27
							s-0.12-0.27-0.27-0.27H162.54z"
                />
              </g>
            </g>
            <g>
              <g>
                <polygon
                  class="st_tunnel_8"
                  points="163.02,129.27 161.33,127.33 163.02,125.15 164.72,125.15 163.02,127.33 164.72,129.27 
							163.02,131.7 164.72,133.64 163.02,133.64 161.29,131.7 						"
                />
              </g>
              <g>
                <path
                  d="M164.92,133.73h-1.94l-1.81-2.03l1.73-2.43l-1.7-1.94l1.77-2.28h1.93l-1.77,2.27l1.7,1.94l-1.7,2.43L164.92,133.73zM163.07,133.55h1.46l-1.61-1.84l1.7-2.43l-1.7-1.94l1.63-2.1h-1.47l-1.62,2.09l1.7,1.94l-1.73,2.43L163.07,133.55z"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="164.84"
                  y="125.03"
                  class="st_tunnel_9"
                  width="1.21"
                  height="10.43"
                />
              </g>
              <g>
                <path
                  d="M166.15,135.55h-1.39v-10.61h1.39V135.55z M164.94,135.37h1.03v-10.25h-1.03V135.37z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  class="st_tunnel_8"
                  d="M168.51,129.64h3.88c0.2,0,0.36-0.16,0.36-0.36l0,0c0-0.2-0.16-0.36-0.36-0.36h-3.88
							c-0.2,0-0.36,0.16-0.36,0.36l0,0C168.15,129.48,168.31,129.64,168.51,129.64z"
                />
              </g>
              <g>
                <path
                  d="M168.06,129.27c0-0.25,0.2-0.45,0.45-0.45h3.88c0.25,0,0.45,0.2,0.45,0.45s-0.2,0.45-0.45,0.45h-3.88
							C168.26,129.73,168.06,129.53,168.06,129.27z M168.51,129c-0.15,0-0.27,0.12-0.27,0.27s0.12,0.27,0.27,0.27h3.88
							c0.15,0,0.27-0.12,0.27-0.27s-0.12-0.27-0.27-0.27H168.51z"
                />
              </g>
            </g>
            <g>
              <g>
                <polygon
                  class="st_tunnel_8"
                  points="171.91,129.27 173.61,127.33 171.91,125.15 170.21,125.15 171.91,127.33 170.21,129.27 
							171.91,131.7 170.21,133.64 171.91,133.64 173.64,131.7 						"
                />
              </g>
              <g>
                <path
                  d="M171.8,131.69l-1.7-2.43l1.7-1.94l-1.77-2.27h1.93l1.77,2.28l-1.7,1.94l1.73,2.43l-1.81,2.03h-1.94L171.8,131.69zM173.53,131.69l-1.73-2.43l1.7-1.94l-1.62-2.09h-1.47l1.63,2.1l-1.7,1.94l1.7,2.43l-1.61,1.84h1.46L173.53,131.69z"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="168.88"
                  y="125.03"
                  transform="matrix(-1 -4.423807e-11 4.423807e-11 -1 338.9708 260.4908)"
                  class="st_tunnel_9"
                  width="1.21"
                  height="10.43"
                />
              </g>
              <g>
                <path
                  d="M168.79,124.94h1.39v10.61h-1.39V124.94z M170,125.12h-1.03v10.25H170V125.12z"
                />
              </g>
            </g>
          </g>
        </g>
        <g class="st_tunnel_5">
          <g class="st_tunnel_6">
            <g>
              <path
                class="st_tunnel_8"
                d="M44.86,129.07h3.88c0.2,0,0.36-0.16,0.36-0.36l0,0c0-0.2-0.16-0.36-0.36-0.36h-3.88
						c-0.2,0-0.36,0.16-0.36,0.36l0,0C44.49,128.9,44.66,129.07,44.86,129.07z"
              />
            </g>
            <g>
              <path
                d="M44.4,128.7c0-0.25,0.2-0.45,0.45-0.45h3.88c0.25,0,0.45,0.2,0.45,0.45c0,0.25-0.2,0.45-0.45,0.45h-3.88
						C44.61,129.16,44.4,128.95,44.4,128.7z M44.86,128.43c-0.15,0-0.27,0.12-0.27,0.27s0.12,0.27,0.27,0.27h3.88
						c0.15,0,0.27-0.12,0.27-0.27s-0.12-0.27-0.27-0.27H44.86z"
              />
            </g>
          </g>
          <g class="st_tunnel_6">
            <g>
              <polygon
                class="st_tunnel_8"
                points="48.25,128.7 49.95,126.76 48.25,124.58 46.56,124.58 48.25,126.76 46.56,128.7 48.25,131.13 
						46.56,133.07 48.25,133.07 49.99,131.13 					"
              />
            </g>
            <g>
              <path
                d="M48.14,131.12l-1.7-2.43l1.7-1.94l-1.77-2.27h1.93l1.77,2.28l-1.7,1.94l1.73,2.43l-1.81,2.03h-1.94L48.14,131.12zM49.87,131.12l-1.73-2.43l1.7-1.94l-1.62-2.09h-1.47l1.63,2.1l-1.7,1.94l1.7,2.43l-1.61,1.84h1.46L49.87,131.12z"
              />
            </g>
          </g>
          <g class="st_tunnel_6">
            <g>
              <rect
                x="45.22"
                y="124.46"
                transform="matrix(-1 -4.648738e-11 4.648738e-11 -1 91.6563 259.3433)"
                class="st_tunnel_9"
                width="1.21"
                height="10.43"
              />
            </g>
            <g>
              <path
                d="M45.13,124.36h1.39v10.61h-1.39V124.36z M46.34,124.55h-1.03v10.25h1.03V124.55z"
              />
            </g>
          </g>
        </g>
        <g class="st_tunnel_5">
          <g class="st_tunnel_6">
            <g>
              <path
                class="st_tunnel_8"
                d="M291.1,129.07h-3.88c-0.2,0-0.36-0.16-0.36-0.36l0,0c0-0.2,0.16-0.36,0.36-0.36h3.88
						c0.2,0,0.36,0.16,0.36,0.36l0,0C291.47,128.9,291.3,129.07,291.1,129.07z"
              />
            </g>
            <g>
              <path
                d="M291.1,129.16h-3.88c-0.25,0-0.45-0.2-0.45-0.45c0-0.25,0.2-0.45,0.45-0.45h3.88c0.25,0,0.45,0.2,0.45,0.45
						C291.56,128.95,291.35,129.16,291.1,129.16z M287.22,128.43c-0.15,0-0.27,0.12-0.27,0.27s0.12,0.27,0.27,0.27h3.88
						c0.15,0,0.27-0.12,0.27-0.27s-0.12-0.27-0.27-0.27H287.22z"
              />
            </g>
          </g>
          <g class="st_tunnel_6">
            <g>
              <polygon
                class="st_tunnel_8"
                points="287.71,128.7 286.01,126.76 287.71,124.58 289.4,124.58 287.71,126.76 289.4,128.7 287.71,131.13 
						289.4,133.07 287.71,133.07 285.97,131.13 					"
              />
            </g>
            <g>
              <path
                d="M289.6,133.16h-1.94l-1.81-2.03l1.73-2.43l-1.7-1.94l1.77-2.28h1.93l-1.77,2.27l1.7,1.94l-1.7,2.43L289.6,133.16zM287.75,132.98h1.46l-1.61-1.84l1.7-2.43l-1.7-1.94l1.63-2.1h-1.47l-1.62,2.09l1.7,1.94l-1.73,2.43L287.75,132.98z"
              />
            </g>
          </g>
          <g class="st_tunnel_6">
            <g>
              <rect
                x="289.53"
                y="124.46"
                class="st_tunnel_9"
                width="1.21"
                height="10.43"
              />
            </g>
            <g>
              <path
                d="M290.83,134.98h-1.39v-10.61h1.39V134.98z M289.62,134.8h1.03v-10.25h-1.03V134.8z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Jerseys_1_" class="st_tunnel_5">
      <g class="st_tunnel_6">
        <g class="st_tunnel_5">
          <polygon
            class="st_tunnel_10"
            points="45.14,134.82 51.4,134.82 51.4,132.38 49.26,130.37 48.4,128.94 47.82,124.63 45.1,124.63 			"
          />
          <path
            class="st_tunnel_6"
            d="M51.69,135.1h-6.83l-0.05-10.76h3.26l0.6,4.49l0.81,1.35l2.21,2.07V135.1z M45.43,134.53h5.68v-2.03
				l-2.1-1.98l-0.89-1.49l-0.55-4.11h-2.19L45.43,134.53z"
          />
        </g>
        <g class="st_tunnel_5">
          <polygon
            class="st_tunnel_10"
            points="290.88,134.82 284.62,134.82 284.62,132.38 286.77,130.37 287.63,128.94 288.2,124.63 
				290.92,124.63 			"
          />
          <path
            class="st_tunnel_6"
            d="M291.16,135.1h-6.83v-2.85l2.21-2.07l0.81-1.35l0.6-4.49h3.26L291.16,135.1z M284.91,134.53h5.68l0.04-9.61
				h-2.19l-0.55,4.11l-0.94,1.55l-2.05,1.92V134.53z"
          />
        </g>
        <g>
          <polygon
            class="st_tunnel_8"
            points="173.6,132.95 173.6,135.39 161.56,135.39 161.56,132.95 163.7,130.94 164.56,129.51 165.14,125.21 
				170.02,125.21 170.6,129.51 171.46,130.94 			"
          />
          <path
            d="M173.89,135.68h-12.61v-2.85l2.2-2.07l0.81-1.35l0.6-4.49h5.39l0.6,4.49l0.81,1.35l2.2,2.07V135.68z M161.85,135.1h11.47
				v-2.03l-2.1-1.98l-0.89-1.49l-0.55-4.11h-4.38l-0.55,4.11l-0.94,1.55l-2.05,1.92V135.1z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "SvgPlatformTunnel2LaneMetalGuardComponent",
  props: {
    currentCalc: undefined,
  },
};
</script>
<style scoped>
  .st_tunnel_0 {
    fill: none;
    stroke: #666666;
    stroke-width: 6;
    stroke-miterlimit: 10;
  }
  .st_tunnel_1 {
    fill: none;
    stroke: #000000;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
  }
  .st_tunnel_2 {
    font-family: "MyriadPro-Regular";
  }
  .st_tunnel_3 {
    font-size: 12px;
  }
  .st_tunnel_4 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
  }
  .st_tunnel_5 {
    display: none;
  }
  .st_tunnel_6 {
    display: inline;
  }
  .st_tunnel_7 {
    fill: #7c7c7c;
  }
  .st_tunnel_8 {
    fill: #686868;
  }
  .st_tunnel_9 {
    fill: #72502f;
  }
  .st_tunnel_10 {
    display: inline;
    fill: #686868;
  }
</style>
