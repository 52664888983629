<template>
  <div>
    <v-app-bar app clipped-left :color="color" dense dark style="overflow-x: auto;">
      <v-app-bar-nav-icon to="/"
        ><v-img :src="logoIconUrl" width="10px" alt="wwcalc"
      /></v-app-bar-nav-icon>
      <v-btn :loading="loading2" text @click="clickCancelExit()">
        <v-icon>mdi-chevron-left</v-icon>{{ selectedLanguage['adjustParameters_component_text1'] }}
      </v-btn>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="curves">{{ selectedLanguage['adjustParameters_component_text2'] }}</v-tab>
        <v-tab key="platform">{{ selectedLanguage['adjustParameters_component_text3'] }}</v-tab>
        <v-tab key="earthwork">{{ selectedLanguage['adjustParameters_component_text4'] }}</v-tab>
      </v-tabs>
      <v-btn :loading="loading1" text @click="clickSaveExit()">
        {{ selectedLanguage['adjustPrices_component_text2'] }}<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card v-if="currentCalc" class="elevation-0">
      <v-tabs-items v-model="tab">
        <v-tab-item key="curves">
          <v-card-text>
            <Curves :callWatcher="callWatcher(this.currentCalc.values_params.imgs.curves_table_selected)"/>
          </v-card-text>
        </v-tab-item>
        <v-tab-item key="platform">
          <v-card-text>
            <v-row>
              <v-col style="align-self: center">
                <v-select
                  outlined
                  v-model="centralBarrierSwitch"
                  :items="selectedLanguage['adjustParameters_component_text5']"
                  item-value="id"
                  :label="selectedLanguage['adjustParameters_component_text6']"
                  @input="onCentralBSelected"
                />
                <v-text-field
                  outlined
                  v-model="currentCalc.values_params.platform.lanes"
                  id="Lanes"
                  :label="selectedLanguage['adjustParameters_component_text7_2']"
                  @input="onLanesSet"
                />
                <v-select disabled
                  outlined
                  v-model="
                    currentCalc.values_params.superstructure.central_barrier
                  "
                  :items="selectedLanguage['adjustParameters_component_text7']"
                  item-text="name"
                  item-value="id"
                  :label="selectedLanguage['adjustParameters_component_text8']"
                  @input="onCentralBarrierSelected"
                  v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                />
                <v-select
                  outlined
                  v-model="
                    currentCalc.values_params.superstructure.central_barrier
                  "
                  :items="selectedLanguage['adjustParameters_component_text9']"
                  item-text="name"
                  item-value="id"
                  :label="selectedLanguage['adjustParameters_component_text10']"
                  @input="onCentralBarrierSelected"
                  v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15']"
                />
                <v-select
                  outlined
                  v-model="
                    currentCalc.values_params.superstructure.side_barrier
                  "
                  :items="selectedLanguage['adjustParameters_component_text11']"
                  item-text="name"
                  item-value="id"
                  :label="selectedLanguage['adjustParameters_component_text12']"
                  @input="onSideBarrierSelected"
                />
              </v-col>
            </v-row>

            <PlatformAquisition1Lanes v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']" :params="selectedOptions"  />
            <PlatformAquisition2Lanes v-else :params="selectedOptions"  />

            <br /><v-divider /><br />
            <v-row v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']" >
              <v-col></v-col>
              <v-col cols="12">
                <Platform1Lane :params="selectedOptions" />
              </v-col>
              <v-col></v-col>
            </v-row>
            <v-row v-else>
              <v-col></v-col>
              <v-col cols="12">
                <Platform2Lanes :params="selectedOptions" :callWatcher="callWatcher(this.currentCalc.values_params.platform.median_width)" />
              </v-col>
              <v-col></v-col>
            </v-row>
            <br /><v-divider /><br />
            <PlatformBridge />
            <br />
            <v-col cols="12">
              <v-spacer />
              <PlatformBridge1Lane
                v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                :params="selectedOptions"
              />
              <PlatformBridge2Lanes v-else :params="selectedOptions" />
              <v-spacer />
            </v-col>
            <br /><v-divider /><br />
            <PlatformTunnel />
            <br /><br />
            <v-col cols="12">
              <v-spacer />
              <PlatformTunnel1Lane
                v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                :params="selectedOptions"
              />
              <PlatformTunnel2Lanes v-else :params="selectedOptions" />
              <v-spacer />
            </v-col>
          </v-card-text>
        </v-tab-item>
        <v-tab-item key="earthwork">
          <v-card-text>
            <br />
            <br />
            <EarthworkTransport />
            <br /><v-divider /><br />
            <EarthworkCut1Lane
              v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
              :params="selectedOptions"
            />
            <EarthworkCut2Lanes v-else :params="selectedOptions" />
            <br /><v-divider /><br />
            <EarthworkFill1Lane
              v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
              :params="selectedOptions"
            />
            <EarthworkFill2Lanes v-else :params="selectedOptions" />
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Curves from "./params/Curves.vue";
import PlatformAquisition1Lanes from "./params/PlatformAquisition1Lanes.vue";
import PlatformAquisition2Lanes from "./params/PlatformAquisition2Lanes.vue";
import Platform1Lane from "./params/Platform1Lane.vue";
import Platform2Lanes from "./params/Platform2Lanes.vue";
import PlatformBridge from "./params/PlatformBridge.vue";
import PlatformBridge1Lane from "./params/PlatformBridge1Lane.vue";
import PlatformBridge2Lanes from "./params/PlatformBridge2Lanes.vue";
import PlatformTunnel from "./params/PlatformTunnel.vue";
import PlatformTunnel1Lane from "./params/PlatformTunnel1Lane.vue";
import PlatformTunnel2Lanes from "./params/PlatformTunnel2Lanes.vue";

import EarthworkTransport from "./params/EarthworkTransport.vue";
import EarthworkCut1Lane from "./params/EarthworkCut1Lane.vue";
import EarthworkCut2Lanes from "./params/EarthworkCut2Lanes.vue";
import EarthworkFill1Lane from "./params/EarthworkFill1Lane.vue";
import EarthworkFill2Lanes from "./params/EarthworkFill2Lanes.vue";

import { portuguese } from "../../../languages/pt-br";
import { english } from "../../../languages/en";


export default {
  name: "AdjustParameters",
  components: {
    Curves,
    EarthworkTransport,
    EarthworkCut1Lane,
    EarthworkCut2Lanes,
    EarthworkFill1Lane,
    EarthworkFill2Lanes,
    Platform1Lane,
    Platform2Lanes,
    PlatformAquisition1Lanes,
    PlatformAquisition2Lanes,
    PlatformBridge,
    PlatformBridge1Lane,
    PlatformBridge2Lanes,
    PlatformTunnel,
    PlatformTunnel1Lane,
    PlatformTunnel2Lanes,
  },

  computed: {
    steps() {
      return this.$store.getters.steps("railway");
    },
    status() {
      return this.$store.getters.status(this.$route.params.id);
    },
    ...mapGetters(["profile","currentCalc", "balanceFormatted"]),
    selectedLanguage() {
      if (this.profile['language'] === 'en') {
        return english;
      } else {
        return portuguese;
      }
    },
  },
  data() {
    return {
      logoIconUrl: "/img/icons/logo_icon.webp",
      tab: "curves",
      color: "#0A7373",
      loading1: false,
      loading2: false,
      centralBarrier: false,
      sideBarrier: true,
      centralBarrierSwitch: 'No',
      selectedOptions: {
        centralBarrierSwitch: 'No',
        central_barrier: "None",
        side_barrier: "None",
      },
      callWatch: undefined,
    };
  },
  mounted: async function () {
    this.currentCalc.values_params.superstructure.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];
    this.currentCalc.values_params.superstructure.side_barrier = this.selectedLanguage['adjustParameters_component_text16'];

    
    this.iniciarCentralBarrierSwitch();
    this.iniciarCentral_barrier();
    this.iniciarSide_barrier();
  },
  methods: {
    iniciarCentralBarrierSwitch(){
      if(this.currentCalc.values_params.platform.median_width > 0){
        this.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text15'];
        this.callWatch = this.selectedLanguage['adjustParameters_component_text15'];
      } else {
        this.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text14'];
        this.callWatch = this.selectedLanguage['adjustParameters_component_text14'];
      }
      this.selectedOptions.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text14'];
    },
    iniciarCentral_barrier(){ return this.selectedOptions.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];},
    iniciarSide_barrier(){ return this.selectedOptions.side_barrier = this.selectedLanguage['adjustParameters_component_text16'];},
    redirectFunc(){
        if(process.env.VUE_APP_VAR_SELECT== 'prd'){
          this.$router.push({ name: 'billingMercadoPago' })
        } else {
          this.$router.push({ name: 'billing' })
        }
      },
    callWatcher(value){
      this.callWatch = value
    },
    async goStep(step) {
      await this.changeStatus({
        calcId: this.$route.params.id,
        payload: {
          status: "Init",
          step_name: this.steps[step - 1],
          step: step,
          notification: this.status.notification,
        },
      });
    },
    ...mapActions(["updateParams", "changeStatus"]),
    async clickSaveExit() {
      this.loading1 = true;
      await this.updateParams();
      await this.goStep(4);
      this.loading1 = false;
    },
    async clickCancelExit() {
      this.loading2 = true;
      await this.goStep(2);
      this.loading2 = false;
    },
    onCentralBSelected(value) {
      if(value === this.selectedLanguage['adjustParameters_component_text14']){
        this.centralBarrier = false
        this.currentCalc.values_params.superstructure.central_barrier = this.selectedLanguage['adjustParameters_component_text16']
        this.selectedOptions.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];
      } else {
        this.centralBarrier = true
      }
      this.selectedOptions.centralBarrierSwitch = value;
      this.callWatch = value
    },
    onLanesSet(value){
      this.currentCalc.values_params.platform.lanes = Number(value);
      this.callWatch = Number(value)
    },
    onCentralBarrierSelected(value) {
      this.currentCalc.values_params.superstructure.central_barrier = value;
      this.selectedOptions.central_barrier = value;
      this.callWatch = value
    },
    onSideBarrierSelected(value) {
      this.currentCalc.values_params.superstructure.side_barrier = value;
      this.selectedOptions.side_barrier = value;
      this.callWatch = value
    },
  },
  watch:{
    callWatch:{
      handler(){
        // no central barrier
        if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.currentCalc.values_params.platform.include_gutter_calc == true){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_1_lane'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_1_lane'
          this.currentCalc.values_params.imgs.cut = 'cut_1_lane'
          this.currentCalc.values_params.imgs.fill = 'fill_1_lane'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_1_lane'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_1_lane'
          this.currentCalc.values_params.imgs.structure_total_width = ((2)+(2*this.currentCalc.values_params.platform.sholder)+(2*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.currentCalc.values_params.platform.include_gutter_calc == false){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_gutter_N'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_1_lane'
          this.currentCalc.values_params.imgs.cut = 'cut_1_lane'
          this.currentCalc.values_params.imgs.fill = 'fill_1_lane'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_1_lane_gutter_N'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_1_lane'
          this.currentCalc.values_params.imgs.structure_total_width = ((2*this.currentCalc.values_params.platform.sholder)+(2*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_jersey'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_1_lane_jersey'
          this.currentCalc.values_params.imgs.cut = 'cut_1_lane'
          this.currentCalc.values_params.imgs.fill = 'fill_1_lane_jersey'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_jersey_1_lane'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_1_lane'
          this.currentCalc.values_params.imgs.structure_total_width = ((2)+(2*this.currentCalc.values_params.platform.sholder)+(2*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_metalguard'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_1_lane_metalguard'
          this.currentCalc.values_params.imgs.cut = 'cut_1_lane'
          this.currentCalc.values_params.imgs.fill = 'fill_1_lane_metal_guard'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_metal_guard_1_lane'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_1_lane'
          this.currentCalc.values_params.imgs.structure_total_width = ((2)+(2*this.currentCalc.values_params.platform.sholder)+(2*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        }
        // yes central barrier
        if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))
        } /* central e side iguais */ else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_jersey_lanes'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_jersey'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))
        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_jersey_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_jersey_2'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_jersey_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_metalguard'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_metal_guard_2_lanes'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_metalguard_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_metal_guard_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_metal_guard_2_lanes_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } /* side none */ else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_J'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_J_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_M'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_M_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } /* central e side diferentes */ else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_J_M'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_J_M'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_J_M'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_J_M'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_J_M_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_J_M'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_J_M_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_J_M_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_M_J'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_M_J'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_M_J'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_M_J'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_M_J_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_M_J_2'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_M_J_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_M_J_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } /* central none */ else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_J'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_jersey'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_J_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_M'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        } else if( this.currentCalc.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
          this.currentCalc.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M_2'
          this.currentCalc.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
          this.currentCalc.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.fill = 'fill_2_lanes_N_M_2'
          this.currentCalc.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes_2'
          this.currentCalc.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
          this.currentCalc.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.currentCalc.values_params.platform.sholder)+(4*(this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))))

        }
        this.currentCalc.values_params.imgs.has_central_barrier = this.selectedOptions.centralBarrierSwitch
        this.currentCalc.values_params.imgs.structure_lanes_total_width = (this.currentCalc.values_params.platform.lanes*(this.currentCalc.values_params.platform.lane))
        this.currentCalc.values_params.imgs.asphalt_depht = (Number(this.currentCalc.values_params.superstructure.first_asphalt_layer_depth)+Number(this.currentCalc.values_params.superstructure.second_asphalt_layer_depth))
      }
    }
  },
};
</script>
