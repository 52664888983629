<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 482.35 218.16" style="enable-background:new 0 0 482.35 218.16;" xml:space="preserve">
<g id="Terreno">
	<line class="st_fill_0" x1="-8.07" y1="218.05" x2="409.09" y2="148.8"/>
</g>
<g id="Aterro">
	<path class="st_fill_1" d="M481.96,149.42c0,0-0.11-0.13-0.32-0.39c-0.21-0.25,0.48-2.63,0.07-3.12c-0.41-0.57-0.91-1.03-1.49-1.8
		c-0.59-1.19-1.26-2.23-2.01-2.43c-0.19,0.18-0.38-0.94-0.58-0.7c-0.2-0.62-0.4,0.49-0.61-0.73c-0.21-0.45-0.42,0.17-0.64-0.95
		c-0.22,0.48-0.44-0.4-0.66-0.62c-0.23-0.18-0.46,0.15-0.69-0.79c-0.23-0.8-0.47-1.14-0.72-1.91c-0.24-0.69-0.49-1.14-0.74-1.57
		c-0.25-0.29-0.51-0.36-0.77-0.25c-0.52,0.12-1.05-1.51-1.6-1.93c-0.55-2.54-1.11-1.53-1.69-2.04c-0.58-1.83-1.17-1.52-1.78-2.15
		c-0.61,0.49-1.23-1.01-1.86-2.24c-0.63-0.84-1.27-1.59-1.93-2.33c-0.65-1.48-1.32-1.42-2-2.41c-0.68-1.15-1.36-1.19-2.06-2.49
		c-0.7-0.6-1.4-1.59-2.12-2.55c-0.71-0.67-1.44-2.02-2.17-2.62c-0.73-0.85-1.47-1.7-2.21-2.67c-0.74-0.68-1.49-1.63-2.25-2.72
		c-0.76-0.91-1.52-1.84-2.29-2.76c-0.77-0.73-1.54-1.44-2.31-2.79c-0.78-1.1-1.55-2.17-2.34-2.82c-0.78-0.6-1.57-1.71-2.35-2.84
		c-0.79-0.41-1.57-2.1-2.36-2.85c-0.79-0.82-1.58-0.91-2.37-1.86c-0.79-0.92-1.58-1.71-2.37-2.86c-0.79-1.02-1.58-1.81-2.36-2.85
		c-0.79-0.83-4.57-5.76-5.35-6.84c-0.78-1.18-1.56-2-2.34-2.82c-0.78-0.65-1.55-1.96-2.31-2.79c-0.77-0.85-1.53-1.88-2.29-2.76
		c-0.76-0.69-1.51-2.02-2.25-2.72c-0.74-0.43-1.48-1.36-2.21-2.67c-0.73-1.28-1.45-2-2.17-2.62c-0.71-1.1-1.42-2.05-2.12-2.55
		c-0.7-1.52-1.38-1.86-2.06-2.49c-0.68,0.16-1.34-1.97-2-2.41c-0.65-1.01-1.3-1.63-1.93-2.33c-0.63-0.75-1.25-1.56-1.86-2.24
		c-0.61-0.38-1.2-1.34-1.78-2.15c-0.58-0.93-1.14-1.68-1.69-2.04c-0.55-0.41-1.08-1.36-1.6-1.93c-0.52,0.17-1.02-1.09-1.51-1.82
		c-0.49-1.01-0.95-0.47-1.41-1.7c-0.45-0.2-0.88-1.14-1.3-1.57c-0.41,0.16-0.81-0.19-1.19-1.43c-0.38-0.91-0.73-0.45-1.07-1.29
		c-0.34-0.64-0.65-0.29-0.94-1.14c-0.29-0.2-1.56-1.48-1.81-1.98c-0.25-0.13-0.48-0.07-0.68-0.82c-0.2-0.4-0.38-0.45-0.54-0.65
		c-0.16,0.12-0.29-0.91-0.39-0.47c-0.11-0.13-0.19-0.12-0.24-0.29c-0.05,0.17-0.08,0.13-0.08-0.1c0,0.48-0.03,0.02-0.1,0
		c-0.07-0.05-0.17,0.71-0.3,0c-0.13,0.09-0.3,0.16-0.49,0c-0.2,0.1,0.58,0.21,0.32,0c-0.26,0.05,0.45-0.04,0.14,0
		c-0.32-0.16-0.67-0.16-1.04,0c-0.38,0.07,0.22,0.71-0.21,1c-0.43,0.07-0.89-0.07-1.38,0c-0.49,0.04-1-0.06-1.54,0
		c-0.54,0.06-1.1,0.05-1.69,0c-0.59,0.22-1.2-0.2-1.84,0c-0.64-0.12-1.3-0.12-1.98,0c-0.68-0.12-1.39-0.18-2.12,0
		c-0.73-0.19-1.48,0.03-2.25,0c-0.77,0.01-1.56,0.03-2.37,0c-0.81-0.17-1.64-0.05-2.49,0c-0.85,0.15-1.72-0.1-2.61,0
		c-0.89-0.04-1.79-0.13-2.71,0c-0.92-0.2-1.86,0.07-2.82,0c-0.96,0.07-1.93-0.18-2.91,0c-0.99-0.3-1.99-0.29-3.01,0
		c-1.02-0.04-2.05-0.26-3.09,0c-1.04-0.31-2.1-0.26-3.17,0c-1.07,0.85-2.15-0.23-3.24,0c-1.09-0.91-2.2,0.34-3.31,0
		c-1.11,0.85-2.24-0.33-3.38,0c-1.13,0.07-2.28,0.6-3.43,0c-1.15-0.07-2.31-0.38-3.48,0c-1.17,0.12-2.35-0.4-3.53,0
		c-1.18,0.05-2.37,0.09-3.57,0c-1.2,0.19-2.4-0.06-3.6,0c-1.21-0.22-2.42,15-3.63,15c-1.21-0.3-2.43,0.09-3.65,0
		c-1.22-0.2-2.45-0.38-3.67,0c-1.23,0.38-2.45,0.24-3.68,0c-1.23-0.14-2.46-0.26-3.69,0c-1.23-0.25-2.46,0.19-3.69,0
		c-1.23-0.02-2.46-0.15-3.68,0c-1.23,0.09-2.45,0.32-3.67,0c-1.22-0.33-2.44,0.23-3.65,0c-1.21-0.05-2.43-14.83-3.63-15
		c-1.21-0.05-2.41,0.11-3.6,0c-1.2,0.22-2.39,0.13-3.57,0c-1.18,0.16-2.36-0.22-3.53,0c-1.17,0.29-2.33-0.6-3.48,0
		c-1.15-0.42-2.3-0.46-3.43,0c-1.13-0.45-2.26,0.07-3.38,0c-1.11-0.48-2.22,0.56-3.31,0c-1.09-0.11-2.17,0.1-3.24,0
		c-1.07,0-2.13-0.2-3.17,0c-1.04-0.21-2.07,0.01-3.09,0c-1.02-0.37-2.02-0.22-3.01,0c-0.99,0.06-1.96,0.05-2.91,0
		c-0.96,0.43-1.89,0-2.82,0s-1.83,0-2.71,0c-0.89-0.05-1.76-0.31-2.61,0c-0.85-0.02-1.68-0.29-2.49,0c-0.81,0.18-1.6,0-2.37,0
		c-0.77-0.22-1.52,0-2.25,0c-0.73,0-1.43,0-2.12,0c-0.68-0.22-1.34,0.67-1.98,0c-0.64-0.67-1.25-1.16-1.84,0
		c-0.59-0.12-1.15,0.14-1.69,0c-0.54-0.11-1.05,0.03-1.54,0c-0.49,0.46-0.95-0.31-1.38,0c-0.43-0.18,0.16,0.66-0.21,0
		c-0.38,0.05,1.28-1,0.96-1c-0.32,0.16,0.39,0.36,0.14,0c-0.26,0.2-0.49-0.08-0.68,0c-0.2-0.53-0.36,0-0.49,0
		c-0.13-0.03-0.23-0.13-0.3,0c-0.07,0.18-0.1,0.21-0.1,0c0,0.12-0.03-0.17-0.08,0.09c-0.05,1.08-0.13,0.03-0.24,0.28
		c-0.11-0.16-0.24,0.77-0.39,0.46c-0.16-0.24-2.34,1-2.54,1.63c-0.2,0.23-0.43,0.71-0.68,0.79c-0.25,1.18-0.52,1.12-0.82,0.95
		c-0.29,1.09,1.39,0.64,1.05,0.1c-0.34-0.01-0.69-0.27-1.07,1.25c-0.38-0.09-1.77,0.68-2.19,1.39c-0.42-0.34-0.85,1.14-1.3,1.52
		c-0.45,0.73-0.92,0.9-1.41,1.65c-0.49,0.29-0.99,1.71-1.51,1.77c-0.52,0.56-1.06,0.24-1.61,1.88c-0.55,1.26-1.12,1.97-1.7,1.99
		c-0.58,0.78-1.18,1.17-1.79,2.09c-0.61,0.78-1.23,1.5-1.87,2.18c-0.64,1.3-1.28,1.16-1.94,2.27c-0.66,0.85-1.33,1.5-2.01,2.35
		c-0.68,0.69-1.37,1.32-2.08,2.42c-0.7,1.15-1.41,1.35-2.14,2.49c-0.72,0.53-1.45,1.81-2.19,2.55c-0.74,0.89-1.48,1.37-2.24,2.61
		c-0.75,0.45-1.51,1.56-2.28,2.65c-0.77,0.7-1.54,2.15-2.31,2.7c-0.78,0.74-1.56,1.88-2.34,2.73c-0.79,0.27-1.58,0.85-2.37,2.76
		c-0.79,1.36-5.59,6.08-6.39,6.78c-0.8,1.63-0.6,3.69-1.4,3.8c-0.8,1-1.61-0.31-2.41,0.81c-0.8,1.19-1.61,1.9-2.41,2.81
		c-0.8,0.7-1.61,1.89-2.41,2.81c-0.8,0.96-1.6,1.71-2.4,2.8c-0.8,0.99-1.59,1.56-2.39,2.78c-0.79,1.24-1.58,1.98-2.37,2.76
		c-0.79,0.57-1.57,1.98-2.34,2.73c-0.78,0.95-1.55,2.05-2.31,2.7c-0.77,1-1.52,3.71-2.28,4.65c-0.75,0.64-1.5,1.48-2.24,2.61
		c-0.74,0.93-1.47,1.1-2.19,1.55c-0.72,0.93-1.43,1.62-2.14,2.49c-0.7,0.89-1.4,1.4-2.08,2.42c-0.68,0.54-1.35,1.59-2.01,2.35
		c-0.66,0.29-1.31,1.14-1.94,2.27c-0.64,0.38-1.26,0.19-1.87,2.18c-0.61,0.8-1.21,0.91-1.79,2.09c-0.58,0.31-1.15,1.54-1.7,1.99
		c-0.55,1.11-1.09,1.45-1.61,1.88c-0.52,0.81-1.03,0.88-1.51,1.77c-0.49,0.91-0.96,1.19-1.41,1.65c-0.45,0.75-0.89,3.98-1.3,3.52
		c-0.42,0.27-0.81-1.13-1.19-0.61c-0.38,1.24-0.73,0.07-1.07,2.25c-0.34,0.13-0.65,1.3-0.95,1.1c-0.29-0.28-0.57,0.88-0.82,0.95
		c-0.25,0.36-0.48,0.66-0.68,0.79c-0.2,0.06-0.38-0.37-0.54-0.37c-0.16-0.67-0.29-0.41-0.39,1.46c-0.11,0.94-0.19,0.26-0.24,0.28
		c-0.05,0.81-0.08-0.56-0.08,0.09c0-0.25-0.11-0.63-0.33-0.02c-0.22-0.35-0.53-1.28-0.94-0.06c-0.41-0.7-0.91-0.35-1.49-0.1
		c-0.58-0.75-1.24,0.6-1.98-0.13c-0.73-0.63-1.54-0.16-2.4-0.16c-0.86,0.01-1.78,0.58-2.75,0.82c-0.97-0.11-1.99-0.96-3.04-1.2
		c-1.06,0.25-2.15,0.94-3.27,0.79c-1.12,0.16-2.27-1.31-3.43-1.22c-1.16,0.28-2.34,0.52-3.53-0.23c-1.19,0.42-2.38-0.68-3.56-0.23
		c-1.19,0.26-2.37-0.28-3.53-0.23c-1.16,0.38-2.31-0.42-3.43-0.22c-1.12-0.33-2.21,0.11-3.27-0.21c-1.06-0.16-2.07-0.1-3.04-0.2
		c-0.97,0.44-1.89-0.77-2.75-0.18c-0.86-0.11-1.66,1.64-2.4-0.16c-0.73-1.06-1.4-0.25-1.98-0.13c-0.58,0.12-1.08-1.34-1.49-1.1
		c-0.41-0.3-0.73-0.75-0.94-0.06c-0.22-0.86-0.33-0.44-0.33-0.02c0,0.08-0.04-0.16-0.12,0.13c-0.08,0.5-0.2,0.59-0.35,0.37
		c-0.16-0.21-0.35,1.11-0.58,0.6c-0.23,0.13-0.49,0.35-0.79,0.82c-0.3,0.46-0.62,0.24-0.98,1.02c-0.36,0.49-0.75,1.06-1.17,1.22
		c-0.42,0.4-0.87,0-1.34,1.4c-0.48,0.47-0.98,1.35-1.51,1.57c-0.53,0.13-1.08,1.05-1.66,1.72c-0.58,1.19-1.17,1.58-1.79,1.87
		c-0.62,1.3-1.26,1.33-1.92,2c-0.66,1.05-1.34,0.99-2.03,2.12c-0.7,0.61-1.41,1.22-2.14,2.22c-0.73,0.84-1.47,1.49-2.23,2.32
		c-0.76,1.14-1.52,2.25-2.3,2.4c-0.78,0.56-1.57,1.27-2.37,2.47c-0.8,0.38-1.61,1.59-2.42,2.52c-0.82,1.41-1.64,1.96-2.46,2.57
		c-0.83,0.67-1.66,1.83-2.49,2.6c-0.84,0.3-1.67,2.23-2.51,2.62c-0.84,1.45-1.68,1.28-2.52,2.62c-0.84,0.73-1.68,1.3-2.51,2.62
		c-0.84,0.97-1.67,1.22-2.49,2.6c-0.83,1.18-1.65,1.53-2.46,2.57c-0.82,0.75-1.62,1.61-2.42,2.52c-0.8,1.07-1.59,1.7-2.37,2.47
		c-0.78,0.67-1.55,2.23-2.3,2.4c-0.76,0.9-1.5,0.89-2.23,2.32c-0.73,1.21-1.44,1.19-2.14,2.22c-0.7,0.86-1.37,1.43-2.03,2.12
		c-0.66,0.64-1.3,1.65-1.92,2c-0.62,0.37-1.22,1.1-1.79,1.87c-0.58,0.89-1.13,1.04-1.66,1.72c-0.53,0.55-1.03,0.45-1.51,1.57
		c-0.48,0.67-0.92,0.72-1.34,1.4c-0.42,0.31-0.81,0.77-1.17,1.22c-0.36,0.22-0.69,3.72-0.98,4.02c-1.18,1.23-1.84,1.91-1.84,1.91
		L481.96,149.42z"/>
	<polygon class="st_fill_2" points="481.96,149.42 390.3,38.77 213.22,38.77 118.3,149.42 68.41,146.16 -0.46,217.87 	"/>
</g>
<g id="Canaleta" class="st_fill_3">
	<g class="st_fill_4">
		<rect x="224.16" y="26.76" class="st_fill_5" width="6.44" height="3.7"/>
	</g>
	<g class="st_fill_4">
		<polygon class="st_fill_6" points="231.05,26.76 231.05,30.98 223.82,30.98 223.82,26.76 224.59,26.76 224.59,30.2 230.28,30.2 
			230.28,26.76 		"/>
	</g>
	<g class="st_fill_4">
		<rect x="292.37" y="26.76" class="st_fill_5" width="6.44" height="3.7"/>
	</g>
	<g class="st_fill_4">
		<polygon class="st_fill_6" points="299.2,26.76 299.2,30.98 291.97,30.98 291.97,26.76 292.74,26.76 292.74,30.2 298.43,30.2 
			298.43,26.76 		"/>
	</g>
</g>
<g id="Medidas">
	<text transform="matrix(1 0 0 1 25.2161 97.0225)" class="st_fill_7 st_fill_8">{{ currentCalc.values_params.earthwork.fill_height }}m</text>
	<text transform="matrix(1 0 0 1 82.9207 171.1367)" class="st_fill_9 st_fill_7 st_fill_8">{{ currentCalc.values_params.earthwork.fill_enbankment_width }}m</text>
	<text transform="matrix(1 0 0 1 433.158 64.0215)" class="st_fill_7 st_fill_8">{{ Math.round(
              (1 / currentCalc.values_params.earthwork.fill_factor) * 10
            ) / 10 }}</text>
	<text transform="matrix(1 0 0 1 451.3669 87.791)" class="st_fill_7 st_fill_8">1.5</text>
	<text transform="matrix(1 0 0 1 81.1589 134.8301)" class="st_fill_7 st_fill_8">{{ currentCalc.values_params.earthwork.fill_enbankment_slope }}%</text>
	<path class="st_fill_10" d="M448.36,96.17V68.78l-21.15-0.05L448.36,96.17z"/>
	<rect x="53.12" y="149.19" width="6.69" height="0.46"/>
	<rect x="53.12" y="38.54" width="6.69" height="0.46"/>
	<rect x="68.82" y="140.96" width="48.96" height="0.46"/>
	<polygon points="116.86,141.97 117.64,141.19 116.86,140.4 117.52,140.4 118.3,141.19 117.52,141.97 	"/>
	<g>
		<g>
			<line class="st_fill_11" x1="70.34" y1="152.48" x2="117.36" y2="152.55"/>
			<g>
				<polygon class="st_fill_9" points="71.34,153.33 70.49,152.48 71.34,151.63 70.62,151.63 69.77,152.48 70.62,153.33 				"/>
			</g>
			<g>
				<polygon class="st_fill_9" points="116.36,153.4 117.21,152.55 116.37,151.7 117.09,151.7 117.93,152.55 117.08,153.4 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_fill_12" x1="56.43" y1="148.48" x2="56.43" y2="39.62"/>
			<g>
				<polygon points="57.28,147.48 56.43,148.33 55.58,147.48 55.58,148.2 56.43,149.05 57.28,148.2 				"/>
			</g>
			<g>
				<polygon points="57.28,40.62 56.43,39.77 55.58,40.62 55.58,39.9 56.43,39.05 57.28,39.9 				"/>
			</g>
		</g>
	</g>
	<rect x="117.85" y="149.13" class="st_fill_9" width="0.46" height="6.69"/>
	<rect x="68.85" y="149.13" class="st_fill_9" width="0.46" height="6.69"/>
</g>
<g id="Canteiro">
	<g>
		<polygon class="st_fill_13" points="306.81,32.02 306.81,39.13 296.32,39.13 296.32,32.04 297.49,32.07 297.49,33.28 305.64,33.28 
			305.64,32.12 		"/>
		<path d="M307,39.32h-10.87v-7.48l1.56,0.04v1.21h7.76v-1.14L307,31.8V39.32z M296.52,38.93h10.09v-6.7l-0.78,0.07v1.17h-8.54
			v-1.22l-0.78-0.02V38.93z"/>
	</g>
	<g class="st_fill_3">
		<polygon class="st_fill_14" points="306.94,32.02 306.94,39.13 296.45,39.13 296.45,32.04 297.62,32.07 305.77,32.12 		"/>
		<path class="st_fill_4" d="M307.13,39.32h-10.87v-7.48l1.37,0.03l8.14,0.05l1.36-0.13V39.32z M296.65,38.93h10.09v-6.7l-0.96,0.09
			l-8.17-0.05l-0.97-0.02V38.93z"/>
	</g>
</g>
<g id="Sub_x5F_base">
	<g>
		<polygon class="st_fill_15" points="310.14,39.08 310.14,39.13 310.1,39.13 		"/>
		<polygon points="310.33,39.32 309.66,39.32 310.33,38.58 		"/>
	</g>
	<g>
		<polygon class="st_fill_16" points="295.03,35.43 255.58,34.65 216.4,35.43 218.56,31.73 255.58,30.95 292.87,31.73 		"/>
		<path d="M216.06,35.63l2.39-4.1l0.11,0l37.02-0.78l37.41,0.78l2.39,4.1l-39.8-0.79L216.06,35.63z M255.58,34.46l39.11,0.77
			l-1.93-3.31l-37.18-0.78l-36.91,0.78l-1.93,3.31L255.58,34.46z"/>
	</g>
	<g>
		<polygon class="st_fill_17" points="297.16,39.13 255.58,38.35 214.27,39.13 216.35,35.43 255.58,34.65 295.08,35.43 		"/>
		<path d="M213.93,39.33l2.3-4.1l0.11,0l39.23-0.78l39.62,0.78l2.3,4.1l-41.92-0.79L213.93,39.33z M255.58,38.16l41.25,0.77
			l-1.86-3.31l-39.39-0.78l-39.12,0.78l-1.86,3.31L255.58,38.16z"/>
	</g>
	<g>
		<polygon class="st_fill_16" points="387.46,35.43 348.01,34.65 308.83,35.43 310.99,31.73 348.01,30.95 385.31,31.73 		"/>
		<path d="M308.49,35.63l2.39-4.1l0.11,0l37.02-0.78l37.41,0.78l2.39,4.1l-39.8-0.79L308.49,35.63z M348.01,34.46l39.11,0.77
			l-1.93-3.31l-37.18-0.78l-36.91,0.78l-1.93,3.31L348.01,34.46z"/>
	</g>
	<g>
		<polygon class="st_fill_17" points="389.6,39.13 348.01,38.35 306.7,39.13 308.78,35.43 348.01,34.65 387.52,35.43 		"/>
		<path d="M306.37,39.33l2.3-4.1l0.11,0l39.23-0.78l39.62,0.78l2.3,4.1l-41.92-0.79L306.37,39.33z M348.01,38.16l41.25,0.77
			l-1.86-3.31l-39.39-0.78l-39.12,0.78l-1.86,3.31L348.01,38.16z"/>
	</g>
</g>
<g id="Land">
	<g>
		<polygon class="st_fill_17" points="317.15,31.46 310.14,39.08 310.1,39.13 292.95,39.13 292.92,39.13 286.13,31.46 296.32,32.04 
			296.32,34.26 306.96,34.26 306.96,32.04 		"/>
		<path d="M310.18,39.32h-17.35l-7.16-8.08l10.84,0.62v2.21h10.25v-2.21l10.86-0.62L310.18,39.32z M293.01,38.93h17l6.67-7.25
			l-9.53,0.54v2.23h-11.03v-2.23l-9.54-0.55L293.01,38.93z"/>
	</g>
</g>
<g id="Asphalt_x5F_and_components">
	<g>
		<polygon points="288.12,30.64 255.53,29.86 222.93,30.64 222.93,31.62 255.53,30.84 288.12,31.62 		"/>
	</g>
	<g>
		<polygon points="380.88,30.64 348.29,29.86 315.7,30.64 315.7,31.62 348.29,30.84 380.88,31.62 		"/>
	</g>
	<g>
		<polygon class="st_fill_18" points="223.13,31.52 218.49,31.52 218.49,29.57 219.26,29.57 219.26,30.74 223.13,30.74 		"/>
	</g>
	<g>
		<polygon class="st_fill_18" points="385.67,31.52 381.02,31.52 381.02,30.74 384.89,30.74 384.89,29.57 385.67,29.57 		"/>
	</g>
	<polygon class="st_fill_19" points="-4.07,218.05 482.93,218.05 482.93,149.05 	"/>
</g>
<g id="Metal_x5F_guard" class="st_fill_3">
	<g class="st_fill_4">
		<g>
			<g>
				<g>
					<g>
						<path class="st_fill_13" d="M301.02,27.99h-2.64c-0.14,0-0.25-0.11-0.25-0.25l0,0c0-0.14,0.11-0.25,0.25-0.25h2.64
							c0.14,0,0.25,0.11,0.25,0.25l0,0C301.26,27.88,301.15,27.99,301.02,27.99z"/>
					</g>
					<g>
						<path d="M301.02,28.05h-2.64c-0.17,0-0.31-0.14-0.31-0.31s0.14-0.31,0.31-0.31h2.64c0.17,0,0.31,0.14,0.31,0.31
							S301.19,28.05,301.02,28.05z M298.38,27.56c-0.1,0-0.19,0.08-0.19,0.19s0.08,0.19,0.19,0.19h2.64c0.1,0,0.19-0.08,0.19-0.19
							s-0.08-0.19-0.19-0.19H298.38z"/>
					</g>
				</g>
				<g>
					<g>
						<polygon class="st_fill_13" points="298.71,27.74 297.56,26.42 298.71,24.94 299.86,24.94 298.71,26.42 299.86,27.74 298.71,29.39 
							299.86,30.71 298.71,30.71 297.53,29.39 						"/>
					</g>
					<g>
						<path d="M300,30.77h-1.32l-1.23-1.38l1.18-1.65l-1.16-1.32l1.2-1.55h1.31l-1.2,1.54l1.15,1.32l-1.15,1.65L300,30.77zM298.74,30.65h0.99l-1.1-1.25l1.15-1.65l-1.16-1.32l1.11-1.42h-1l-1.1,1.42l1.15,1.32l-1.18,1.65L298.74,30.65z"/>
					</g>
				</g>
				<g>
					<g>
						<rect x="299.95" y="24.86" class="st_fill_20" width="0.82" height="7.09"/>
					</g>
					<g>
						<path d="M300.83,32.01h-0.95V24.8h0.95V32.01z M300.01,31.88h0.7v-6.96h-0.7V31.88z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st_fill_13" d="M302.44,27.99h2.64c0.14,0,0.25-0.11,0.25-0.25l0,0c0-0.14-0.11-0.25-0.25-0.25h-2.64
							c-0.14,0-0.25,0.11-0.25,0.25l0,0C302.19,27.88,302.3,27.99,302.44,27.99z"/>
					</g>
					<g>
						<path d="M302.13,27.74c0-0.17,0.14-0.31,0.31-0.31h2.64c0.17,0,0.31,0.14,0.31,0.31s-0.14,0.31-0.31,0.31h-2.64
							C302.27,28.05,302.13,27.91,302.13,27.74z M302.44,27.56c-0.1,0-0.19,0.08-0.19,0.19s0.08,0.19,0.19,0.19h2.64
							c0.1,0,0.19-0.08,0.19-0.19s-0.08-0.19-0.19-0.19H302.44z"/>
					</g>
				</g>
				<g>
					<g>
						<polygon class="st_fill_13" points="304.75,27.74 305.9,26.42 304.75,24.94 303.59,24.94 304.75,26.42 303.59,27.74 304.75,29.39 
							303.59,30.71 304.75,30.71 305.92,29.39 						"/>
					</g>
					<g>
						<path d="M304.67,29.39l-1.15-1.65l1.15-1.32l-1.2-1.54h1.31l1.2,1.55l-1.16,1.32l1.18,1.65l-1.23,1.38h-1.32L304.67,29.39zM305.85,29.39l-1.18-1.65l1.15-1.32l-1.1-1.42h-1l1.11,1.42l-1.16,1.32l1.15,1.65l-1.1,1.25h0.99L305.85,29.39z"/>
					</g>
				</g>
				<g>
					<g>
						
							<rect x="302.69" y="24.86" transform="matrix(-1 -4.525059e-11 4.525059e-11 -1 606.1979 56.8022)" class="st_fill_20" width="0.82" height="7.09"/>
					</g>
					<g>
						<path d="M302.63,24.8h0.95v7.21h-0.95V24.8z M303.45,24.92h-0.7v6.96h0.7V24.92z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st_fill_13" d="M218.24,27.6h2.64c0.14,0,0.25-0.11,0.25-0.25l0,0c0-0.14-0.11-0.25-0.25-0.25h-2.64
						c-0.14,0-0.25,0.11-0.25,0.25l0,0C217.99,27.49,218.1,27.6,218.24,27.6z"/>
				</g>
				<g>
					<path d="M217.93,27.35c0-0.17,0.14-0.31,0.31-0.31h2.64c0.17,0,0.31,0.14,0.31,0.31s-0.14,0.31-0.31,0.31h-2.64
						C218.07,27.66,217.93,27.52,217.93,27.35z M218.24,27.17c-0.1,0-0.19,0.08-0.19,0.19s0.08,0.19,0.19,0.19h2.64
						c0.1,0,0.19-0.08,0.19-0.19s-0.08-0.19-0.19-0.19H218.24z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_fill_13" points="220.54,27.35 221.7,26.03 220.54,24.55 219.39,24.55 220.54,26.03 219.39,27.35 220.54,29 
						219.39,30.32 220.54,30.32 221.72,29 					"/>
				</g>
				<g>
					<path d="M220.47,29l-1.15-1.65l1.15-1.32l-1.2-1.54h1.31l1.2,1.55l-1.16,1.32L221.8,29l-1.23,1.38h-1.32L220.47,29z M221.64,29
						l-1.18-1.65l1.15-1.32l-1.1-1.42h-1l1.11,1.42l-1.16,1.32l1.15,1.65l-1.1,1.25h0.99L221.64,29z"/>
				</g>
			</g>
			<g>
				<g>
					
						<rect x="218.48" y="24.47" transform="matrix(-1 -4.525059e-11 4.525059e-11 -1 437.7917 56.0227)" class="st_fill_20" width="0.82" height="7.09"/>
				</g>
				<g>
					<path d="M218.42,24.41h0.95v7.21h-0.95V24.41z M219.25,24.53h-0.7v6.96h0.7V24.53z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st_fill_13" d="M385.53,27.6h-2.64c-0.14,0-0.25-0.11-0.25-0.25v0c0-0.14,0.11-0.25,0.25-0.25h2.64
						c0.14,0,0.25,0.11,0.25,0.25l0,0C385.77,27.49,385.66,27.6,385.53,27.6z"/>
				</g>
				<g>
					<path d="M385.53,27.66h-2.64c-0.17,0-0.31-0.14-0.31-0.31s0.14-0.31,0.31-0.31h2.64c0.17,0,0.31,0.14,0.31,0.31
						S385.7,27.66,385.53,27.66z M382.89,27.17c-0.1,0-0.19,0.08-0.19,0.19s0.08,0.19,0.19,0.19h2.64c0.1,0,0.19-0.08,0.19-0.19
						s-0.08-0.19-0.19-0.19H382.89z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_fill_13" points="383.22,27.35 382.07,26.03 383.22,24.55 384.37,24.55 383.22,26.03 384.37,27.35 383.22,29 
						384.37,30.32 383.22,30.32 382.04,29 					"/>
				</g>
				<g>
					<path d="M384.51,30.38h-1.32L381.96,29l1.18-1.65l-1.16-1.32l1.2-1.55h1.31l-1.2,1.54l1.15,1.32L383.3,29L384.51,30.38zM383.25,30.26h0.99l-1.1-1.25l1.15-1.65l-1.16-1.32l1.11-1.42h-1l-1.1,1.42l1.15,1.32L382.12,29L383.25,30.26z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="384.45" y="24.47" class="st_fill_20" width="0.82" height="7.09"/>
				</g>
				<g>
					<path d="M385.34,31.62h-0.95v-7.21h0.95V31.62z M384.52,31.49h0.7v-6.96h-0.7V31.49z"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Jerseys" class="st_fill_3">
	<g class="st_fill_4">
		<g>
			<polygon class="st_fill_13" points="218.43,31.51 222.68,31.51 222.68,29.85 221.22,28.49 220.64,27.51 220.25,24.59 218.4,24.59 			
				"/>
			<path d="M222.88,31.7h-4.64l-0.03-7.31h2.22l0.41,3.05l0.55,0.92l1.5,1.4V31.7z M218.62,31.31h3.86v-1.38l-1.43-1.35l-0.61-1.01
				l-0.37-2.79h-1.49L218.62,31.31z"/>
		</g>
		<g>
			<polygon class="st_fill_13" points="385.37,31.51 381.12,31.51 381.12,29.85 382.58,28.49 383.16,27.51 383.55,24.59 385.41,24.59 			
				"/>
			<path d="M385.57,31.7h-4.64v-1.94l1.5-1.4l0.55-0.92l0.41-3.05h2.22L385.57,31.7z M381.32,31.31h3.86l0.03-6.53h-1.48l-0.37,2.79
				l-0.64,1.05l-1.39,1.31V31.31z"/>
		</g>
		<g>
			<polygon class="st_fill_13" points="305.7,30.24 305.7,31.9 297.52,31.9 297.52,30.24 298.98,28.88 299.56,27.9 299.95,24.98 
				303.27,24.98 303.66,27.9 304.24,28.88 			"/>
			<path d="M305.89,32.09h-8.57v-1.94l1.5-1.4l0.55-0.92l0.41-3.05h3.66l0.41,3.05l0.55,0.92l1.5,1.4V32.09z M297.71,31.7h7.79
				v-1.38l-1.43-1.35l-0.61-1.01l-0.37-2.79h-2.98l-0.37,2.79l-0.64,1.05l-1.39,1.31V31.7z"/>
		</g>
	</g>
</g>
<g id="Grass">
	<g>
		<path class="st_fill_21" d="M297.07,32.08l-10.67-0.69c0,0,0.01-0.06,0-0.13c-0.01,0.11,0.24-0.54,0.54-0.45
			c0.16-0.03,0.33,0.11,0.54,0.09c0.23-0.17,0.44,0.17,0.71,0.03c0.13,0.03,0.23,0.36,0.41,0.01c0.18-0.45,0.29,0.01,0.43,0.05
			c0.13,0.2,0.31-0.11,0.45,0.06c0.2-0.42,0.34-0.19,0.48,0.05c0.11,0.58,0.3,0.34,0.5-0.07c0.18-0.08,0.33,0.05,0.5,0.06
			c0.19-0.22,0.36-0.16,0.51,0.02c0.17,0.06,0.35,0.02,0.5,0.17c0.15,0.25,0.36-0.15,0.5,0.2c0.16,0.12,0.38-0.3,0.52-0.06
			c0.17,0.02,0.36-0.15,0.51-0.06c0.15,0.18,0.32,0.17,0.49,0.11c0.33-0.09,0.67-0.29,0.93,0.14c0.28,0.21,0.57,0.09,0.84,0.03
			c0.28-0.19,0.49,0.08,0.71,0.07c0.24-0.26,0.38,0.22,0.54,0.05c0.3,0.09,0.48-0.02,0.47,0.05
			C297.73,32.13,297.07,32.08,297.07,32.08z"/>
		<path d="M297.18,32.13c-0.05,0-0.09,0-0.11,0l-10.73-0.69l0.01-0.05c0,0,0.01-0.03,0-0.08l-0.01,0l0-0.05l0.04-0.38l0.04,0.27
			c0.1-0.17,0.28-0.44,0.51-0.38c0.09-0.01,0.17,0.01,0.26,0.04c0.08,0.03,0.17,0.06,0.26,0.05c0.13-0.09,0.26-0.04,0.38,0.01
			c0.11,0.04,0.21,0.08,0.33,0.03l0.02-0.01l0.02,0c0.05,0.01,0.09,0.05,0.13,0.09c0.04,0.04,0.08,0.08,0.11,0.07
			c0.02,0,0.06-0.02,0.11-0.13c0.06-0.14,0.12-0.21,0.19-0.22c0.08-0.01,0.14,0.07,0.2,0.14c0.03,0.05,0.07,0.1,0.1,0.11l0.02,0.01
			l0.01,0.02c0.04,0.06,0.08,0.06,0.17,0.02c0.06-0.03,0.15-0.06,0.23-0.01c0.07-0.14,0.14-0.2,0.22-0.2c0,0,0,0,0.01,0
			c0.13,0,0.22,0.16,0.3,0.31l0.01,0.02c0.05,0.28,0.11,0.3,0.13,0.31c0.03,0,0.11-0.04,0.29-0.38l0.01-0.02l0.02-0.01
			c0.13-0.06,0.25-0.02,0.35,0.02c0.05,0.02,0.1,0.03,0.15,0.04c0.09-0.09,0.18-0.14,0.27-0.14c0,0,0,0,0,0c0.1,0,0.2,0.06,0.3,0.17
			c0.05,0.02,0.1,0.02,0.15,0.03c0.12,0.02,0.24,0.04,0.36,0.15l0.01,0.01c0.05,0.08,0.09,0.07,0.18,0.04
			c0.09-0.03,0.23-0.07,0.32,0.15c0.04,0.02,0.09-0.01,0.17-0.07c0.07-0.05,0.15-0.1,0.23-0.09c0.04,0.01,0.07,0.03,0.1,0.07
			c0.05,0,0.11-0.02,0.18-0.04c0.1-0.04,0.22-0.08,0.33-0.01l0.01,0.01c0.13,0.15,0.27,0.15,0.43,0.1c0.05-0.01,0.1-0.03,0.15-0.05
			c0.28-0.09,0.59-0.18,0.82,0.2c0.22,0.17,0.46,0.1,0.7,0.04c0.03-0.01,0.06-0.02,0.09-0.02c0.2-0.13,0.37-0.06,0.51,0
			c0.07,0.03,0.14,0.06,0.2,0.06c0.15-0.15,0.29-0.05,0.37,0.02c0.09,0.07,0.12,0.08,0.16,0.04l0.02-0.02l0.03,0.01
			c0.16,0.05,0.29,0.04,0.37,0.03c0.06-0.01,0.1-0.01,0.12,0.02c0.01,0.01,0.01,0.02,0.02,0.03c0.06,0.08,0.07,0.14,0.04,0.2
			C297.52,32.11,297.32,32.13,297.18,32.13z M286.46,31.35l10.62,0.68c0.15,0.01,0.38,0,0.42-0.07c0.02-0.03-0.02-0.08-0.04-0.11
			l0,0c-0.01,0-0.03,0-0.04,0c-0.08,0.01-0.21,0.03-0.38-0.02c-0.1,0.08-0.19,0-0.26-0.06c-0.1-0.08-0.16-0.12-0.26-0.02l-0.01,0.01
			l-0.02,0c-0.09,0.01-0.18-0.03-0.26-0.07c-0.14-0.06-0.27-0.11-0.42-0.01l-0.02,0.01c-0.03,0.01-0.06,0.02-0.09,0.03
			c-0.24,0.07-0.52,0.14-0.79-0.06l-0.01-0.01c-0.2-0.33-0.44-0.26-0.72-0.17c-0.05,0.02-0.1,0.03-0.16,0.05
			c-0.17,0.05-0.36,0.08-0.53-0.12c-0.07-0.03-0.16-0.01-0.25,0.03c-0.08,0.03-0.16,0.05-0.24,0.05l-0.02,0l-0.01-0.02
			c-0.02-0.03-0.04-0.05-0.06-0.05c-0.04-0.01-0.1,0.03-0.16,0.07c-0.09,0.06-0.2,0.13-0.3,0.06l-0.01-0.01l0-0.01
			c-0.06-0.15-0.12-0.14-0.21-0.11c-0.08,0.02-0.2,0.06-0.29-0.08c-0.09-0.09-0.2-0.1-0.3-0.12c-0.06-0.01-0.12-0.02-0.18-0.04
			l-0.01,0l-0.01-0.01c-0.08-0.1-0.16-0.15-0.23-0.15c0,0,0,0,0,0c-0.07,0-0.14,0.04-0.21,0.12l-0.02,0.02l-0.02,0
			c-0.07,0-0.13-0.03-0.2-0.05c-0.1-0.03-0.18-0.06-0.27-0.03c-0.16,0.32-0.27,0.43-0.38,0.42c-0.1-0.01-0.16-0.13-0.21-0.38
			c-0.06-0.11-0.15-0.25-0.22-0.25c-0.03,0-0.09,0.03-0.17,0.21l-0.03,0.07l-0.05-0.06c-0.04-0.05-0.08-0.04-0.17-0.01
			c-0.08,0.03-0.19,0.07-0.28-0.04c-0.05-0.02-0.1-0.08-0.14-0.14c-0.03-0.05-0.08-0.11-0.11-0.1c-0.01,0-0.05,0.01-0.11,0.17
			c-0.06,0.12-0.12,0.18-0.18,0.19c-0.08,0.01-0.14-0.05-0.19-0.1c-0.03-0.03-0.05-0.05-0.07-0.06c-0.15,0.06-0.27,0.01-0.39-0.03
			c-0.12-0.05-0.2-0.08-0.29-0.01l-0.01,0.01l-0.01,0c-0.12,0.01-0.22-0.02-0.32-0.06c-0.08-0.03-0.16-0.05-0.22-0.04l-0.01,0
			l-0.01,0c-0.18-0.05-0.36,0.24-0.43,0.37c-0.02,0.02-0.03,0.04-0.04,0.06C286.46,31.31,286.46,31.33,286.46,31.35z"/>
	</g>
	<g>
		<path class="st_fill_21" d="M306.11,32.08l10.67-0.69c0,0-0.01-0.06,0-0.13c0.01,0.11-0.24-0.54-0.54-0.45
			c-0.16-0.03-0.33,0.11-0.54,0.09c-0.23-0.17-0.44,0.17-0.71,0.03c-0.13,0.03-0.23,0.36-0.41,0.01c-0.18-0.45-0.29,0.01-0.43,0.05
			c-0.13,0.2-0.31-0.11-0.45,0.06c-0.2-0.42-0.34-0.19-0.48,0.05c-0.11,0.58-0.3,0.34-0.5-0.07c-0.18-0.08-0.33,0.05-0.5,0.06
			c-0.19-0.22-0.36-0.16-0.51,0.02c-0.17,0.06-0.35,0.02-0.5,0.17c-0.15,0.25-0.36-0.15-0.5,0.2c-0.16,0.12-0.38-0.3-0.52-0.06
			c-0.17,0.02-0.36-0.15-0.51-0.06c-0.15,0.18-0.32,0.17-0.49,0.11c-0.33-0.09-0.67-0.29-0.93,0.14c-0.28,0.21-0.57,0.09-0.84,0.03
			c-0.28-0.19-0.49,0.08-0.71,0.07c-0.24-0.26-0.38,0.22-0.54,0.05c-0.3,0.09-0.48-0.02-0.47,0.05
			C305.45,32.13,306.11,32.08,306.11,32.08z"/>
		<path d="M306,32.13c-0.14,0-0.34-0.02-0.4-0.13c-0.03-0.06-0.02-0.12,0.04-0.2c0-0.02,0.01-0.03,0.02-0.03
			c0.03-0.03,0.07-0.02,0.12-0.02c0.08,0.01,0.21,0.02,0.37-0.03l0.03-0.01l0.02,0.02c0.04,0.04,0.07,0.03,0.16-0.04
			c0.09-0.07,0.22-0.18,0.37-0.02c0.06,0,0.13-0.03,0.2-0.06c0.14-0.06,0.31-0.13,0.51,0c0.03,0.01,0.06,0.02,0.09,0.02
			c0.23,0.06,0.47,0.13,0.7-0.04c0.24-0.39,0.55-0.29,0.82-0.2c0.05,0.02,0.1,0.03,0.15,0.05c0.17,0.05,0.31,0.06,0.44-0.1
			l0.01-0.01c0.11-0.06,0.23-0.02,0.33,0.01c0.06,0.02,0.12,0.04,0.18,0.04c0.03-0.04,0.06-0.06,0.1-0.07
			c0.08-0.02,0.16,0.03,0.23,0.09c0.08,0.05,0.13,0.09,0.17,0.07c0.09-0.22,0.23-0.18,0.32-0.15c0.09,0.02,0.13,0.03,0.18-0.04
			l0.01-0.01c0.12-0.11,0.24-0.13,0.36-0.15c0.05-0.01,0.1-0.02,0.15-0.03c0.1-0.11,0.2-0.17,0.3-0.17c0,0,0,0,0,0
			c0.09,0,0.18,0.05,0.27,0.14c0.05-0.01,0.1-0.02,0.15-0.04c0.11-0.03,0.22-0.07,0.35-0.02l0.02,0.01l0.01,0.02
			c0.17,0.35,0.26,0.39,0.29,0.38c0.01,0,0.07-0.02,0.13-0.31l0.01-0.02c0.09-0.15,0.18-0.31,0.31-0.31c0.08,0,0.15,0.07,0.22,0.2
			c0.08-0.05,0.16-0.02,0.23,0.01c0.09,0.04,0.13,0.04,0.17-0.02l0.01-0.02l0.02-0.01c0.03-0.01,0.07-0.06,0.1-0.11
			c0.06-0.08,0.11-0.15,0.2-0.14c0.07,0.01,0.13,0.08,0.19,0.23c0.05,0.11,0.09,0.13,0.11,0.13c0.03,0,0.07-0.04,0.11-0.07
			c0.04-0.04,0.08-0.08,0.13-0.09l0.02,0l0.02,0.01c0.12,0.06,0.22,0.02,0.33-0.03c0.11-0.05,0.24-0.1,0.38-0.01
			c0.09,0,0.18-0.02,0.26-0.05c0.09-0.03,0.17-0.06,0.26-0.04c0.22-0.06,0.41,0.21,0.51,0.38l0.04-0.27l0.04,0.42l-0.01,0
			c0,0.05,0,0.09,0,0.09l0.01,0.05l-0.05,0l-10.67,0.69C306.09,32.13,306.05,32.13,306,32.13z M305.73,31.85L305.73,31.85
			c-0.02,0.03-0.06,0.08-0.04,0.11c0.04,0.07,0.27,0.08,0.42,0.07l0,0l10.62-0.68c0-0.02,0-0.04,0-0.06
			c-0.01-0.01-0.02-0.03-0.04-0.06c-0.08-0.13-0.26-0.43-0.43-0.37l-0.01,0l-0.01,0c-0.06-0.01-0.14,0.01-0.22,0.04
			c-0.09,0.03-0.2,0.07-0.32,0.06l-0.01,0l-0.01-0.01c-0.09-0.07-0.17-0.04-0.29,0.01c-0.11,0.05-0.24,0.1-0.39,0.03
			c-0.02,0.01-0.05,0.04-0.07,0.06c-0.05,0.05-0.11,0.12-0.19,0.1c-0.07-0.01-0.12-0.07-0.18-0.19c-0.06-0.16-0.1-0.17-0.11-0.17
			c-0.03,0-0.07,0.06-0.11,0.1c-0.04,0.05-0.08,0.11-0.14,0.14c-0.09,0.12-0.2,0.07-0.28,0.04c-0.09-0.03-0.13-0.04-0.17,0.01
			l-0.05,0.06l-0.03-0.07c-0.08-0.18-0.14-0.2-0.17-0.21c-0.07,0-0.15,0.14-0.22,0.25c-0.05,0.25-0.11,0.36-0.21,0.38
			c-0.11,0.01-0.22-0.1-0.38-0.42c-0.09-0.03-0.17,0-0.27,0.03c-0.06,0.02-0.13,0.04-0.2,0.05l-0.02,0l-0.02-0.02
			c-0.07-0.08-0.14-0.12-0.21-0.12c0,0,0,0,0,0c-0.07,0-0.15,0.05-0.23,0.15l-0.01,0.01l-0.01,0c-0.06,0.02-0.12,0.03-0.18,0.04
			c-0.11,0.02-0.21,0.03-0.3,0.12c-0.09,0.14-0.2,0.11-0.29,0.08c-0.09-0.03-0.15-0.04-0.21,0.11l0,0.01l-0.01,0.01
			c-0.1,0.08-0.21,0-0.3-0.06c-0.06-0.04-0.12-0.08-0.16-0.07c-0.02,0-0.04,0.02-0.06,0.05l-0.01,0.02l-0.02,0
			c-0.08,0.01-0.16-0.02-0.24-0.05c-0.09-0.03-0.18-0.06-0.25-0.03c-0.17,0.2-0.36,0.17-0.53,0.12c-0.05-0.01-0.1-0.03-0.15-0.05
			c-0.28-0.09-0.52-0.16-0.72,0.17l-0.01,0.01c-0.26,0.2-0.54,0.13-0.79,0.06c-0.03-0.01-0.06-0.02-0.09-0.03l-0.02-0.01
			c-0.15-0.11-0.28-0.05-0.42,0.01c-0.08,0.04-0.17,0.07-0.26,0.07l-0.02,0l-0.01-0.01c-0.09-0.1-0.15-0.07-0.26,0.02
			c-0.07,0.06-0.17,0.13-0.26,0.06c-0.17,0.05-0.3,0.03-0.38,0.02C305.76,31.85,305.74,31.85,305.73,31.85z"/>
	</g>
</g>
</svg>

</template>
<script>
export default {
  name: "SvgEarthworkFill2LaneComponent",
  props: {
    currentCalc: undefined,
  },
};
</script>
<style scoped>
.st0{fill:none;}
	.st_fill_1{fill:#8FCE57;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_2{fill:#875116;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_3{display:none;}
	.st_fill_4{display:inline;}
	.st_fill_5{fill:#02151E;stroke:#8ADB37;stroke-miterlimit:10;}
	.st_fill_6{fill:#A07220;}
	.st_fill_7{font-family:'Roboto-Bold';}
	.st_fill_8{font-size:18px;}
	.st_fill_9{fill:#FFFFFF;}
	.st_fill_10{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st_fill_11{fill:none;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
	.st_fill_12{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_fill_13{fill:#686868;}
	.st_fill_14{display:inline;fill:#686868;}
	.st_fill_15{fill:#2D190F;}
	.st_fill_16{fill:#DD8F64;}
	.st_fill_17{fill:#7C503A;}
	.st_fill_18{fill:#7C7C7C;}
	.st_fill_19{fill:#3E2D00;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_20{fill:#72502F;}
	.st_fill_21{fill:#8FCE57;}
</style>
