<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 174.25 103.46"
    style="enable-background: new 0 0 174.25 103.46"
    xml:space="preserve"
  >
    <g id="Estrutura">
      <path class="st_bridge_0" d="M160.9,75.93" />
      <path class="st_bridge_0" d="M11.64,33.54" />
      <polygon
        class="st_bridge_1"
        points="157.34,57.87 157.34,57.87 144.16,75.88 144.16,75.88 87.06,75.88 87.06,75.88 30.43,76.09 
		30.43,76.09 16.78,56.62 16.78,56.62 16.57,33.54 16.57,33.54 87.06,32.21 157.22,33.83 157.22,33.83 	"
      />
    </g>
    <g id="Medidas">
      <line class="st_bridge_2" x1="16.21" y1="88.29" x2="16.21" y2="97.51" />
      <line class="st_bridge_2" x1="157.73" y1="88.29" x2="157.73" y2="97.05" />
      <text transform="matrix(1 0 0 1 77.5034 90.9873)" class="st_bridge_3 st_bridge_4">
        {{currentCalc.values_params.structure.bridge_width}} m
      </text>
      <g>
        <g>
          <line class="st_bridge_2" x1="17.12" y1="93.69" x2="156.39" y2="93.37" />
          <g>
            <polygon
              points="18.11,94.54 17.26,93.69 18.11,92.84 17.39,92.85 16.54,93.69 17.39,94.54 				"
            />
          </g>
          <g>
            <polygon
              points="155.39,94.22 156.24,93.37 155.39,92.53 156.11,92.53 156.96,93.37 156.11,94.22 				"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Sub_x5F_base"></g>
    <g id="Asphalt_x5F_and_components">
      <g>
        <polygon
          class="st_bridge_5"
          points="156.97,33.78 148.22,33.78 148.22,32.31 155.5,32.31 155.5,30.11 156.97,30.11 		"
        />
      </g>
      <g>
        <polygon
          class="st_bridge_5"
          points="25.34,33.78 16.59,33.78 16.59,30.11 18.06,30.11 18.06,32.31 25.34,32.31 		"
        />
      </g>
      <g>
        <polygon
          points="148.22,32 86.78,30.53 25.34,32 25.34,33.84 86.78,32.37 148.22,33.84 		"
        />
      </g>
    </g>
    <g id="Jerseys" class="st_bridge_6">
      <g class="st_bridge_7">
        <g>
          <polygon
            class="st_bridge_8"
            points="17.04,33.69 25.06,33.69 25.06,30.57 22.31,27.99 21.21,26.16 20.47,20.65 16.98,20.65 			"
          />
          <path
            d="M25.42,34.06h-8.75l-0.06-13.78h4.18l0.77,5.75l1.04,1.73l2.82,2.65V34.06z M17.41,33.32h7.28v-2.6L22,28.18l-1.14-1.9
				l-0.7-5.27h-2.8L17.41,33.32z"
          />
        </g>
        <g>
          <polygon
            class="st_bridge_8"
            points="156.51,33.69 148.5,33.69 148.5,30.57 151.25,27.99 152.35,26.16 153.08,20.65 156.57,20.65 			"
          />
          <path
            d="M156.88,34.06h-8.75v-3.65l2.82-2.65l1.04-1.73l0.77-5.75h4.18L156.88,34.06z M148.87,33.32h7.28l0.06-12.31h-2.8
				l-0.7,5.27l-1.21,1.98l-2.63,2.46V33.32z"
          />
        </g>
      </g>
    </g>
    <g id="Metal_x5F_guard" class="st_bridge_6">
      <g class="st_bridge_7">
        <g>
          <g>
            <g>
              <path
                class="st_bridge_8"
                d="M157.63,26.39h-4.97c-0.26,0-0.47-0.21-0.47-0.47v0c0-0.26,0.21-0.47,0.47-0.47h4.97
						c0.26,0,0.47,0.21,0.47,0.47v0C158.1,26.18,157.89,26.39,157.63,26.39z"
              />
            </g>
            <g>
              <path
                d="M157.63,26.51h-4.97c-0.32,0-0.58-0.26-0.58-0.58c0-0.32,0.26-0.58,0.58-0.58h4.97c0.32,0,0.58,0.26,0.58,0.58
						C158.21,26.25,157.95,26.51,157.63,26.51z M152.66,25.58c-0.19,0-0.35,0.16-0.35,0.35c0,0.19,0.16,0.35,0.35,0.35h4.97
						c0.19,0,0.35-0.16,0.35-0.35c0-0.19-0.16-0.35-0.35-0.35H152.66z"
              />
            </g>
          </g>
          <g>
            <g>
              <polygon
                class="st_bridge_8"
                points="153.28,25.93 151.11,23.44 153.28,20.64 155.46,20.64 153.28,23.44 155.46,25.93 153.28,29.03 
						155.46,31.52 153.28,31.52 151.06,29.03 					"
              />
            </g>
            <g>
              <path
                d="M155.71,31.63h-2.48l-2.32-2.59l2.22-3.11l-2.18-2.49l2.27-2.92h2.47l-2.26,2.91l2.17,2.48l-2.17,3.11L155.71,31.63zM153.33,31.4h1.87l-2.07-2.36l2.17-3.11l-2.18-2.49l2.09-2.68h-1.88l-2.08,2.68l2.17,2.48l-2.22,3.11L153.33,31.4z"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="155.61"
                y="20.49"
                class="st_bridge_9"
                width="1.55"
                height="13.36"
              />
            </g>
            <g>
              <path
                d="M157.28,33.96h-1.79V20.37h1.79V33.96z M155.73,33.73h1.32V20.61h-1.32V33.73z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                class="st_bridge_8"
                d="M16.03,26.39H21c0.26,0,0.47-0.21,0.47-0.47v0c0-0.26-0.21-0.47-0.47-0.47h-4.97
						c-0.26,0-0.47,0.21-0.47,0.47v0C15.56,26.18,15.77,26.39,16.03,26.39z"
              />
            </g>
            <g>
              <path
                d="M15.45,25.93c0-0.32,0.26-0.58,0.58-0.58H21c0.32,0,0.58,0.26,0.58,0.58c0,0.32-0.26,0.58-0.58,0.58h-4.97
						C15.71,26.51,15.45,26.25,15.45,25.93z M16.03,25.58c-0.19,0-0.35,0.16-0.35,0.35c0,0.19,0.16,0.35,0.35,0.35H21
						c0.19,0,0.35-0.16,0.35-0.35c0-0.19-0.16-0.35-0.35-0.35H16.03z"
              />
            </g>
          </g>
          <g>
            <g>
              <polygon
                class="st_bridge_8"
                points="20.38,25.93 22.55,23.44 20.38,20.64 18.2,20.64 20.38,23.44 18.2,25.93 20.38,29.03 18.2,31.52 
						20.38,31.52 22.6,29.03 					"
              />
            </g>
            <g>
              <path
                d="M20.23,29.02l-2.17-3.11l2.17-2.48l-2.26-2.91h2.47l2.27,2.92l-2.18,2.49l2.22,3.11l-2.32,2.59h-2.48L20.23,29.02zM22.45,29.02l-2.22-3.11l2.17-2.48l-2.08-2.68h-1.88l2.09,2.68l-2.18,2.49l2.17,3.11l-2.07,2.36h1.87L22.45,29.02z"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="16.5"
                y="20.49"
                transform="matrix(-1 -4.449653e-11 4.449653e-11 -1 34.5442 54.3368)"
                class="st_bridge_9"
                width="1.55"
                height="13.36"
              />
            </g>
            <g>
              <path
                d="M16.38,20.37h1.79v13.59h-1.79V20.37z M17.93,20.61h-1.32v13.13h1.32V20.61z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Car" class="st_bridge_6"></g>
  </svg>
</template>
<script>
export default {
  name: "SvgPlatformBridge1LaneComponent",
  props: {
    currentCalc: undefined,
  },
};
</script>
<style scoped>
  .st_bridge_0 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
  }
  .st_bridge_1 {
    fill: #666666;
  }
  .st_bridge_2 {
    fill: none;
    stroke: #000000;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
  }
  .st_bridge_3 {
    font-family: "Roboto-Bold";
  }
  .st_bridge_4 {
    font-size: 14px;
  }
  .st_bridge_5 {
    fill: #7c7c7c;
  }
  .st_bridge_6 {
    display: none;
  }
  .st_bridge_7 {
    display: inline;
  }
  .st_bridge_8 {
    fill: #686868;
  }
  .st_bridge_9 {
    fill: #72502f;
  }
</style>
