<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 465.68 203.7" style="enable-background:new 0 0 465.68 203.7;" xml:space="preserve">
<g id="Corte">
	<path class="st_cut_0" d="M28.3,75.33l84.75,102.3h89.77l87.76-102.3l46.14,3.01l63.67-66.3c0,0-158.03,12.26-209.98,21.56
		C149.22,40.97,28.3,75.33,28.3,75.33z"/>
</g>
<g id="Terreno">
	<path class="st_cut_1" d="M464.62-3.53c0,0.84,0,0.29,0,0.34c0-0.08,0,0.62,0,1c0-0.2,0-0.88,0,1.63c0,0.69,0,1.96,0,2.22
		c0-0.86,0,2.3,0,2.78c0,1.95,0,3.92,0,3.31c0,2.51,0,1.79,0,3.81c0,1.69,0,3.03,0,4.27c0,1.5,0,3.07,0,4.7
		c0,53.94,0,179.79,0,179.79H0.31c0-15.08,0-30.16,0-45.25c0-18.38,0-36.75,0-55.13c0-1.79-0.38-4.6-0.58-7.37
		c-0.1-1.39-0.15-2.76-0.07-4c0.07-2.18,0.26-2.25,0.65-3.17c0.09,2.07,0.69-2.29,1.66-1.17c0.97,2.02,2.31-0.29,3.87-2.09
		c1.56-1.7,3.35,1.06,5.22-2.57c1.87-1.38,3.82-3.1,5.7-2.6c1.89-3.09,3.71-0.24,5.33-2.19c1.62-1.61,3.03-2.78,4.08-1.34
		c1.06-0.5,1.76,2.18,1.98-0.04c0,2.57,0.11-2.32,0.32,0.38c0.21,0.82,0.52,2.5,0.92,1.09c0.4-0.46,0.89,3.33,1.47,1.75
		c0.58,2.81,1.24,1.25,1.98,2.36c0.74,0.77,1.56,0.88,2.44,2.91c0.89,0.41,1.84,0.21,2.86,3.4c1.02,2.29,2.1,2.01,3.23,3.84
		c1.13,1.43,2.32,1.37,3.55,4.23c1.23,2.58,2.51,4.24,3.83,4.56c1.32,1.15,2.67,3.52,4.06,4.83c1.39,0.36,2.8,3.72,4.24,5.05
		c1.44,2.78,2.9,3.51,4.38,5.22c1.48,2.49,2.97,3.88,4.47,5.33c1.5,1.74,3.01,4.1,4.52,5.38c1.51,0.83,3.02,3.14,4.52,5.38
		c1.5,2.44,3,3.53,4.47,5.33c1.48,2.14,2.94,3,4.38,5.22c1.44,1.99,2.86,3.67,4.24,5.05c1.39,0.69,2.74,3.1,4.06,4.83
		c1.32,1.64,2.6,3.99,3.83,4.56c1.23,1.86,2.42,3.02,3.55,4.23c1.13,1.27,2.21,3.5,3.23,3.84c1.02,2.18,1.97,1.82,2.86,3.4
		c0.89,0.36,0.7,1.42,1.44,2.91c0.74-0.5,0.56,1.26,1.14,2.24c0.58,1.17,0.91,6.49,1.31,6.87c0.4,0.05,0.71,0.51,0.92,1.09
		c0.21,1.05,0.32-1.9,0.32,0.38c0,0.01,0.25,0.25,0.72,0c0.47,0.8,1.16-0.57,2.05,0c0.89-0.6,3.97-5.33,5.22-5
		c1.25-2.34,2.68,0.18,4.24,0c1.57,0.77,3.28-0.73,5.1,0c1.83,1.05,3.77-0.22,5.81,0c2.04-0.14,4.16-0.39,6.36,0
		c2.19,0,4.45-1.8,6.75,0c2.3,1.46,4.63,1.13,6.98,0c2.35-1.12,4.71-1.69,7.06,0c2.35,1.13,4.69-0.03,6.98,0
		c2.3,1.27,4.56-0.6,6.75,0c2.19,0.29,4.32,0.19,6.36,0c2.04-0.86,3.98,1.53,5.81,0c1.83,0.81,3.54-1,5.1,0
		c1.57,0.11,2.99,1.46,4.24,0c1.25,0.99,4.34,3.97,5.22,6c0.89,0.12,1.58,0.2,2.05,0c0.47-1.22,0.72,0.3,0.72,0
		c0-0.47,0.11,0.1,0.33-0.38c0.22,0.12,0.53,0.11,0.95-1.09c0.41-0.37,0.93-1.24,1.52-1.75c0.6-0.18,0.28-5.54,1.05-7.36
		c0.77-1.64,1.61-1.78,2.53-2.91c0.92-1.7,0.91-2.25,1.96-4.4c1.05-0.68,2.17-1.72,3.34-3.84c1.17-1.14,2.4-3.59,3.68-4.23
		c1.28-0.83,2.6-3.67,3.96-4.56c1.36-2.2,2.77-1.36,4.2-4.83c1.44-3.09,2.9-5.42,4.39-5.05c1.49-1.26,3.01-4.18,4.54-5.22
		c1.53-0.41,3.08-3.51,4.63-5.33c1.56-1.22,3.12-3.38,4.68-5.38c1.56-1.77,3.13-4.4,4.68-5.38c1.56-0.18,3.1-3.29,4.63-5.33
		c1.53-1.42,3.05-1.5,4.54-5.22c1.49-2.2,2.96-3.54,4.39-5.05c1.44-2.14,2.84-2.89,4.2-4.83c1.36-0.51,2.69-1.33,3.96-4.56
		c1.28-1.41,2.5-2.65,3.68-4.23c1.17-0.51,2.29-2.24,3.34-3.84c1.05-0.3,2.04-2.61,2.96-3.4c0.92-1.27,1.76-2.09,2.53-2.91
		c0.77,0.6,1.45-1.6,2.05-2.36c0.6,0.97,1.11-1.15,1.52-1.75c0.41-1.33,0.73-0.08,0.95-1.09c0.22-2.35,0.33-2.48,0.33-0.38
		c0-0.72,0.46,0.28,1.29,0.08c0.83-0.39,2.02-0.68,3.5,0.22c1.47,0.51,3.22-0.03,5.15,0.33c1.93-0.06,4.05-0.82,6.26,0.4
		c2.21-0.04,4.51-0.06,6.81,0.44c2.3,0.95,4.6-1.1,6.81,0.44c2.21,0.75,4.33,1.15,6.26,0.4c1.93-0.46,3.68,0.17,5.15,0.33
		c1.47-0.52,2.67-0.4,3.5,0.22c0.83-1.07,1.29-0.22,1.29,0.08c0,0.25,0.18-0.63,0.51-0.52c0.33,0.16,0.82-1.18,1.45-1.49
		c0.63-0.17,1.4-1.99,2.29-2.35c0.89-1,1.9-0.78,3.01-3.09c1.11-2.25,2.32-3.17,3.62-3.72c1.3-0.1,2.68-2.23,4.12-4.23
		c1.44-2.22,2.95-2.4,4.51-4.63c1.56-0.44,3.16-2.86,4.79-4.92c1.63-2.14,3.29-3.29,4.95-5.09c1.67-1.53,3.34-2.37,5.01-5.14
		c1.67-1,3.32-4.31,4.95-5.09c1.63-1.07,3.23-2.83,4.79-4.92c1.56-0.98,3.06-2.68,4.51-4.63c1.44-1.2,2.82-2.46,4.12-4.23
		c1.3-0.15,2.51-3.52,3.62-3.72c1.11-1.43,2.12-3.87,3.01-3.09c0.89-0.83,1.66-0.34,2.29-2.35c0.63-1.05,1.12-1.13,1.45-1.49
		c0.33-0.22,0.51,0.26,0.51-0.52c0,1.45,0,0.87,0.06-0.02c0.06,0.06,0.17-1.2,0.39-0.09c0.22-0.27,0.56,0.13,1.06-0.22
		c0.5,0.63,1.17-0.7,2.06-0.41c0.89-0.8,2-0.87,3.39-0.65c1.39,0.36,3.05,0.01,5.05-0.96c2,0.06,4.33,0.69,7.04-1.32
		c2.72,0.21,5.82-1.79,9.37-1.73c3.55,0.21,7.54-1.16,12.03-2.2c0-0.24,0.69,0.26,1.84-0.53c1.15-0.89,2.77,0.24,4.61-1.31
		c1.84-0.3,3.92-0.69,5.99-1.71c2.07-0.27,4.15-1.14,5.99-1.71c1.84-0.4,3.46-0.16,4.61-1.31C463.93-4.59,464.62-3.24,464.62-3.53z"
		/>
	<path class="st_cut_2" d="M465.88-2.9v207.23H0.4c0-15.33,0-30.66,0-45.99c0-18.68,0-37.36,0-56.04c0-3.64-1.54-11.41-0.01-14.78
		c0.61-1.34,26.42-13.98,27.9-12.19c0.02,0.03,84.75,102.3,84.75,102.3l45.37-0.67l44.4,0.67l87.76-102.3l46.14,3.01l63.67-66.3
		c0,0,0.04-0.23,40.54-7.73L465.88-2.9z"/>
</g>
<g id="Canaleta" class="st_cut_3">
	<g class="st_cut_4">
		<polygon class="st_cut_5" points="-118.77,326.53 -118.77,339.52 -141.04,339.52 -141.04,326.53 -138.66,326.53 -138.66,337.14 
			-121.15,337.14 -121.15,326.53 		"/>
	</g>
	<g class="st_cut_4">
		<polygon class="st_cut_5" points="91.28,326.53 91.28,339.52 69,339.52 69,326.53 71.38,326.53 71.38,337.14 88.9,337.14 88.9,326.53 
					"/>
	</g>
	<g class="st_cut_4">
		<rect x="-139.99" y="326.53" class="st_cut_6" width="19.86" height="11.41"/>
	</g>
	<g class="st_cut_4">
		<rect x="70.24" y="326.53" class="st_cut_6" width="19.86" height="11.41"/>
	</g>
</g>
<g id="Medidas">
	<g>
		<text transform="matrix(1 0 0 1 353.0084 138.6516)" class="st_cut_7 st_cut_8 st_cut_9">{{currentCalc.values_params.earthwork.cut_height}} m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 302.8511 104.7336)" class="st_cut_7 st_cut_8 st_cut_9">{{ currentCalc.values_params.earthwork.cut_enbankment_width }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 63.2833 170.7355)" class="st_cut_7 st_cut_8 st_cut_9">{{ Math.round((1 / currentCalc.values_params.earthwork.cut_factor) * 10) / 10 }}</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 29.105 149.0695)" class="st_cut_7 st_cut_8 st_cut_9">1.5</text>
	</g>
	<rect x="291.83" y="66.54" width="45.27" height="0.43"/>
	<polygon points="336.25,67.48 336.97,66.76 336.25,66.03 336.86,66.03 337.58,66.76 336.86,67.48 	"/>
	<path class="st_cut_10" d="M56.77,153.67v-26.51l23.14,26.4L56.77,153.67z"/>
	<rect x="337.35" y="85.09" class="st_cut_7" width="0.43" height="6.18"/>
	<rect x="291.14" y="85.09" class="st_cut_7" width="0.43" height="6.18"/>
	<rect x="343.42" y="181.28" class="st_cut_7" width="6.18" height="0.43"/>
	<rect x="343.42" y="77.98" class="st_cut_7" width="6.18" height="0.43"/>
	<text transform="matrix(1 0 0 1 303.1978 62.0915)" class="st_cut_8 st_cut_9">{{currentCalc.values_params.earthwork.cut_enbankment_slope}} %</text>
	<g>
		<g>
			<line class="st_cut_11" x1="293.19" y1="87.71" x2="336.05" y2="87.71"/>
			<g>
				<polygon class="st_cut_7" points="294.19,88.56 293.34,87.71 294.19,86.86 293.47,86.86 292.62,87.71 293.47,88.56 				"/>
			</g>
			<g>
				<polygon class="st_cut_7" points="335.05,88.56 335.9,87.71 335.05,86.86 335.77,86.86 336.62,87.71 335.77,88.56 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_cut_11" x1="347.12" y1="179.64" x2="347.12" y2="79.78"/>
			<g>
				<polygon class="st_cut_7" points="347.97,178.64 347.12,179.49 346.27,178.64 346.27,179.36 347.12,180.21 347.97,179.36 				"/>
			</g>
			<g>
				<polygon class="st_cut_7" points="347.97,80.78 347.12,79.93 346.27,80.78 346.27,80.06 347.12,79.21 347.97,80.06 				"/>
			</g>
		</g>
	</g>
</g>
<g id="Text">
</g>
<g id="Aterro">
</g>
<g id="Sub_x5F_base">
	<g>
		<polygon class="st_cut_12" points="203.35,173.88 158.43,173.03 113.18,173.93 118.06,169.85 158.43,169 199.09,169.85 		"/>
		<path d="M112.61,174.1l5.33-4.47l40.49-0.85l40.79,0.85l4.83,4.36l-45.62-0.75L112.61,174.1z M158.43,172.82l43.7,0.71l-3.77-3.52
			l-39.94-0.8l-40.24,0.85l-4.4,3.61L158.43,172.82z"/>
	</g>
	<g>
		<polygon class="st_cut_13" points="202.98,177.51 158.43,177.07 113.26,177.92 111.1,175.39 112.83,173.94 158.43,173.03 
			203.79,173.88 204.67,175.23 		"/>
		<path d="M113.02,178.14l-2.33-2.77l1.85-1.64l3.11-0.06l42.78-0.85l45.05,0.77l1.65,1.52l-2.13,2.88l-44.34-0.83L113.02,178.14zM158.43,176.86l44.41,0.58l1.51-2.2l-1.12-1.23l-44.8-0.76l-45.35,0.86l-1.57,1.29l1.94,2.31L158.43,176.86z"/>
	</g>
</g>
<g id="Asphalt_x5F_and_components">
	<g>
		<polygon points="199.07,168.82 158.43,167.76 118.01,168.82 118.01,169.88 158.43,168.82 199.07,169.88 		"/>
	</g>
	<g>
		<polygon class="st_cut_14" points="118.43,169.54 110.72,175.6 107.61,171.75 108.34,171.18 110.88,174.31 117.86,168.82 		"/>
	</g>
	<g>
		<polygon class="st_cut_14" points="198.5,169.54 205.28,175.6 208.38,171.75 207.66,171.18 205.12,174.31 199.07,168.82 		"/>
	</g>
</g>
</svg>

</template>
<script>
export default {
  name: "SvgEarthworkCut1LaneComponent",
  props: {
    currentCalc: undefined,
  },
};
</script>
<style scoped>
	.st_cut_0{opacity:0.29;fill:#FF9100;}
	.st_cut_1{fill:#8FCE57;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st_cut_2{fill:#3E2D00;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_cut_3{display:none;}
	.st_cut_4{display:inline;}
	.st_cut_5{fill:#A07220;}
	.st_cut_6{fill:none;}
	.st_cut_7{fill:#FFFFFF;}
	.st_cut_8{font-family:'Roboto-Bold';}
	.st_cut_9{font-size:18px;}
	.st_cut_10{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st_cut_11{fill:none;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
	.st_cut_12{fill:#DD8F64;}
	.st_cut_13{fill:#7C503A;}
	.st_cut_14{fill:#7C7C7C;}
</style>
