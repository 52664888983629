<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsEarthSVG2Title">
        <strong> {{ selectedLanguage['earthWorkFill1_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <SvgEarthworkFill2LaneComponent v-if="params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text2'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text2']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill2LaneJersey v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text3'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text3']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill2LaneMetalGuard v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text4'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text4']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill2LaneJersey2 v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text3'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text3']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill2LaneMetalGuard2 v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text4'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text4']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>

    <SvgEarthworkFill_2_lanes_N_J v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text3'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text2']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill_2_lanes_N_M v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text4'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text2']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill_2_lanes_N_J_2 v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text3'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text2']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill_2_lanes_N_M_2 v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text4'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text2']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>

    <SvgEarthworkFill_2_lanes_M_J v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text3'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text4']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill_2_lanes_J_M v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text4'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text3']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill_2_lanes_M_J_2 v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text3'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text4']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill_2_lanes_J_M_2 v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text4'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text3']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>

    <SvgEarthworkFill_2_lanes_M_N v-else-if="params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text2'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text4']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    <SvgEarthworkFill_2_lanes_J_N v-else-if="params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text2'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text3']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG3Platform2"/>
    
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkFillHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.fill_height"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.fill_factor"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text7'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text8'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.fill_enbankment_slope
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text9'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.fill_enbankment_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgEarthworkFill2LaneComponent from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill2LaneComponent.vue";
import SvgEarthworkFill2LaneJersey from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill2LaneJersey.vue";
import SvgEarthworkFill2LaneMetalGuard from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill2LaneMetalGuard.vue";
import SvgEarthworkFill2LaneJersey2 from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill2LaneJersey2.vue";
import SvgEarthworkFill2LaneMetalGuard2 from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill2LaneMetalGuard2.vue";
import SvgEarthworkFill_2_lanes_M_J from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_M_J.vue";
import SvgEarthworkFill_2_lanes_J_M from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_J_M.vue";
import SvgEarthworkFill_2_lanes_M_J_2 from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_M_J_2.vue";
import SvgEarthworkFill_2_lanes_J_M_2 from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_J_M_2.vue";
import SvgEarthworkFill_2_lanes_N_J from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_N_J.vue";
import SvgEarthworkFill_2_lanes_N_M from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_N_M.vue";
import SvgEarthworkFill_2_lanes_N_J_2 from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_N_J_2.vue";
import SvgEarthworkFill_2_lanes_N_M_2 from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_N_M_2.vue";
import SvgEarthworkFill_2_lanes_M_N from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_M_N.vue";
import SvgEarthworkFill_2_lanes_J_N from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill_2_lanes_J_N.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";


export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgEarthworkFill2LaneComponent,
    SvgEarthworkFill2LaneJersey,
    SvgEarthworkFill2LaneMetalGuard,
    SvgEarthworkFill2LaneJersey2,
    SvgEarthworkFill2LaneMetalGuard2,
    SvgEarthworkFill_2_lanes_M_J,
    SvgEarthworkFill_2_lanes_J_M,
    SvgEarthworkFill_2_lanes_M_J_2,
    SvgEarthworkFill_2_lanes_J_M_2,
    SvgEarthworkFill_2_lanes_N_J,
    SvgEarthworkFill_2_lanes_N_M,
    SvgEarthworkFill_2_lanes_N_J_2,
    SvgEarthworkFill_2_lanes_N_M_2,
    SvgEarthworkFill_2_lanes_M_N,
    SvgEarthworkFill_2_lanes_J_N,
  },
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-10,
.cls-12,
.cls-2,
.cls-9 {
  fill: none;
}
.cls-3 {
  fill: #875116;
}
.cls-4 {
  fill: #ccc;
}
.cls-5 {
  fill: #666;
}
.cls-6 {
  fill: #3d3d3d;
}
.cls-7,
.cls-8 {
  font-size: 18px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-11,
.cls-8 {
  fill: #fff;
}
.cls-12,
.cls-9 {
  stroke: #000;
}
.cls-10,
.cls-12,
.cls-9 {
  stroke-miterlimit: 10;
}
.cls-10 {
  stroke: #fff;
}
.cls-10,
.cls-12 {
  stroke-width: 0.5px;
}
</style>