<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsEarthSVG2Title">
        <strong> {{ selectedLanguage['earthWorkFill1_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <SvgEarthworkFill1LaneComponent v-if="params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text2'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text2']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG2"/>
    <SvgEarthworkFill1LaneJersey v-else-if="params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text2'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text3']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG2"/>
    <SvgEarthworkFill1LaneMetalGuard v-else-if="params.central_barrier==this.selectedLanguage['earthWorkFill1_component_text2'] && params.side_barrier==this.selectedLanguage['earthWorkFill1_component_text4']" :currentCalc="currentCalc" id="ValuesParamsEarthSVG2"/>
    
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkFillHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.fill_height"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.fill_factor"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text7'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text8'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.fill_enbankment_slope
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkFillEnbankmentWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkFill1_component_text9'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.fill_enbankment_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgEarthworkFill1LaneComponent from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill1LaneComponent.vue";
import SvgEarthworkFill1LaneJersey from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill1LaneJersey.vue";
import SvgEarthworkFill1LaneMetalGuard from "@/components/SvgComponents/roadway/earthworkFill/SvgEarthworkFill1LaneMetalGuard.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgEarthworkFill1LaneComponent,
    SvgEarthworkFill1LaneJersey,
    SvgEarthworkFill1LaneMetalGuard
},
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-10,
.cls-12,
.cls-2,
.cls-9 {
  fill: none;
}
.cls-3 {
  fill: #875116;
}
.cls-4 {
  fill: #ccc;
}
.cls-5 {
  fill: #666;
}
.cls-6 {
  fill: #3d3d3d;
}
.cls-7,
.cls-8 {
  font-size: 18px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-11,
.cls-8 {
  fill: #fff;
}
.cls-12,
.cls-9 {
  stroke: #000;
}
.cls-10,
.cls-12,
.cls-9 {
  stroke-miterlimit: 10;
}
.cls-10 {
  stroke: #fff;
}
.cls-10,
.cls-12 {
  stroke-width: 0.5px;
}
</style>