<template>
  <div style="overflow-x: hidden; overflow-y: hidden;">
    <!-- tunnel_section_1_lane -->
    <SvgPlatformTunnel1LaneComponent v-if="params.central_barrier==this.selectedLanguage['platformTunnel1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformTunnel1Lane_component_text1']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG7"/>
    <SvgPlatformTunnel1LaneComponent v-if="params.central_barrier==this.selectedLanguage['platformTunnel1Lane_component_text1'] && params.side_barrier==this.selectedLanguage['platformTunnel1Lane_component_text2']" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG7"/>
    <SvgPlatformTunnel1LaneComponent v-if="params.central_barrier==this.selectedLanguage['platformTunnel1Lane_component_text1'] && params.side_barrier=='Metal Defense'" :currentCalc="currentCalc" id="ValuesParamsPlatformSVG7"/>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsStructureTunnelWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platformTunnel1Lane_component_text4'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.structure.tunnel_width"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SvgPlatformTunnel1LaneComponent from "@/components/SvgComponents/roadway/platformTunnel/SvgPlatformTunnel1LaneComponent.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgPlatformTunnel1LaneComponent
  },
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2,
.cls-5 {
  fill: none;
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke: #666;
  stroke-width: 6px;
}
.cls-3 {
  fill: #666;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  stroke: #000;
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 12px;
  font-family: MyriadPro-Regular, Myriad Pro;
}
</style>